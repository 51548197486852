import styles from "../../Styles/CustomToolTip.module.css";

const CustomTooltip = ({ active, payload, snappedOn }: any) => {
  if (active && payload && payload.length) {
    const data = snappedOn ?? payload[0].payload;
    const colors = payload.map((p: any) => ({ key: p.dataKey, color: p.color}));
    return (
      <div className={styles.customTooltip}>
        {Object.keys(data).map((key: any, i: number) => {
          const keyToName: {
            [key: string]: string;
          } = {
            date: "Date",
            rms: "RMS",
            kurtosis: "Kurtosis",
            p2p: "Peak to Peak",
            time: "Time (μs)",
            acceleration: "Acceleration (g)",
            amplitude: "Amplitude",
            frequency: "Frequency (Hz)",
            temperature: "Temperature",
          };

          if (key === "REF") {
            return null;
          }

          let value;
          if (isNaN(data[key])) {
            value = data[key];
          } else {
            value = parseFloat(data[key]).toFixed(4);
            if (key === "date") {
              value = new Date(data[key]).toLocaleString();
            }
          }

          if (value === "undefined" || value === undefined) {
            return null;
          }

          let name;
          if (keyToName[key]) {
            name = keyToName[key];
          } else {
            name = key;
            for (const c of colors) {
              // eslint-disable-next-line
              if (c.key == key) { // we want string and number comparison to be true
                name = c.color;
                break;
              }
            }
          }

          return (
            <p key={i}>
              <b>{`${name}:`}</b>
              {` ${value}`}
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;