/*
 * This file contains shared code between FFT analysis
 * dashboards such as the audio, vibration and researcher dashboards.
 */

// The colors that FFT layer graphs can be
export const FFT_LAYER_COLORS = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "rgb(255,0,0)",
  "rgb(40,240,0)",
  "rgb(0,0,255)",
  "#FF00FF",
  "rgb(130,130,130)",
  "rgb(0,0,0)",
];

// The different 'View Types' that the left/top
// Analysis View can be
export enum LeftView {
  SampleGraph,
  FFTLayers,
  TrendsGraph,
  SensorSettings,
}

// The different 'View Types' that the right/bottom
// Analysis View can be
export enum RightView {
  FFTAnalysis,
}
