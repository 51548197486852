import styles from "../../../Styles/SignInInvitation.module.css";

/**
 * A component that invites the user to sign in or create an account
 */
const SignInInvitation = () => {
  return (
    <div className={styles.signInInvitation}>
      <h1>Sign In or Create an Account</h1>
      <p>
        <a href="/">Sign in or create an account</a>
        <br />
        to use all of openPHM's
        <br />
        powerfull analysis features
      </p>
    </div>
  );
};

export default SignInInvitation;
