import { useEffect, useState } from "react";
import styles from "../../../Styles/DownloadMeasurement.module.css";
import { getDownloadData, saveCSV, saveJSON } from "../../../Utils/FileUtils";

const DownloadMeasurement = ({
  downloadFilePaths,
}: {
  downloadFilePaths: string[];
}) => {
  const [categories, setCategories] = useState<{ name: string; key: string }[]>(
    []
  );
  const [checkedCategories, setCheckedCategories] = useState<string[]>([
    "id",
    "file_path",
    "sampling_frequency",
    "timestamp",
    "type",
    "axis",
    "tags",
  ]);

  useEffect(() => {
    fetch(
      `/get_download_categories/${encodeURIComponent(
        JSON.stringify(downloadFilePaths)
      )}`
    )
      .then((res) => res.json())
      .then((res) => {
        setCategories(res);
      });
  }, [downloadFilePaths]);

  return (
    <>
      <h2 className={styles.categoryTitle}>Data to download</h2>
      <div className={styles.downloadCategories}>
        {categories.map((category) => (
          <div className={styles.downloadCategory}>
            <span>{category.name}</span>
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedCategories([...checkedCategories, category.key]);
                } else {
                  setCheckedCategories(
                    checkedCategories.filter((c) => c !== category.key)
                  );
                }
              }}
            />
          </div>
        ))}
      </div>
      <div>
        <div
          className={styles.downloadModalBtn}
          onClick={() => {
            getDownloadData(
              downloadFilePaths as string[],
              checkedCategories
            ).then((data: any) => {
              data?.forEach((d: any) => {
                saveCSV(d);
              });
            });
          }}
        >
          <div className={`${styles.tableIcon} ${styles.icon}`} />
          Save Data as <em>CSV</em>
        </div>
        <div
          className={styles.downloadModalBtn}
          onClick={() => {
            getDownloadData(
              downloadFilePaths as string[],
              checkedCategories
            ).then((data: any) => {
              data?.forEach((d: any) => {
                saveJSON(d);
              });
            });
          }}
        >
          <div className={`${styles.jsonIcon} ${styles.icon}`} />
          Save Data as <em>JSON</em>
        </div>
      </div>
    </>
  );
};

export default DownloadMeasurement;
