import { CSSProperties, ReactNode, useContext } from "react";
import styles from "../Styles/PopUp.module.css";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { AppContext } from "./App";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "var(--border)",
    padding: "0",
    zIndex: "200",
    position: "absolute",
    backgroundColor: "#ffffff",
  } as CSSProperties,
};

try {
  Modal.setAppElement("#root");
} catch (error) {}

interface Props {
  isOpen: boolean;
  setIsOpen:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((b: boolean) => void);
  title: string | ReactNode | undefined;
  confirmBtnTxt?: string;
  confirmBtnFunc?: () => any;
  children: ReactNode;
  helpUrl?: string;
  // Prevents auto close on confirm click (for error checking)
  closeManually?: boolean;
  // Do something on close
  closeFunction?: (action?: string) => void;
}

/*
 * This is the popup component that is used throughout the app
 * various props are passed in to customize the popup such
 */
const PopUp = ({
  isOpen,
  setIsOpen,
  title,
  confirmBtnTxt,
  confirmBtnFunc,
  children,
  helpUrl,
  closeManually,
  closeFunction,
}: Props) => {
  const { tabInstance } = useContext(AppContext) ?? {};
  const navigate = useNavigate();

  const closeModal = (action?: string) => {
    if (closeFunction) closeFunction(action);
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      contentLabel={`${title} Modal`}
    >
      <div className={styles.popUpWrapper}>
        <div className={styles.topbar}>
          <h2 className={styles.title}>{title}</h2>
          {helpUrl && (
            <div
              className={styles.helpIcon}
              onClick={() => {
                if (!tabInstance) return;
                tabInstance.select("");
                tabInstance.refresh();
                closeModal();
                setTimeout(() => {
                  navigate(`/help${helpUrl}`);
                }, 0);
              }}
            />
          )}
          <i
            className={`fa-solid fa-times ${styles.exit}`}
            onClick={() => closeModal()}
          />
        </div>
        <div className={styles.content}>{children}</div>
        {confirmBtnTxt && (
          <div className={styles.footer}>
            <button
              className={`button ${styles.cancelBtn}`}
              onClick={() => closeModal()}
            >
              Cancel
            </button>
            <button
              className={`button`}
              onClick={() => {
                if (confirmBtnFunc)
                  confirmBtnFunc();
                if (!closeManually) {
                  closeModal("confirm");
                }
              }}
            >
              {confirmBtnTxt}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default PopUp;
