import { useState, useEffect } from "react";
import styles from "../../../../Styles/ShareSettings.module.css";
import PopUp from "../../../PopUp";
import { AccessType } from "../../../../Utils/FileUtils";

interface Props {
  open: boolean;
  setOpen: any;

  // This is the file which is having it's ownership transferred
  // null when on delete page (in this case it pulls the array later)
  file: any;

  // This is the current user which is transferring ownership
  user: any;

  // Close the sharing modal after new owner is set
  closeModal?: any;
  userType: "industry" | "researcher";
}

/**
 * PopUp used to transfer the ownership of an organization or group
 * Used in ShareSettingsModal.tsx and when deleting the account
 */
const TransferOwnership = ({
  open,
  setOpen,
  file,
  user,
  closeModal,
  userType,
}: Props) => {
  const [newOwnerField, setNewOwnerField] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any>([]);
  const [newOwner, setNewOwner] = useState<any>(null);

  // This updates the search results when the user types in the search bar
  useEffect(() => {
    if (newOwnerField) {
      fetch(
        `/profiles/${encodeURIComponent(
          JSON.stringify({ query: newOwnerField, user_type: userType })
        )}`
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setSearchResults(data.filter((d: any) => d.user_id !== user?.sub));
        });
    } else {
      setSearchResults([]);
    }
  }, [newOwnerField, user?.sub, userType]);

  const setNewOwnerRequest = async (fileIds: number[]): Promise<void> => {
    try {
      await fetch("/set_new_owner", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          new_owner_id: newOwner.id,
          file_ids: fileIds,
          user_type: userType,
        }),
      });
      if (closeModal) closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PopUp
      isOpen={open}
      setIsOpen={setOpen}
      title={"Transfer Ownership"}
      confirmBtnTxt={newOwner ? "Confirm New Owner" : undefined}
      confirmBtnFunc={
        newOwner
          ? async () => {
              let ids: number[] = [];
              if (file === null) {
                // Get all fileIds the user is an owner of
                await fetch(
                  `/get_owned_orgs/${encodeURIComponent(
                    JSON.stringify({
                      user_id: user?.sub,
                      user_type: localStorage.getItem("user_type"),
                    })
                  )}`
                )
                  .then((response) => response.json())
                  .then((data) => {
                    ids = data.map((d: any) => d.org_id);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              } else {
                ids = [file.dbID];
              }
              setNewOwnerRequest(ids);
            }
          : undefined
      }
    >
      <div style={{ height: newOwner ? "100px" : "125px" }}>
        {!newOwner && (
          <div className={styles.collaborators}>
            <div className={styles.resultsDropdownWrapper}>
              {((file && file.permissions === "owner") || file === null) && (
                <div className={styles.collaboratorSearch}>
                  <input
                    value={newOwnerField}
                    onChange={(e) => {
                      setNewOwnerField(e.target.value);
                    }}
                    type="text"
                    placeholder="Pick a new owner (by name or email)"
                  />
                </div>
              )}
              {searchResults.length !== 0 && (
                <div className={styles.resultsDropdown}>
                  {searchResults.map((user: any, i: number) => (
                    <>
                      <div
                        key={i}
                        onClick={() => {
                          // Select a new owner
                          setNewOwner({
                            email: user.email,
                            name: user.name,
                            type: AccessType.Viewer,
                            id: user.user_id,
                          });
                          setSearchResults([]);
                          setNewOwnerField("");
                        }}
                      >
                        {user.name} ({user.email})
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {newOwner && (
          <div className={styles.newOwner}>
            <b>New Owner: </b>
            {newOwner.name}
            <i
              onClick={() => {
                setNewOwner(null);
              }}
              className={`fa-solid fa-times`}
            />
          </div>
        )}
        <p className={styles.newOwnerDescription}>
          <b>Note: </b>
          You will immediately lose ownership of {file ? "this " : "your "}
          {userType === "industry" ? "Organization" : "Group"}
          {file
            ? " and become an editor once the transfer is confirmed."
            : "s."}
        </p>
      </div>
    </PopUp>
  );
};

export default TransferOwnership;
