/*
 * This is the color legend element for the fault / threshold lines
 */
const FaultLegend = ({
  title,
  color,
}: {
  title: string;
  color: string;
}) => {
  return (
    <div style={{ fontSize: "0.9rem" }}>
      <span style={{ color: color, fontSize: "1rem", marginRight: "0.5rem" }}>⬤</span>
      {title}
    </div>
  );
};

export default FaultLegend;
