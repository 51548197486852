import ReactJson from "react-json-view";
import HelpContent from "../../HelpContent";
import Section from "../../Section";
import PageContents from "../../PageContents";

const UploadingData = () => {
  return (
    <>
      <Section
        title={"Overview"}
        children={
          <>
            <div>
              <p style={{ float: "left", width: "70%", marginBottom: "0" }}>
                {HelpContent["Uploading Data"].intro}
              </p>
              <PageContents sections={["CSV", "JSON"]} />
              <p style={{ float: "left", width: "70%" }}>
                {HelpContent["Uploading Data"].intro_p2}
              </p>
            </div>
          </>
        }
      />
      <Section
        title={"CSV"}
        children={
          <>
            <div>
              <p>{HelpContent["Uploading Data"].csv}</p>
            </div>
          </>
        }
      />
      <Section
        title={"JSON"}
        children={
          <>
            <div>
              <p>{HelpContent["Uploading Data"].json}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Uploading Data"].jsonDataTypes,
                }}
              />
              <p>
                Below is an example of a valid JSON file:
                <ReactJson
                  style={{
                    margin: "1.5em 0",
                  }}
                  name={false}
                  src={{
                    sampling_frequency: 1000,
                    timestamp: new Date().toISOString(),
                    type: "vibration",
                    axis: "x",
                    raw_data: [
                      { x: 0, y: 6.734 },
                      { x: 0.001, y: 1 },
                      { x: 0.002, y: -5.5 },
                      { x: 0.003, y: 3.45 },
                    ],
                  }}
                />
              </p>
            </div>
          </>
        }
      />
    </>
  );
};

export default UploadingData;
