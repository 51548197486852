import { AppState, RedirectLoginOptions } from "@auth0/auth0-react";
import styles from "../Styles/Home.module.css";

interface Props {
  loginWithRedirect: (options?: RedirectLoginOptions<AppState> | undefined) => Promise<void>;
}

/*
 * This is the first landing page a user sees when visiting the website
 * before logging in. It contains two buttons, one for industry and one for
 * researchers. Each button will log the user in and redirect them to the
 * appropriate page.
 */
const Home = ({ loginWithRedirect }: Props) => {
  return (
    <div className={styles.container}>
      <div className={`content ${styles.loginBox}`}>
        <h2>Industry Analyst?</h2>
        <p>
          Monitor and analyze machine health telemetry data from industrial
          sensor systems using in depth machine monitoring tools.
        </p>
        <div>
          {/* Login Button */}
          <div
            className={`button ${styles.loginBtn}`}
            onClick={() => {
              loginWithRedirect();
              localStorage.setItem("user_type", "industry");
              localStorage.setItem("currentTab", "devicesTab");
            }}
          >
            <i className="fa-solid fa-chart-simple" />
            Continue as Analyst {">>"}
          </div>
        </div>
      </div>
      <div className={`content ${styles.loginBox}`}>
        <h2>Researcher?</h2>
        <p>
          Get free access to anonymized machine health data from sensors being
          used in the field.
        </p>
        <div
          className={`button ${styles.loginBtn}`}
          onClick={() => {
            loginWithRedirect();
            localStorage.setItem("user_type", "researcher");
            localStorage.setItem("currentTab", "samplesTab");
          }}
        >
          <i className="fa-solid fa-magnifying-glass" />
          Continue as researcher {">>"}
        </div>
      </div>
      <div className={`content ${styles.loginBox}`}>
        <h2>About</h2>
        <p>
          openPHM is a machine health data analysis web app for both researchers
          and industry. Industry can use the software for condition and
          predictive monitoring of equipment, while researchers can easily
          access anonymized machine health data and analysis tools for research
          purposes.
        </p>
      </div>
    </div>
  );
};

export default Home;
