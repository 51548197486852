import styles from "../Styles/Footer.module.css";

/*
 * This is simply the footer component
 * It contains a copywrite notice
 */
const Footer = () => {
  return (
    <div className={styles.footer}>
      Copyright © {new Date().getFullYear()} openPHM
    </div>
  );
};

export default Footer;
