import ReactJson from "react-json-view";
import HelpContent from "../../HelpContent";
import Section from "../../Section";
import PageContents from "../../PageContents";

const DownloadingData = () => {
  return (
    <>
      <Section
        children={
          <>
            <div>
              <p style={{ float: "left", width: "70%" }}>
                {HelpContent["Downloading Data"].intro}
              </p>
              <PageContents sections={["CSV", "JSON"]} />
            </div>
          </>
        }
      />

      <Section
        title={"CSV"}
        children={
          <>
            <div>
              <p>{HelpContent["Downloading Data"].csv}</p>
            </div>
          </>
        }
      />
      <Section
        title={"JSON"}
        children={
          <>
            <div>
              <p>
                {HelpContent["Downloading Data"].json}
                <br />
                Below is an example of a JSON file for a vibration sample:
                <ReactJson
                  style={{
                    margin: "1.5em 0",
                  }}
                  name={false}
                  src={{
                    name: new Date().toISOString(),
                    id: "3",
                    file_path: [
                      "Group 1",
                      "Project 1",
                      new Date().toISOString(),
                    ],
                    sampling_frequency: 1000,
                    timestamp: new Date().toISOString(),
                    type: "vibration",
                    axis: "x",
                    vibration_raw: [
                      { x: 0, y: 6.734 },
                      { x: 0.001, y: 1 },
                      { x: 0.002, y: -5.5 },
                      { x: 0.003, y: 3.45 },
                    ],
                    acceleration_fft: [
                      { x: 0, y: 4.44 },
                      { x: 1, y: 43 },
                      { x: 2, y: 41.5 },
                      { x: 3, y: -3.89 },
                    ],
                  }}
                />
              </p>
            </div>
          </>
        }
      />
    </>
  );
};

export default DownloadingData;
