import { Dispatch, ReactNode, SetStateAction, useRef, useState } from "react";
import styles from "../../Styles/LegendDropdown.module.css";
import { useOutsideDetect } from "../../Utils/DropdownUtils";
import { LineColors } from "../../Utils/GraphUtils";

const LegendDropdown = ({name, customDropdownStyle, disabled, children}: {name: string, customDropdownStyle?: string, disabled?: boolean, children?: ReactNode}) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideDetect(dropdownRef, buttonRef, () => setOpen(false));

  return (
    <div className={styles.legendDropdown}>
      <div
        className={`${styles.legendDropdownButton} ${disabled ? styles.disabled : ""}`}
        onMouseUp={() => disabled ? null : setOpen(!open)}
        ref={buttonRef}
      >
        {name}
        <div
          className={styles.legendDropdownArrow}
          style={{
            transform: `translateY(1px) ${
              open ? "rotate(180deg)" : ""
            }`,
          }}
        />
      </div>
      {open && (
        <div className={customDropdownStyle ? customDropdownStyle : styles.legendDropdownMenu} ref={dropdownRef}>
          {children}
        </div>
      )}
    </div>
  );
}

const DropdownOption = ({
  title,
  color,
  state,
  setState,
}: {
  title: string,
  color?: string,
  state?: boolean,
  setState?: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <div className={styles.dropdownOption}>
      {state !== undefined && setState && <input
        type="checkbox"
        id={`legend-${title}`}
        defaultChecked={state}
        onChange={e => setState(e.target.checked)}
      />}
      <label
        style={{ color: state ? color ?? LineColors[title] : LineColors["None"], textAlign: "center", width: "100%" }}
        htmlFor={`legend-${title}`}
      >
        {title}
      </label>
    </div>
  );
};

export { LegendDropdown, DropdownOption };