import { User } from "@auth0/auth0-react";
import styles from "../../Styles/UserSettings.module.css";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../App";

interface Props {
  user: User | undefined;
  logout: () => void;
  closeDropdown: () => void;
}

// This component is the dropdown menu which appears upon clicking
// on the profile picture on the far right of the nav bar (when logged in)
const UserSettings = ({
  user,
  logout,
  closeDropdown,
}: Props) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div style={{ color: "white", fontSize: "1.2em" }}>
        <i className="fa fa-user " style={{ paddingRight: "0.5em" }} />
        User:
      </div>
      <div className={styles.username}>{user?.name}</div>
      <div>
        <div
          className={`button ${styles.btn} ${styles.logout}`}
          onClick={() => {
            localStorage.setItem("token", "");
            logout();
          }}
        >
          <i className="fa fa-door-open " />
          Log Out
        </div>
        <div
          className={`button ${styles.btn}`}
          onClick={() => {
            closeDropdown();
            tabInstance.select("");
            tabInstance.refresh();
            document.title = "Profile | openPHM";
            setTimeout(() => {
              navigate("/profile");
            }, 0);
          }}
        >
          <i className="fa-regular fa-user" />
          Profile
        </div>
        <div
          className={`button ${styles.btn}`}
          onAuxClick={(event: any) => {
            if (event.button === 1) {
              // Middle mouse button has been clicked
              // Open in a new tab
              window.open(`/help`, "_blank")?.focus();
            }
          }}
          onClick={() => {
            closeDropdown();
            tabInstance.select("");
            tabInstance.refresh();
            document.title = "Help | openPHM";
            setTimeout(() => {
              navigate("/help/documentation_overview");
            }, 0);
          }}
        >
          <i className="fa-regular fa-circle-question" />
          Help
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
