import HelpContent from "../../HelpContent";

const DocsOverview = () => {
  return (
    <>
      <p>{HelpContent["Documentation Overview"].intro}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["Documentation Overview"].quickStart,
        }}
      />
    </>
  );
};

export default DocsOverview;
