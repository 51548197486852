import PopUp from "../PopUp";
import styles from "../../Styles/TagPopUp.module.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { getCurrentTabPathOrg } from "../../Utils/Utils";

const hexToHSL = (hexColor: string) => {
  // Remove the '#' symbol from the hex code
  hexColor = hexColor.replace("#", "");

  // Extract and normalize the individual color components
  const red = parseInt(hexColor.substring(0, 2), 16) / 255;
  const green = parseInt(hexColor.substring(2, 4), 16) / 255;
  const blue = parseInt(hexColor.substring(4, 6), 16) / 255;

  // Find the maximum and minimum color components
  const max = Math.max(red, green, blue);
  const min = Math.min(red, green, blue);

  // Calculate the hue
  let hue: number;
  const delta = max - min;
  if (delta === 0) {
    hue = 0; // No saturation, so hue is 0
  } else {
    switch (max) {
      case red:
        hue = ((green - blue) / delta) % 6;
        break;
      case green:
        hue = (blue - red) / delta + 2;
        break;
      case blue:
        hue = (red - green) / delta + 4;
        break;
    }
    hue = Math.round(hue! * 60);
  }

  // Calculate the lightness
  let lightness = (max + min) / 2;

  // Calculate the saturation
  var saturation;
  if (max === min) {
    saturation = 0;
  } else if (lightness <= 0.5) {
    saturation = delta / (2 * lightness);
  } else {
    saturation = delta / (2 - 2 * lightness);
  }

  // Convert saturation and lightness to percentage values
  saturation = Math.round(saturation * 100);
  lightness = Math.round(lightness * 100);

  // Return the HSL value as a string
  return "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";
};

const HSLToHex = (HSLColor: string) => {
  HSLColor = HSLColor.slice(4, HSLColor.length - 1).replaceAll("%", "");
  let [h, s, l] = HSLColor.split(",").map((value) => Number(value));

  // Normalize the hue value to be within the range [0, 360)
  h = ((h % 360) + 360) % 360;

  // Convert the saturation and lightness values to be within the range [0, 1]
  s = Math.max(0, Math.min(s, 100)) / 100;
  l = Math.max(0, Math.min(l, 100)) / 100;

  // Calculate the RGB values
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  let r, g, b;

  if (h >= 0 && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h >= 60 && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h >= 120 && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h >= 180 && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h >= 240 && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  // Convert the RGB values to the hexadecimal format
  const hexR = Math.round((r + m) * 255)
    .toString(16)
    .padStart(2, "0");
  const hexG = Math.round((g + m) * 255)
    .toString(16)
    .padStart(2, "0");
  const hexB = Math.round((b + m) * 255)
    .toString(16)
    .padStart(2, "0");

  return "#" + hexR + hexG + hexB;
};

const TagPopUp = ({
  tagModalOpen,
  setTagModalOpen,
  dataId,
  tags,
  updateTags,
  tagEditable,
  onClose,
}: {
  tagModalOpen: boolean;
  setTagModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataId: number | null;
  tags: any[];
  updateTags: ({
    updateType,
    val,
    id,
    tags,
  }: {
    updateType?: string;
    val?: string;
    id?: number;
    tags?: any[];
  }) => Promise<void> | void;
  tagEditable: boolean;
  onClose?: () => any;
}) => {

  // Update a tag option
  const updateTagOption = async () => {
    const { id, value, recommendation, color } = openedTag;

    await getCurrentTabPathOrg()
      .then((org_id) =>
        fetch("/update_tag_option", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id,
            value,
            recommendation: recommendation === "" ? null : recommendation,
            color,
            org_id: org_id,
          }),
        })
      );
    getTagOptions();
  };

  const [openedTag, setOpenedTag] = useState<any>(null);
  const [editTagOpen, setEditTagOpen] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<any>([]);
  const editColorPickerWrapperRef = useRef<HTMLDivElement>(null);

  const getTagOptions = useCallback(() => {
    if (dataId)
      getCurrentTabPathOrg()
        .then((orgId) => fetch(`/get_tags/${orgId}`))
        .then((data) => data.json())
        .then((tags) => {
          setTagOptions(tags);
        });
    else
      fetch(`/get_tags/${-1}`)
        .then((data) => data.json())
        .then((tags) => {
          setTagOptions(tags);
        });
  }, [dataId]);

  useEffect(() => {
    getTagOptions();
  }, [getTagOptions]);

  return (
    <PopUp
      isOpen={tagModalOpen}
      setIsOpen={(b: boolean) => {
        if (!b) {
          setTagModalOpen(false);
        }
      }}
      title="Add Tag"
      closeFunction={onClose}
    >
      {tagEditable && (
        <PopUp
          isOpen={editTagOpen}
          setIsOpen={(b: boolean) => {
            if (!b) {
              setEditTagOpen(false);
            }
          }}
          title={"Edit Tag"}
          confirmBtnTxt="Confirm"
          confirmBtnFunc={() => updateTagOption()}
        >
          <div className={styles.editInputGroup}>
            <span>Tag: {openedTag?.value}</span>
          </div>
          <div className={styles.editInputGroup}>
            <span>Recommendation: </span>
            <input
              type="text"
              defaultValue={
                openedTag?.recommendation ? openedTag?.recommendation : ""
              }
              onChange={(e) =>
                setOpenedTag((openedTag: any) => {
                  const newTag = openedTag;
                  newTag.recommendation = e.target.value;
                  return newTag;
                })
              }
            />
          </div>
          <div className={styles.editInputGroup}>
            <span>Color: </span>
            <div
              className={styles.colorPickerWrapper}
              ref={editColorPickerWrapperRef}
              style={{ backgroundColor: openedTag?.color }}
            >
              <input
                type="color"
                defaultValue={
                  openedTag?.color ? HSLToHex(openedTag?.color) : "#b6daed"
                }
                onChange={(e) =>
                  setOpenedTag((openedTag: any) => {
                    const newTag = openedTag;
                    newTag.color = hexToHSL(e.target.value);
                    if (editColorPickerWrapperRef.current)
                      editColorPickerWrapperRef.current.style.backgroundColor =
                        openedTag?.color;
                    return newTag;
                  })
                }
              />
            </div>
          </div>
        </PopUp>
      )}
      <div className={styles.tagSection}>
        {Array.from(new Set(tagOptions.map((tag: any) => tag.category))).map(
          (category: any, i: number) => (
            <div key={i}>
              <div className={styles.tagSectionHeader}>
                <span>{category}</span>
              </div>
              <div className={styles.tagSectionTags}>
                {tagOptions
                  .filter(
                    (tag: any) =>
                      tag.category === category &&
                      !tags.map((t: any) => t.value).includes(tag.value)
                  )
                  .map((tag: any, i: number) => (
                    <div
                      style={{ backgroundColor: tag.color }}
                      className={styles.tagOption}
                      key={i}
                    >
                      <span
                        onClick={() => {
                          updateTags({
                            updateType: "add",
                            val: tag.value,
                            tags: tagOptions,
                          });
                          setTagModalOpen(false);
                        }}
                      >
                        {tag.value}
                      </span>
                      {tagEditable && (
                        <div
                          className={styles.editTag}
                          onClick={() => {
                            setOpenedTag({
                              id: tag.id,
                              value: tag.value,
                              recommendation: tag.recommendation,
                              color: tag.color,
                            });
                            setEditTagOpen(true);
                          }}
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          )
        )}
      </div>
    </PopUp>
  );
};

export default TagPopUp;
