import styles from "../../Styles/ErrorPages.module.css";
import { Link } from "react-router-dom";

/*
 * This page should appear when the user has navigated to a page
 * that does not exist. It is a 404 error page.
 */
const PageNotFound = () => {
  return (
    <div className={styles.container}>
      <div className={`content`}>
        <h2>404 - Page Not Found</h2>
        Sorry, we could not find the page you requested.
        <br />
        <br />
        {`Check that you typed the address correctly or `}
        <Link to="/">{`go back home`}</Link>.
      </div>
    </div>
  );
};

export default PageNotFound;
