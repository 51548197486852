import HelpContent from "../../HelpContent";
import Section from "../../Section";
import PageContents from "../../PageContents";
import ReactJson from "react-json-view";
import RequestExample from "../../../../Images/RequestExample.png";

const DataDownloadAPI = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <div>
            <p
              style={{ float: "left", width: "70%" }}
              dangerouslySetInnerHTML={{
                __html: HelpContent["Data Download API"].intro,
              }}
            />
            <PageContents
              sections={[
                "API Secret Key",
                "API Routes",
                "Data Format",
                "HTTP Request Format",
              ]}
            />
          </div>
        }
      />
      <Section
        title="API Secret Key"
        children={
          <div>
            <p
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: HelpContent["Data Download API"].apiSecrets,
              }}
            />
          </div>
        }
      />
      <Section
        title="API Routes"
        children={
          <div>
            <p
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: HelpContent["Data Download API"].apiRoutes,
              }}
            />
          </div>
        }
      />
      <Section
        title="Data Format"
        children={
          <>
            <div>
              <p
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Data Download API"].dataFormat,
                }}
              />
            </div>
            <ReactJson
              style={{
                margin: "1.5em 0",
              }}
              name={false}
              src={[
                {
                  id: 34,
                  file_path: [
                    "Group_1#35",
                    "Project_1",
                    "Sensor_1",
                    "2023-07-20T14:34:57.897Z",
                  ],
                  timestamp: "2023-07-20T18:34:57.897Z",
                  type: "vibration",
                  axis: "x",
                  sampling_frequency: "1000",
                  vibration_raw: [
                    {
                      x: 0,
                      y: 2,
                    },
                    {
                      x: 0.001,
                      y: 4.585925586358032,
                    },
                    {
                      x: 0.002,
                      y: -0.5729762047974136,
                    },
                  ],
                  acceleration_fft: [
                    {
                      x: 0,
                      y: 6,
                    },
                    {
                      x: 1,
                      y: -4.585,
                    },
                    {
                      x: 2,
                      y: -12.746,
                    },
                  ],
                  acceleration_rms: "2.345135379171016",
                  acceleration_kurtosis: "1.8966497895798966",
                  acceleration_p2p: "9.973838408092822",
                  name: "2023-07-20T14:34:57.897Z",
                },
                {
                  id: 35,
                  file_path: [
                    "Group_1#35",
                    "Project_1",
                    "Sensor_1",
                    "2023-07-20T14:34:57.897Z",
                  ],
                  timestamp: "2023-07-20T18:35:03.385Z",
                  type: "audio",
                  sampling_frequency: "1000",
                  audio_raw: [
                    {
                      x: 0,
                      y: 2,
                    },
                    {
                      x: 0.001,
                      y: 5.586358032,
                    },
                    {
                      x: 0.002,
                      y: -0.16,
                    },
                  ],
                  audio_fft: [
                    {
                      x: 0,
                      y: 2,
                    },
                    {
                      x: 1,
                      y: 5.82,
                    },
                    {
                      x: 2,
                      y: -0.9620416,
                    },
                  ],
                  audio_rms: "2.3573318298961383",
                  audio_kurtosis: "1.8986454310446617",
                  audio_p2p: "9.79026309902909",
                  name: "2023-07-20T14:35:03.385Z",
                },
                {
                  id: 36,
                  file_path: [
                    "Group_1#35",
                    "Project_1",
                    "Sensor_1",
                    "2023-07-20T14:34:57.897Z",
                  ],
                  timestamp: "2023-07-20T18:35:07.559Z",
                  type: "temperature",
                  temperature: 21.55,
                  name: "2023-07-20T14:35:07.559Z",
                },
              ]}
            />
          </>
        }
      />
      <Section
        title="HTTP Request Format"
        children={
          <>
            <div>
              <p
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Data Download API"].requestFormat,
                }}
              />
            </div>
            <img
              style={{ width: "100%", marginBlock: "-3rem" }}
              alt="request-example"
              src={RequestExample}
            />
          </>
        }
      />
    </>
  );
};

export default DataDownloadAPI;
