import styles from "../../Styles/GraphWrapper.module.css";

import React, { Dispatch, ReactNode, SetStateAction, useContext, useRef, useState } from "react";

import PageWrapper from "./PageWrapper";
import ZoomPopUp from "./ZoomPopUp";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import FaultLegend from "./FaultLegend";
import { LineColors } from "../../Utils/GraphUtils";
import PopUp from "../PopUp";

import FileSaver from "file-saver";
import saveSvgAsPng from "save-svg-as-png";

interface Props {
  title: string;
  mobile: boolean;
  helpPage?: string;
  legendItems?: (string | { name: string, color: string })[];
  titleChildren?: ReactNode;
  mobileTitleChildren?: ReactNode;
  headerChildren?: ReactNode;
  options?: ReactNode;
  graph?: ReactNode;
  zoomSelected?: boolean;
  setZoomSelected?: Dispatch<SetStateAction<boolean>>;
  zoomOut?: () => void;
  data: { [key: string]: number }[] | undefined;
}

/*
 * This is a wrapper for the graphs, it includes the buttons present in all graphs
 */
const GraphWrapper = ({
  title,
  mobile,
  helpPage,
  legendItems,
  titleChildren,
  mobileTitleChildren,
  headerChildren,
  options,
  graph,
  zoomSelected,
  setZoomSelected,
  zoomOut,
  data,
}: Props) => {
  
  // Navigation
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();

  // Zoom
  const [zoomPopUpOpen, setZoomPopUpOpen] = useState(false);
  const zoomInBtnRef = useRef<HTMLDivElement>(null);
  
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);

  const svg = useRef<HTMLDivElement>(null);

  // DOWNLOADING/SAVING THE GRAPH FUNCTIONS

  const getFileName = (): string => {
    return title + " Graph";
  };

  const saveCSV = () => {
    if (!data) return;

    let csvData = "";
    
    // Add the headers
    if (data.length > 0) {
      for (const key in data[0]) {
        if (data[0][key] !== undefined) {
          csvData += key + ",";
        }
      }
      
      for (let i = 0; i < data.length; i++) {
        csvData += "\n";
        for (const key in data[i]) {
          if (data[i][key] !== undefined) {
            csvData += data[i][key] + ",";
          }
        }
      }
    }

    // Create a blob of the data
    const fileToSave = new Blob([csvData], {
      type: "text/csv;charset=utf-8;",
    });

    // Save the file
    FileSaver.saveAs(fileToSave, getFileName() + ".csv");
  };

  const saveJSON = () => {
    if (!data) return;

    // Create a blob of the data
    const fileToSave = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });

    // Save the file
    FileSaver.saveAs(fileToSave, getFileName() + ".json");
  };

  const savePNG = async () => {
    const element: any = svg.current?.getElementsByTagName("svg")[0];
    saveSvgAsPng.saveSvgAsPng(element, getFileName() + ".png", {
      backgroundColor: "white",
    });
  };

  const saveSVG = () => {
    if (svg.current) {
      const element: any = svg.current?.getElementsByTagName("svg")[0];

      const svgURL = new XMLSerializer().serializeToString(element);
      const svgBlob = new Blob([svgURL], {
        type: "image/svg+xml;charset=utf-8",
      });

      FileSaver.saveAs(svgBlob, getFileName() + ".svg");
    }
  };

  const allTitleChildren = (
    <>
      <div className={styles.menuBtns}>
        {/* Help */}
        {helpPage && <div
          className={`${styles.headerButton} ${styles.question}`}
          onClick={() => {
            tabInstance.select("");
            tabInstance.refresh();
            setTimeout(() => navigate(`/help/${helpPage}`), 0);
          }}
        />}

        {/* Selects the zoom tool so the drag and zoom functionality becomes avaiable */}
        {setZoomSelected && <div
          className={`${styles.headerButton} ${styles.zoomIn}`}
          ref={zoomInBtnRef}
          onClick={() => {
            if (!zoomSelected) zoomInBtnRef.current?.classList.add("zoomBtnSelected");
            else zoomInBtnRef.current?.classList.remove("zoomBtnSelected");
            if (
              !zoomSelected &&
              [null, "true"].includes(localStorage.getItem("showZoomPopUp"))
            )
              setZoomPopUpOpen(true);
            setZoomSelected((ZoomSelected) => !ZoomSelected);
          }}
        />}

        {/* Zoom out graph to minimum zoom */}
        {zoomOut && <div
          className={`${styles.headerButton} ${styles.zoomOut}`}
          onClick={zoomOut}
        />}

        {/* Download */}
        <div
          className={`${styles.headerButton} ${styles.download}`}
          onClick={() => setDownloadModalOpen(true)}
        />

        {titleChildren}
      </div>
    </>
  );

  const allMobileTitleChildren = (
    <>
      {/* Help */}
      {helpPage && <div
        className={styles.mobileTitleButton}
        onClick={() => {
          tabInstance.select("");
          tabInstance.refresh();
          setTimeout(() => navigate(`/help/${helpPage}`), 0);
        }}
      >
        <div className={styles.question} />
        Help
      </div>}

      {/* Selects the zoom tool so the drag and zoom functionality becomes avaiable */}
      {setZoomSelected && <div
        className={styles.mobileTitleButton}
        onClick={() => {
          if (!zoomSelected) zoomInBtnRef.current?.classList.add("zoomBtnSelected");
          else zoomInBtnRef.current?.classList.remove("zoomBtnSelected");
          if (
            !zoomSelected &&
            [null, "true"].includes(localStorage.getItem("showZoomPopUp"))
          )
            setZoomPopUpOpen(true);
          setZoomSelected((ZoomSelected) => !ZoomSelected);
        }}
      >
        <div className={styles.zoomIn} />
        Zoom In
      </div>}

      {/* Zoom out graph to minimum zoom */}
      {zoomOut && <div
        className={styles.mobileTitleButton}
        onClick={zoomOut}
      >
        <div className={styles.zoomOut} />
        Zoom Out
      </div>}

      {/* Download */}
      <div
        className={styles.mobileTitleButton}
        onClick={() => setDownloadModalOpen(true)}
      >
        <div className={styles.download} />
        Download
      </div>

      {mobileTitleChildren}
    </>
  );

  return (
    <>
      <PopUp
        isOpen={downloadModalOpen ? true : false}
        setIsOpen={setDownloadModalOpen}
        helpUrl={`/exporting_data`}
        title={
          <>
            Download {title} Data
            <div className={styles.helpIcon} />
          </>
        }
      >
        <div>
          <div
            className={styles.downloadModalBtn}
            onClick={() => {
              savePNG();
            }}
          >
            <div className={`${styles.graphIcon} ${styles.icon}`} />
            Save Graph as <em>PNG</em>
          </div>
          <div
            className={styles.downloadModalBtn}
            onClick={() => {
              saveSVG();
            }}
          >
            <div className={`${styles.svgIcon} ${styles.icon}`} />
            Save Graph as <em>SVG</em>
          </div>
          <div
            className={styles.downloadModalBtn}
            onClick={() => {
              saveCSV();
            }}
          >
            <div className={`${styles.tableIcon} ${styles.icon}`} />
            Save Graph Data as <em>CSV</em>
          </div>
          <div
            className={styles.downloadModalBtn}
            onClick={() => {
              saveJSON();
            }}
          >
            <div className={`${styles.jsonIcon} ${styles.icon}`} />
            Save Graph Data as <em>JSON</em>
          </div>
        </div>
      </PopUp>
      <ZoomPopUp isOpen={zoomPopUpOpen} setIsOpen={setZoomPopUpOpen} />
      <PageWrapper title={title} mobile={mobile} titleChildren={allTitleChildren} mobileTitleChildren={allMobileTitleChildren} headerChildren={headerChildren}>
        <div className={styles.graph} ref={svg}>
          <div className={styles.legend}>
            {legendItems && <div className={styles.faultLegend}>
              {legendItems.filter((legendItem) => legendItem !== "").map((legendItem, i) => (
                <FaultLegend 
                  key={i} 
                  title={typeof legendItem === "string" ? legendItem : legendItem.name} 
                  color={typeof legendItem === "string" ? LineColors[legendItem] : legendItem.color} />
              ))}
            </div>}
            {options}
          </div>
          {graph}
        </div>
      </PageWrapper>
    </>
  );
};

export default GraphWrapper;