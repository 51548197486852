import { Sample, TrendsData, TemperatureData, SampleData } from "./Types";

export async function getAnalysisSensorSamples(sensorId: number, measurementType: string, axis: "x" | "y" | "z" = "x"): 
Promise<Sample[]> {
  const response = await fetch(`/analysis_sensor_samples/${sensorId}/${measurementType}/${axis}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getSensorTrendsData(sensorId: number, measurementType: string, axis: "x" | "y" | "z" = "x", dataType: "acceleration" | "velocity" | "audio", from?: number, to?: number): 
Promise<TrendsData[]> {
  const response = await fetch(`/analysis_sensor_trends_data/${sensorId}/${measurementType}/${axis}/${dataType}${from ? `?from=${from}` : ""}${from && to ? `&to=${to}` : ""}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getSampleData(sampleId: number, orgId?: number): 
Promise<SampleData> {
  const response = await fetch(`/sample_data/${sampleId}/${orgId || -1}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getSensorTemperatureData(sensorId: number, from?: number, to?: number): 
Promise<TemperatureData[]> {
  const response = await fetch(`/analysis_sensor_temperature_data/${sensorId}${from ? `?from=${from}` : ""}${from && to ? `&to=${to}` : ""}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}
