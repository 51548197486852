import styles from "../../Styles/ErrorPages.module.css";
import { Link } from "react-router-dom";

/*
 * This page should appear when the user has navigated to a page
 * that they do not have access to (such as the device manager when not logged in).
 * It is a 401 error page.
 */
const Unauthorized = () => {
  return (
    <div className={styles.container}>
      <div className={`content`}>
        <h2>401 - Unauthorized</h2>
        You must be logged in to access the page you requested.
        <br />
        <br />
        <Link to="/">{`Go back home`}</Link>
        {` to log in and gain access.`}
      </div>
    </div>
  );
};

export default Unauthorized;
