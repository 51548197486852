import PageWrapper from "./PageWrapper";
/*
 * This is the loading page for when a dashboard section is not loaded yet.
 */
const LoadingPage = () => {
  return (
    <PageWrapper title={"Loading..."} mobile={false}>
      <div></div>
    </PageWrapper>
  );
};

export default LoadingPage;
