import styles from "../Styles/Spinner.module.css";

interface Props {
  fullscreen?: boolean;
}

/*
 * This is the spinning loading icon that is used throughout the app
 */
const Spinner = ({ fullscreen }: Props) => {
  return (
    <div
      className={`${styles.spinner} ${fullscreen ? styles.fullscreen : ""}`}
    />
  );
};

export default Spinner;
