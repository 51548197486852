import HelpContent from "../../HelpContent";
import TrendsViewImg from "../../../../Images/HelpArticles/TrendsView.png";
import TrendThresholds from "../../../../Images/HelpArticles/TrendThresholds.png";
import CaptionedImage from "../../CaptionedImage";
import Section from "../../Section";
import PageContents from "../../PageContents";

const TrendsView = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Trends View"].intro,
                }}
              />
              <PageContents sections={["Thresholds"]} />
            </div>
            <CaptionedImage
              img={TrendsViewImg}
              title="Trends View"
              caption="The Trends View for a sensor"
              width={100}
              position={"center"}
            />
          </>
        }
      />
      <Section
        title="Thresholds"
        children={
          <>
            <div>
              <p
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Trends View"].thresholds,
                }}
              />
            </div>
            <CaptionedImage
              img={TrendThresholds}
              title="Threshold Settings"
              caption="The Threshold Settings for velocity and acceleration"
              width={100}
              position={"center"}
            />
          </>
        }
      />
    </>
  );
};

export default TrendsView;
