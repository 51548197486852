import HelpContent from "../../HelpContent";
import EditTag from "../../../../Images/HelpArticles/EditTag.png";
import AddTags from "../../../../Images/HelpArticles/AddTags.png";
import SampleViewImg from "../../../../Images/HelpArticles/SampleView.png";
import SampleNotes from "../../../../Images/HelpArticles/SampleNotes.png";
import CaptionedImage from "../../CaptionedImage";
import Section from "../../Section";
import PageContents from "../../PageContents";

const SampleView = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Sample View"].intro,
                }}
              />
              <PageContents
                sections={["Tagging Samples", "Annotating Samples"]}
              />
            </div>
            <CaptionedImage
              img={SampleViewImg}
              title="Trends View"
              caption="The Trends View for a sensor"
              width={100}
              position={"center"}
            />
          </>
        }
      />
      <Section
        title="Tagging Samples"
        children={
          <>
            <div>
              <p
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Sample View"].tagging1,
                }}
              />
            </div>
            <CaptionedImage
              img={AddTags}
              title="Threshold Settings"
              caption="The Threshold Settings for velocity and acceleration"
              width={100}
              position={"center"}
            />
            <div>
              <p
                style={{ float: "left", width: "45%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Sample View"].tagging2,
                }}
              />
            </div>
            <CaptionedImage
              img={EditTag}
              title="Threshold Settings"
              caption="The Threshold Settings for velocity and acceleration"
              width={50}
              position={"right"}
            />
          </>
        }
      />
      <Section
        title="Annotating Samples"
        children={
          <>
            <div>
              <p
                style={{ width: "100%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Sample View"].sampleNotes,
                }}
              />
            </div>
            <CaptionedImage
              img={SampleNotes}
              title="Note Editor"
              caption="The note editor for a sample"
              width={80}
              position={"center"}
            />
          </>
        }
      />
    </>
  );
};

export default SampleView;
