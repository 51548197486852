import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../Styles/InvitePage.module.css";
import { PopupConfigOptions, PopupLoginOptions, User } from "@auth0/auth0-react";

/**
 * This is the page that is displayed when a user clicks on an invite link.
 * Props:
 * - user: the user's information from Auth0
 * - loginWithPopup: function to log the user in by opening a popup (should be obtained from Auth0 in the parent component)
 */
const InvitePage = ({
  user,
  loginWithPopup,
}: {
  user: User | undefined;
  loginWithPopup: (options?: PopupLoginOptions | undefined, config?: PopupConfigOptions | undefined) => Promise<void>;
}) => {
  const navigate = useNavigate();

  // The invite data from the database associated with the uuid in the link
  const [invite, setInvite] = useState<{
    id: string;
    created_by: string;
    org_path: string;
    org_id: number;
    user_type: "industry" | "researcher";
  } | null>(null);

  // The link is in the form of a uuid so we need to get the data from the database
  useEffect(() => {
    fetch(`/decode_link/${window.location.pathname.split("/")[2]}`)
      .then((res) => res.json())
      .then((data) => {
        localStorage.setItem("user_type", data?.user_type); // We just want to make sure that the user is logged in as the correct user type
        setInvite(data);
      });
  }, []);

  // When the user accepts or declines the invite, we need to delete the invite link from the database and redirect them to the correct page
  const onInviteAnswered = () =>
    fetch(`/delete_invite_link/${invite?.id}`, {
      method: "DELETE",
    }).then(() => {
      localStorage.setItem(
        "currentTab",
        invite?.user_type === "industry" ? "devicesTab" : "samplesTab"
      );
      navigate("/");
      window.location.reload();
    });

  return (
    <div className={`${styles.invite} ${styles.invitePage}`}>
      <h1>
        {invite?.created_by}
        <br />
        invited you to join
        <br />
        <span>
          {
            /* Get the name of the org (org path is org name + "/") */
            invite?.org_path?.slice(0, -1)
          }
        </span>
      </h1>
      {user ? (
        <div>
          <button
            onClick={() => {
              fetch(`/add_viewer/${invite?.org_id}/${user?.sub}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  user_type: invite?.user_type,
                }),
              }).then(() => onInviteAnswered());
            }}
          >
            Accept
          </button>
          <button
            className={styles.declineBtn}
            onClick={() => onInviteAnswered()}
          >
            Decline
          </button>
        </div>
      ) : (
        <>
          {/* If the user is not logged in, we need to log them in before they can accept the invite */}
          <button
            onClick={() => {
              loginWithPopup();
            }}
          >
            Sign In or Create an Account
          </button>
        </>
      )}
    </div>
  );
};

export default InvitePage;
