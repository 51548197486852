import { MachineSettings, TemperatureSettings } from "./Types";

export async function getTemperatureSettings(sensorId: number): 
Promise<TemperatureSettings> {
  const response = await fetch(`/sensor_temperature_settings/${sensorId}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function updateTemperatureSettings(sensorId: number, temperatureSettings: TemperatureSettings):
Promise<TemperatureSettings> {
  const response = await fetch(`/sensor_temperature_settings/${sensorId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ temperatureSettings }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getMachineSettings(sensorId: number): 
Promise<MachineSettings> {
  const response = await fetch(`/sensor_machine_settings/${sensorId}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function updateMachineSettings(sensorId: number, machineSettings: MachineSettings):
Promise<MachineSettings> {
  const response = await fetch(`/sensor_machine_settings/${sensorId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ machineSettings }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}
