import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  lazy,
  Suspense,
  useContext,
} from "react";
import PopUp from "../PopUp";
import FileBrowser from "../FileBrowserComponent";
import { User } from "@auth0/auth0-react";
import Spinner from "../Spinner";
import {
  handleCreateFolder,
  handleRenameFolder,
  handleDeleteFolder,
  handleDeleteFile,
  handleCreateOrganization,
  FileError,
  handleRenameFile,
  saveFiles,
  getFiles,
} from "../../Utils/FileUtils";

// Styles
//import "../../../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css";
import styles from "../../Styles/DeviceManager.module.css";
import ChooseLocation from "./ChooseLocation/ChooseLocation";
import ReactMarkdown from "react-markdown";
import Settings from "../../Images/SettingsIcon.png";
import { useNavigate } from "react-router-dom";
import InvitePopUp from "../InvitePage/InvitePopUp";
import DownloadMeasurement from "./DownloadMeasurement/DownloadMeasurement";
import { AppContext } from "../App";
import DashboardVibration from "../AnalysisPage/DashboardVibration";
import DashboardTemperature from "../AnalysisPage/DashboardTemperature";
import DashboardAudio from "../AnalysisPage/DashboardAudio";
import { MachineSettings } from "../AnalysisPage/HTTPRequests/Types";

// Modals
const ShareSettingsModal = lazy(
  () => import("./SettingsModals/ShareSettingsModal")
);
const FileErrorModal = lazy(() => import("./FileErrorModal/FileErrorModal"));

// Get File Data from DB
const loadFiles = async (
  user: any,
  setFolders: React.Dispatch<any>,
  setSamples: React.Dispatch<any>,
  setOrgs: React.Dispatch<any>,
  tabInstance: any,
) => {
  if (user?.sub) {
    const data = await getFiles(user?.sub, "researcher");
    setFolders(data.folders);
    setSamples(data.samples);
    setOrgs(data.orgs);
    tabInstance.refresh();
    return data;
  }
};

// Creates a sample JSON object for the sample manager
const sample = async (
  {
    id,
    mtime,
    file_path,
    measurement_type,
  }: {
    id: number;
    mtime: Date;
    file_path: string;
    axes: ("x" | "y" | "z")[];
    measurement_type: string;
  },
  userID: string
) => {
  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "measurement",
        file_id: id,
        user_id: userID,
        user_type: "researcher",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: id,
    key: file_path,
    sampleId: id,
    customType: measurement_type ? measurement_type : "Sample",
    permissions: permissionsVal,
  };
};

// Creates a organization JSON object for the device manager
const org = async (dbID: string, location: string, userID: string) => {
  const key = location;

  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "org",
        file_id: dbID,
        user_id: userID,
        user_type: "researcher",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;

  return {
    dbID: dbID,
    key: key,
    isOrg: true,
    permissions: permissionsVal,
    shareFunc: async () => {
      const res = await fetch(
        `/share_url/?user_id=${userID}&file_path=${encodeURIComponent(key)}`
      );
      return `${window.location.origin}/share/${await res.json()}`;
    },
  };
};

// Creates a folder JSON object for the device manager
const folder = async (
  dbID: string,
  key: string,
  userID: string,
  hasSamples: boolean,
  openTab: any,
  ifAuthorized: any,
  samples: any[]
) => {
  // Get the user's permissions on the file
  const permissions = await fetch(
    `/get_file_permissions/${encodeURIComponent(
      JSON.stringify({
        file_type: "folder",
        file_id: dbID,
        user_id: userID,
        user_type: "researcher",
      })
    )}`
  ).then((res: any) => {
    return res.json();
  });
  const permissionsVal = permissions[0]?.access_type;
  return {
    dbID: dbID,
    key: key,
    permissions: permissionsVal,
    analysisFunc: hasSamples
      ? () => {
          const otherSamples = samples
            .filter(
              (s) =>
                s.file_path.startsWith(key) &&
                s.file_path.split("/").length === key.split("/").length
            )
            .map((s) => {
              return {
                id: s.id,
                sensorId: s.sensor_id,
                Time: new Date(s.mtime),
                type: s.measurement_type,
                sampling_frequency: s.sampling_frequency,
                axis: s.axis,
              };
            });

          if (
            otherSamples.filter(
              (s) => s.type === "vibration" || s.type === "unknown"
            ).length > 0
          ) {
            if (otherSamples.filter((s) => s.axis === "x").length > 0) {
              openTab(
                `${key}x`,
                `Vib. X: ${key.split("/").slice(-2)[0]}`,
                "vibration_analysis",
                "chart-simple",
                ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"x"} />)
              );
            }
            if (otherSamples.filter((s) => s.axis === "y").length > 0) {
              openTab(
                `${key}y`,
                `Vib. Y: ${key.split("/").slice(-2)[0]}`,
                "vibration_analysis",
                "chart-simple",
                ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"y"} />)
              );
            }
            if (otherSamples.filter((s) => s.axis === "z").length > 0) {
              openTab(
                `${key}z`,
                `Vib. Z: ${key.split("/").slice(-2)[0]}`,
                "vibration_analysis",
                "chart-simple",
                ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"z"} />)
              );
            }
            if (otherSamples.filter((s) => !s.axis && (s.type === "vibration" || s.type === "unknown")).length > 0) {
              openTab(
                `${key}`,
                `Vibration: ${key.split("/").slice(-2)[0]}`,
                "vibration_analysis",
                "chart-simple",
                ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={undefined} />)
              );
            }
          }
            
          if (otherSamples.filter((s) => s.type === "temperature").length > 0)
            openTab(
              key,
              `Temperature: ${key.split("/").slice(-2)[0]}`,
              "temperature_analysis",
              "chart-simple",
              ifAuthorized(
                "researcher",
                <DashboardTemperature folderPath={key} />
              )
            );
          if (otherSamples.filter((s) => s.type === "audio").length > 0)
            openTab(
              key,
              `Audio: ${key.split("/").slice(-2)[0]}`,
              "audio_analysis",
              "chart-simple",
              ifAuthorized(
                "researcher",
                <DashboardAudio folderPath={key} />
              )
            );
        }
      : undefined,
    shareFunc: async () => {
      const res = await fetch(
        `/share_url/?user_id=${userID}&file_path=${encodeURIComponent(key)}`
      );
      return `${window.location.origin}/share/${await res.json()}`;
    },
  };
};

interface Props {
  sortDevicesTabLast: any;
  user: User | undefined;
  ifAuthorized: any;
}

/**
 * This is the main page of the researcher side.
 * Here, the user can see all of their saved samples and organize them in groups and folders.
 * They can also upload and download data.
 */
const SampleManager = ({
  sortDevicesTabLast,
  user,
  ifAuthorized,
}: Props) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();

  // The Files (Folders, Samples)
  const [files, setFiles] = useState<any>(null);
  const [folders, setFolders] = useState<any>(null);
  const [samples, setSamples] = useState<any>(null);
  const [orgs, setOrgs] = useState<any>(null);

  const [machineSettingsModalOpen, setMachineSettingsModalOpen] =
    useState(false);
  const [machineSettings, setMachineSettings] =
    useState<MachineSettings | null>(null);

  // Open a new tab
  const openTab = useCallback(
    (
      id: string,
      name: string,
      type: string,
      icon: string,
      panelComponent: any
    ) => {
      const tab_id = `$${type}$${id}`;
      const page = {
        id: tab_id,
        direction: "ltr",
        iconClass: `fa fa-${icon}`,
        title: name,
        lazy: true,
        panelComponent: () => panelComponent,
      };

      // Save Opened Tabs in Local Storage
      if (localStorage.getItem("tabs")) {
        localStorage.setItem(
          "tabs",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("tabs") as string),
            page,
          ])
        );
      } else {
        localStorage.setItem("tabs", JSON.stringify([page]));
      }

      tabInstance.open(page).then(() => sortDevicesTabLast());
    },
    [tabInstance, sortDevicesTabLast]
  );

  // Load Files on Initialization
  useEffect(() => {
    loadFiles(user, setFolders, setSamples, setOrgs, tabInstance);
  }, [user, setFolders, setSamples, setOrgs, tabInstance]);

  // Make sure the files are in the correct format for the file browser
  useEffect(() => {
    const load = async () => {
      // Load in files
      const newFiles: any = [
        ...(await Promise.all(
          orgs.map(async (o: any) => {
            if (!user?.sub) return;
            return await org(o.id, o.file_path, user?.sub);
          })
        )),
        ...(await Promise.all(
          folders.map(async (f: any) => {
            if (!user?.sub) return;
            return await folder(
              f.id,
              f.file_path,
              user?.sub,
              samples.filter(
                (s: any) => !s.file_path.replace(f.file_path, "").includes("/")
              ).length > 0,
              openTab,
              ifAuthorized,
              samples
            );
          })
        )),
        ...(await Promise.all(
          samples.map(async (s: any, i: number) => {
            if (!user?.sub) return;
            return await sample(s, user?.sub);
          })
        )),
        ...(await fetch(
          `/readme/${encodeURIComponent(JSON.stringify([...folders, ...orgs]))}`
        )
          .then((res: any) => res.json())
          .then((data: any) =>
            Promise.all(
              data.map(async (d: any) => {
                const permissions = await fetch(
                  `/get_file_permissions/${encodeURIComponent(
                    JSON.stringify({
                      file_type: "readme",
                      file_id: d.readme_id,
                      user_id: user?.sub,
                      user_type: "researcher",
                    })
                  )}`
                )
                  .then((res: any) => {
                    return res.json();
                  })
                  .then((perm) => perm[0]?.access_type);
                return {
                  dbID: d.readme_id,
                  key: d.file_path,
                  customType: "readme",
                  content: d.content,
                  viewFunc: () => {
                    setReadmeFile({ ...d, permissions: permissions });
                    setReadmePopupOpen(true);
                  },
                  permissions: permissions,
                };
              })
            )
          )),
      ].filter((file) => !!file);
      // Pushing loaded files into state
      setFiles(newFiles);
    };

    if (folders && samples && orgs) {
      load();
    }
  }, [openTab, folders, orgs, samples, user?.sub, ifAuthorized]);

  const [readmePopupOpen, setReadmePopupOpen] = useState<boolean>(false);
  const [readmeFile, setReadmeFile] = useState<any>(null);
  const [readmeEdit, setReadmeEdit] = useState<boolean>(false);

  const managerRef = useRef<any>(null);

  // Queue used for multifile actions (multi-delete and multi-move)
  const [queue, setQueue] = useState<any>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  // Queue for when multiple actions are done at the same time
  useEffect(() => {
    if (queue && queue.length > 0) {
      // Run first item in queue
      if (queue[0].action === "Move") {
        handleRenameFolder(
          files,
          queue[0].oldKey,
          queue[0].newKey,
          setFileErrorModalOpen,
          "Move",
          () => setQueue(queue.slice(1))
        );
      } else if (queue[0].action === "DeleteFolder") {
        handleDeleteFolder(files, [queue[0].key], () =>
          setQueue(queue.slice(1))
        );
      } else if (queue[0].action === "DeleteFile") {
        handleDeleteFile(
          files,
          [queue[0].key],
          () => setQueue(queue.slice(1)),
          "sample"
        );
      }
    } else {
      loadFiles(user, setFolders, setSamples, setOrgs, tabInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queue, user, setFolders, setSamples, setOrgs]);

  // Modal Open/Close States
  const [fileErrorModalOpen, setFileErrorModalOpen] =
    useState<FileError | null>(null);
  const [shareSettingsModalOpen, setShareSettingsModalOpen] = useState(false);

  // The name of the folder open in a modal
  const [modalItemName, setModalItemName] = useState<string>("");
  const [modalFile, setModalFile] = useState<any>();
  const [newOrgName, setNewOrgName] = useState("");
  const [newOrgTeam, setNewOrgTeam] = useState<any>();

  // Download
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [downloadFilePaths, setDownloadFilePaths] = useState<string[] | null>(
    null
  );

  // Upload
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [uploadLocation, setUploadLocation] = useState<string>("");

  if (!files) return <Spinner />;

  return (
    <>
      <InvitePopUp
        user={user}
        reloadFiles={() =>
          loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
        }
      />

      <PopUp
        isOpen={readmePopupOpen}
        setIsOpen={setReadmePopupOpen}
        title={readmeFile?.file_path?.split("/").slice(-2)[0] + " - Readme"}
        closeFunction={() => setReadmeEdit(false)}
      >
        {readmeEdit ? (
          <form
            className={styles.editReadme}
            onSubmit={async (e) => {
              e.preventDefault();
              await fetch("/update_readme", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: readmeFile?.readme_id,
                  content: readmeFile?.content,
                }),
              }).then(() =>
                loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
              );
              setReadmeEdit(false);
            }}
          >
            <textarea
              value={readmeFile?.content}
              onChange={(e) =>
                setReadmeFile({ ...readmeFile, content: e.target.value })
              }
              autoFocus
              placeholder="Add description"
              cols={75}
              rows={20}
            />
            <button type="submit">Save</button>
          </form>
        ) : (
          <div className={styles.viewReadme}>
            <ReactMarkdown className="markdown">
              {readmeFile?.content}
            </ReactMarkdown>
            {["editor", "owner"].includes(readmeFile?.permissions) && (
              <button
                onClick={() => setReadmeEdit(true)}
                style={{ marginTop: "1rem" }}
              >
                {readmeFile?.content === "" ? "Add a description" : "Edit"}
              </button>
            )}
          </div>
        )}
      </PopUp>

      <Suspense fallback={<></>}>
        {/* Sharing Settings Modal */}
        <PopUp
          isOpen={shareSettingsModalOpen}
          setIsOpen={setShareSettingsModalOpen}
          title={modalItemName}
          confirmBtnTxt={"Done"}
          confirmBtnFunc={() => {
            if (!modalFile) {
              handleCreateOrganization(
                newOrgTeam,
                newOrgName,
                () =>
                  loadFiles(user, setFolders, setSamples, setOrgs, tabInstance),
                setFileErrorModalOpen,
                setShareSettingsModalOpen,
                "researcher"
              );
            }
          }}
        >
          <ShareSettingsModal
            closeModal={() => setShareSettingsModalOpen(false)}
            file={modalFile}
            user={user}
            setNewOrgName={setNewOrgName}
            newOrgName={newOrgName}
            setNewOrgTeam={setNewOrgTeam}
            userType="researcher"
          />
        </PopUp>

        {/* File Action Error Modal */}
        <PopUp
          isOpen={fileErrorModalOpen !== null}
          setIsOpen={() => setFileErrorModalOpen(null)}
          title={
            fileErrorModalOpen
              ? `${fileErrorModalOpen?.fileType} ${fileErrorModalOpen?.actionType} Failed`
              : ""
          }
        >
          <FileErrorModal fileError={fileErrorModalOpen} />
        </PopUp>
      </Suspense>

      <PopUp
        
        isOpen={downloadModalOpen ? true : false}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setDownloadModalOpen(false);
          }
        }}
        helpUrl={`/downloading_data`}
        title={
          <>
            Download Data
            <div className={styles.helpIcon} />
          </>
        }
      >
        <DownloadMeasurement downloadFilePaths={downloadFilePaths || []} />
      </PopUp>

      <PopUp
        
        isOpen={uploadModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setUploadModalOpen(false);
          }
        }}
        confirmBtnTxt={"Upload"}
        confirmBtnFunc={async () => {
          await saveFiles(
            uploadLocation,
            folders,
            files,
            user?.sub!,
            setFileErrorModalOpen,
            uploadedFiles,
            {
              ...machineSettings!,
              rolling_elem_settings_active: !!(
                machineSettings?.num_of_rolling_elems ||
                machineSettings?.angle_of_contact ||
                machineSettings?.pitch_diameter ||
                machineSettings?.rolling_elem_diameter
              ),
              motor_settings_active: !!(
                machineSettings?.motor_speed ||
                machineSettings?.synch_speed ||
                machineSettings?.num_of_poles ||
                machineSettings?.num_of_rotors ||
                machineSettings?.num_of_fan_blades
              ),
              gear_settings_active: !!(
                machineSettings?.num_of_teeth ||
                machineSettings?.num_of_teeth_in ||
                machineSettings?.num_of_teeth_out ||
                machineSettings?.num_of_assembly_phases
              ),

              iso_settings_active: !!(machineSettings?.iso_group),
            }
          );
          setMachineSettings(null);
          loadFiles(user, setFolders, setSamples, setOrgs, tabInstance);
        }}
        closeFunction={() => {
          setUploadedFiles([]);
          setUploadLocation("");
        }}
        helpUrl={`/uploading_data`}
        title={
          <>
            Upload Data
            <div className={styles.helpIcon} />
          </>
        }
      >
        <div className={styles.uploadPopUp}>
          <div className={styles.uploadedFiles}>
            {uploadedFiles.map((file, i) => (
              <div key={i}>{file?.name}</div>
            ))}
            <input
              type="file"
              id="fileUploader"
              multiple
              style={{ display: "none" }}
              accept=".csv,.json"
              onChange={async (e) => {
                const files = e.target.files;
                setUploadedFiles(
                  await Promise.all(
                    Array.from(files!).map((file) => {
                      return new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          resolve({
                            name: file.name,
                            content: e.target?.result,
                            type: file.type,
                            upload: true,
                          });
                        };
                        reader.readAsText(file);
                      });
                    })
                  )
                );
              }}
            />
          </div>
          <button
            className={styles.uploadBtn}
            onClick={() => {
              const elem = document.getElementById("fileUploader");
              if (elem && document.createEvent) {
                const evt = document.createEvent("MouseEvents");
                evt.initEvent("click", true, false);
                elem.dispatchEvent(evt);
              }
            }}
          >
            +
          </button>
          <button
            className={styles.settingsBtn}
            onClick={() => {
              setMachineSettingsModalOpen(true);
            }}
          >
            <img alt={"settings"} src={Settings} />
            <span>Machine Settings</span>
          </button>
          <PopUp
            isOpen={machineSettingsModalOpen}
            setIsOpen={setMachineSettingsModalOpen}
            title="Machine Settings"
            confirmBtnTxt={"Save"}
            confirmBtnFunc={() => {}}
            closeFunction={(action?: string) => {
              if (action === "confirm") {
                return;
              }
              setMachineSettings(null);
            }}
          >
            <div className={styles.machineSettings}>
              <h2>Rolling Elements</h2>
              <div className={styles.machineSettingsElement}>
                <span># of rolling elements: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_rolling_elems}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_rolling_elems: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span>Angle of contact: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.angle_of_contact}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        angle_of_contact: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span>Pitch diameter: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.pitch_diameter}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        pitch_diameter: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span>Rolling element diameter: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.rolling_elem_diameter}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        rolling_elem_diameter: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <h2>Motor</h2>
              <div className={styles.machineSettingsElement}>
                <span>Motor speed: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.motor_speed}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        motor_speed: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span>Synchronous speed: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.synch_speed}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        synch_speed: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of poles: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_poles}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_poles: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of rotors: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_rotors}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_rotors: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of fan blades: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_fan_blades}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_fan_blades: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <h2>Gears</h2>
              <div className={styles.machineSettingsElement}>
                <span># of teeth: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_teeth}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_teeth: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of teeth in: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_teeth_in}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_teeth_in: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of teeth out: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_teeth_out}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_teeth_out: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <div className={styles.machineSettingsElement}>
                <span># of assembly phases: </span>
                <input
                  type="text"
                  size={10}
                  inputMode="numeric"
                  value={machineSettings?.num_of_assembly_phases}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        num_of_assembly_phases: e.target.value,
                      };
                    });
                  }}
                />
              </div>
              <h2>ISO Category (<a href="https://www.iso.org/standard/78311.html" target="blank_">ISO 20816-3</a>)</h2>
              <div className={styles.machineSettingsElement}>
                <span>Group: </span>
                <select 
                  value={machineSettings?.iso_group}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        iso_group: parseInt(e.target.value),
                      };
                    });
                  }}
                >
                  <option style={{ display: "none" }} value="0"></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
              <div className={styles.machineSettingsElement}>
                <span>Rigidity: </span>
                <select 
                  value={machineSettings?.iso_flexible ? "1" : "0"}
                  onChange={(e) => {
                    setMachineSettings((current: any) => {
                      return {
                        ...current,
                        iso_flexible: !!parseInt(e.target.value),
                      };
                    });
                  }}
                >
                  <option value="0">Rigid</option>
                  <option value="1">Flexible</option>
                </select>
              </div>
            </div>
          </PopUp>
          <ChooseLocation
            location={uploadLocation}
            setLocation={setUploadLocation}
            orgs={orgs}
            folders={folders}
            user_id={user?.sub!}
          />
        </div>
      </PopUp>

      {/* File Browser Plugin
       *** For plugin development instructions/tips see:
       *** https://github.com/chrisvenczel/openPHM/wiki/Device-Manager */}
      <div className={`page`}>
        <div className={`content ${styles.filebrowser}`}>
          <FileBrowser
            browserType="sample"
            innerRef={managerRef}
            files={files}
            icons={{
              // File Icons
              Org: <i className="orgFileIcon" aria-hidden="true" />,
              Vibration: <i className="vibrationFileIcon" aria-hidden="true" />,
              Temperature: (
                <i className="temperatureFileIcon" aria-hidden="true" />
              ),
              Audio: <i className="audioFileIcon" aria-hidden="true" />,
              Readme: <i className="readmeFileIcon" aria-hidden="true" />,
              Folder: <i className="folderFileIcon" aria-hidden="true" />,
              FolderOpen: (
                <i className="folderOpenFileIcon" aria-hidden="true" />
              ),
              FolderAdd: <i className="folderAddFileIcon" aria-hidden="true" />,

              // Button Icons
              Rename: <i className="renameFileIcon" aria-hidden="true" />,
              Delete: <i className="deleteFileIcon" aria-hidden="true" />,
              OrganizationAdd: (
                <i className="organizationAddFileIcon" aria-hidden="true" />
              ),
              Share: <i className="shareFileIcon" aria-hidden="true" />,
              Download: <i className="downloadIcon" aria-hidden="true" />,
              ReadmeAdd: <i className="readmeAddFileIcon" aria-hidden="true" />,
              Tutorial: <i className="tutorialFileIcon" aria-hidden="true" />,
              LeaveOrg: <i className="leaveOrgFileIcon" aria-hidden="true" />,
            }}
            goToTutorial={() => {
              tabInstance.select("");
              tabInstance.refresh();
              setTimeout(() => {
                navigate("/help/researcher_tutorial_overview");
              }, 0);
            }}
            onCreateFolder={(key: string) => {
              handleCreateFolder(
                files,
                user?.sub,
                key,
                () =>
                  loadFiles(user, setFolders, setSamples, setOrgs, tabInstance),
                setFileErrorModalOpen
              );
            }}
            onMoveFolder={
              files
                .filter((f: any) => selectedFiles.some((s: any) => s === f.key))
                .filter(
                  (f: any) => !f.permissions.includes("viewer") && !f.isOrg
                ).length > 0
                ? (oldKey: string, newKey: string) => {
                    const newActions = selectedFiles
                      .map((key: string) => {
                        if (
                          queue.filter((action: any) => action.key === key)
                            .length > 0
                        ) {
                          return null;
                        } else {
                          const path =
                            newKey.split("/").length === 2
                              ? ""
                              : newKey.split("/").slice(0, -2).join("/") + "/";
                          const fileName = key.split("/").reverse()[1] + "/";
                          const newPath = path + fileName;
                          return {
                            action: "Move",
                            oldKey: key,
                            newKey: newPath,
                          };
                        }
                      })
                      .filter((e: any) => e !== null);
                    setQueue([...queue, ...newActions]);
                  }
                : undefined
            }
            onRenameFolder={(oldKey: string, newKey: string) =>
              handleRenameFolder(
                files,
                oldKey,
                newKey,
                setFileErrorModalOpen,
                "Rename",
                () => loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
              )
            }
            onRenameFile={(oldKey: string, newKey: string) =>
              handleRenameFile(
                files,
                oldKey,
                newKey,
                () =>
                  loadFiles(user, setFolders, setSamples, setOrgs, tabInstance),
                setFileErrorModalOpen,
                "Sample"
              )
            }
            onMoveFile={
              files
                .filter((f: any) => selectedFiles.some((s: any) => s === f.key))
                .filter(
                  (f: any) => !f.permissions.includes("viewer") && !f.isOrg
                ).length > 0
                ? (oldKey: string, newKey: string) => {
                    const newActions = selectedFiles
                      .map((key: string) => {
                        if (
                          queue.filter((action: any) => action.key === key)
                            .length > 0
                        ) {
                          return null;
                        } else {
                          return {
                            action: "Move",
                            oldKey: oldKey,
                            newKey: newKey,
                          };
                        }
                      })
                      .filter((e: any) => e !== null);
                    setQueue([...queue, ...newActions]);
                  }
                : undefined
            }
            onDeleteFolder={(key: string) =>
              handleDeleteFolder(files, key, () =>
                loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
              )
            }
            onDeleteFile={(key: string) =>
              handleDeleteFile(
                files,
                key,
                () =>
                  loadFiles(user, setFolders, setSamples, setOrgs, tabInstance),
                files.filter((file: any) => file.key === key[0])[0]
                  ?.customType === "readme"
                  ? "readme"
                  : "sample"
              )
            }
            confirmMultipleDeletionRenderer={() => (
              <div className={"deleting"}>
                <button
                  style={{
                    marginTop: "0",
                    width: "100%",
                  }}
                  onClick={() => {
                    const newActions = selectedFiles
                      .map((key: string) => {
                        if (
                          queue.filter((action: any) => action.key === key)
                            .length > 0
                        ) {
                          return null;
                        } else {
                          const isSensor = key.split("/").reverse()[0] !== "";
                          return {
                            action: isSensor ? "DeleteFile" : "DeleteFolder",
                            key: key,
                            isSensor: isSensor,
                          };
                        }
                      })
                      .filter((e: any) => e !== null)
                      .sort((x: any, y: any) => {
                        return y.isSensor - x.isSensor;
                      });
                    setQueue([...queue, ...newActions]);
                  }}
                >
                  Confirm Multiple Deletions
                </button>
              </div>
            )}
            renderStyle={"table"}
            // Custom Functions
            setSelectedFiles={(e: any) => setSelectedFiles(e)}
            orgSettings={(file: any) => {
              setModalItemName(
                `${file.name} - ${
                  file.permissions === "owner" ? "Manage" : "View"
                } Team`
              );
              setModalFile(file);
              setShareSettingsModalOpen(true);
            }}
            addOrganization={() => {
              setModalItemName(`New Group`);
              setNewOrgName("");
              setModalFile(null);
              setShareSettingsModalOpen(true);
            }}
            onLeaveOrg={(org: any) => {
              fetch(`/get_org/${org[0]}`)
                .then((res) => res.json())
                .then((orgId) => {
                  if (!orgId) return;
                  fetch(`/remove_permission/${orgId}/${user?.sub}`, {
                    method: "POST",
                  }).then(() =>
                    loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
                  );
                });
            }}
            addReadme={(e: any) => {
              const readme = files.filter(
                (file: any) =>
                  file.key.split("/").slice(0, -1).join("/") + "/" === e.key &&
                  file.customType === "readme"
              );
              if (readme.length > 0) {
                setReadmeFile({
                  file_path: readme[0].key,
                  content: readme[0].content,
                  permissions: readme[0].permissions,
                });
                setReadmePopupOpen(true);
              } else {
                fetch(`/add_readme`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    key: e.key,
                  }),
                }).then(() =>
                  loadFiles(user, setFolders, setSamples, setOrgs, tabInstance)
                );
              }
            }}
            customActions={[
              {
                name: "Copy File Path",
                onClick: () => {
                  navigator.clipboard.writeText(selectedFiles[0]);
                },
                icon: <i className="copyIcon" aria-hidden="true" />,
              },
              {
                name: "Upload",
                onClick: () => {
                  setUploadModalOpen(true);
                },
                icon: <i className="uploadIcon" aria-hidden="true" />,
              },
              {
                name: "Look Up Researchers",
                onClick: () => tabInstance.select("searchTab"),
                icon: <i className="orgFileIcon" aria-hidden="true" />,
              },
              {
                name: "Browse Samples",
                onClick: () => tabInstance.select("searchTab"),
                icon: <i className="vibrationFileIcon" aria-hidden="true" />,
              },
            ]}
            onDownloadFile={(filePaths: string[]) => {
              setDownloadModalOpen(true);
              setDownloadFilePaths(filePaths);
            }}
            onDownloadFolder={(filePaths: string[]) => {
              setDownloadModalOpen(true);
              setDownloadFilePaths(filePaths);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SampleManager;
