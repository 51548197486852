import { useState } from "react";
import styles from "../../Styles/CaptionedImage.module.css";
import PopUp from "../PopUp";

/*
 * This a an image with a caption
 * It is used in the help pages
 */
const CaptionedImage = ({
  img,
  title,
  caption,
  width,
  position,
}: {
  img: string;
  title: string;
  caption: string;
  width: number;
  position: "center" | "left" | "right";
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <PopUp isOpen={modalOpen} setIsOpen={setModalOpen} title={title}>
        <div
          style={{
            height: "65vh",
            maxWidth: "80vw",
            margin: 0,
            position: "relative",
          }}
        >
          <img
            src={img}
            alt={title}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        </div>
        <div style={{ textAlign: "center", paddingTop: "8px" }}>{caption}</div>
      </PopUp>
      <div
        style={{
          width: position === "center" ? "100%" : width + "%",
          float: position === "center" ? "none" : position,
        }}
        className={styles.container}
      >
        <div
          className={styles.innerBox}
          style={{
            width: position === "center" ? width + "%" : "100%",
          }}
        >
          <img onClick={() => setModalOpen(true)} alt={title} src={img} />
          <div className={styles.expand} />
          <div>{caption}</div>
        </div>
      </div>
    </>
  );
};

export default CaptionedImage;
