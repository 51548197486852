import { useCallback, useEffect, useRef, useState } from "react";
import styles from "../../../Styles/RelatedSamples.module.css";
import SelectTable from "./SelectTable";

/**
 * This components show the samples of a sensor (used in the same way as RelatedSamples.tsx)
 * The user has the option to filter by: type and axis
 * The user can select samples on this component and then save or download them
 * Props:
 * - sensor_id: the id of the sensor to get the samples from
 * - samplesSelected: selected samples state of the parent component
 * - setSamplesSelected: the function to set samplesSelected
 * - tags: the tags selected as filters in the parent component (we don't want to show samples that don't have the filter tags)
 */
const SensorSamples = ({
  sensor_id,
  samplesSelected,
  setSamplesSelected,
  tags,
}: {
  sensor_id: number;
  samplesSelected: any[];
  setSamplesSelected: (func: (curr: any[]) => any[]) => void;
  tags: { value: string }[];
}) => {
  const [axis, setAxis] = useState<string>("all");
  const [type, setType] = useState<string>("all");

  const [samples, setSamples] = useState<any[]>([]);
  const resultsRef = useRef<HTMLDivElement>(null);

  // Load samples whenever a filter changes
  useEffect(() => {
    fetch(
      `/sensor_samples/${encodeURIComponent(
        JSON.stringify({
          sensor_id,
          type,
          axis,
          tags: tags.map((tag) => tag.value),
          offset: 0,
        })
      )}`
    )
      .then((res) => res.json())
      .then((res) => {
        setSamples(res);
        setSamplesSelected(() => []);
      });
  }, [axis, type, sensor_id, setSamplesSelected, tags]);

  // Function to load more results when the user scrolls to the bottom
  const loadMoreResults = useCallback(async () => {
    const res = await fetch(
      `/sensor_samples/${encodeURIComponent(
        JSON.stringify({
          sensor_id,
          type,
          axis,
          tags,
          offset: samples.length,
        })
      )}`
    ).then((data) => data.json());
    setSamples((samples) => [...samples, ...res]);
  }, [axis, type, sensor_id, samples, tags]);

  // Add scroll event listener to load more results when the user scrolls to the bottom
  const loadingRef = useRef(false);
  useEffect(() => {
    const handleScroll = () => {
      if (resultsRef.current === null) return;
      if (
        resultsRef.current.scrollTop + resultsRef.current.clientHeight >=
          resultsRef.current.scrollHeight - 100 &&
        !loadingRef.current
      ) {
        loadingRef.current = true;
        loadMoreResults().then(() => {
          loadingRef.current = false;
        });
      }
    };

    const results = resultsRef.current;
    results?.addEventListener("scroll", handleScroll);
    return () => {
      results?.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreResults]);

  return (
    <div className={styles.relatedSamples}>
      <div className={styles.title}>
        <h2>Samples</h2>
      </div>

      <div className={styles.filters}>
        <div className={styles.filter}>
          <span>Type</span>
          <select onChange={(e) => setType(e.target.value)}>
            <option value="all">All</option>
            <option value="vibration">Vibration</option>
            <option value="temperature">Temperature</option>
            <option value="audio">Audio</option>
          </select>
        </div>
        {["all", "vibration"].includes(type) && (
          <div className={styles.filter}>
            <span>Axis</span>
            <select onChange={(e) => setAxis(e.target.value)}>
              <option value="all">All</option>
              <option value="x">X</option>
              <option value="y">Y</option>
              <option value="z">Z</option>
            </select>
          </div>
        )}
      </div>
      <SelectTable
        samples={samples}
        samplesSelected={samplesSelected}
        setSamplesSelected={setSamplesSelected}
        loadMoreResults={loadMoreResults}
      />
    </div>
  );
};

export default SensorSamples;
