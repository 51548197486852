import SharePageExample from "../../../../Images/SharePageExample.jpg";
import HelpContent from "../../HelpContent";
import CaptionedImage from "../../CaptionedImage";
import Section from "../../Section";

const SharingData = () => {
  return (
    <Section
      children={
        <>
          <div>
            <p>{HelpContent["Sharing Data"].intro}</p>
            <p>{HelpContent["Sharing Data"].explanation}</p>
            <CaptionedImage
              img={SharePageExample}
              title="Share Page Example"
              caption="What the recipient would see by clicking on the project share link"
              width={100}
              position={"center"}
            />
          </div>
        </>
      }
    />
  );
};

export default SharingData;
