import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth0Provider, CacheLocation } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { getConfig } from "./auth_config";
import { DndProvider } from "react-dnd";
import { ToastContainer } from "react-toastify";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-toastify/dist/ReactToastify.css";

import App from "./Components/App";

// This is globally accessible CSS
import "./index.css";
import "./markdown.css";

const history = createBrowserHistory();

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  cacheLocation: "localstorage" as CacheLocation,
  ...(config.audience ? { audience: config.audience } : null),
  onRedirectCallback,
  authorizationParams: {
    audience: "https://openphm.org",
    redirectUri: window.location.origin,
  },
  scope: "openid profile email",
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <DndProvider backend={HTML5Backend} key={1}>
    <ToastContainer />
    <Auth0Provider {...providerConfig}>
      <Router>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Router>
    </Auth0Provider>
  </DndProvider>
);
