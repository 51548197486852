import styles from "../../../Styles/ChooseLocation.module.css";
import Delete from "../../../Images/X.png";
import { useEffect, useState } from "react";

/**
 * Component for choosing a location to save a file.
 * Props:
 *  - location: string state of the parent element that holds the location of the file (e.g. "New:{}/" or "Org1/Folder1/Folder2/")
 *      - "New:{}" indicates that the file is being saved in a new folder
 *  - setLocation: setter for the location state
 *  - orgs: array of all organizations that the user is a part of
 *  - folders: array of all folders that the user has access to
 *  - user_id: the user's id (from OAuth, not the database id)
 */
const ChooseLocation = ({
  location,
  setLocation,
  orgs,
  folders,
  user_id,
}: {
  location: string;
  setLocation: React.Dispatch<React.SetStateAction<string>>;
  orgs: any[];
  folders: any[];
  user_id: string;
}) => {
  const [displayedOrgs, setDisplayedOrgs] = useState<any[]>([]);

  useEffect(() => {
    const getEditableOrgs = async () => {
      const permissions = await Promise.all(
        orgs.map(async (org: any) => {
          const permissions = await fetch(
            `/get_file_permissions/${encodeURIComponent(
              JSON.stringify({
                file_type: "org",
                file_id: org.id,
                user_id: user_id,
                user_type: "researcher",
              })
            )}`
          ).then((res) => res.json());
          return permissions[0]?.access_type;
        })
      );
      setDisplayedOrgs(
        orgs.filter((_: any, i: number) =>
          ["owner", "editor"].includes(permissions[i])
        )
      );
    };
    getEditableOrgs();
  }, [orgs, user_id]);

  return (
    <div className={styles.location}>
      <div style={{ margin: "1rem 0 0.5rem 0" }}>Save at:</div>
      {
        <>
          {location.split("/")[0].match(/New:{[^{}]*}/) ? (
            <div className={styles.newFolder}>
              <input
                type="text"
                placeholder="New group..."
                value={location.split("/")[0].slice(5, -1)}
                onChange={(e) => setLocation(`New:{${e.target.value}}/`)}
              />
              <img
                alt="cancel-new-folder"
                className={`${styles.deleteIcon} ${styles.cancelNewFolder}`}
                src={Delete}
                onClick={() => setLocation("")}
              />
            </div>
          ) : (
            <select onChange={(e) => setLocation(e.target.value)}>
              <option value="">Choose a group...</option>
              {displayedOrgs.map((org: any) => (
                <option key={org.id} value={org.file_path}>
                  {org.file_path.slice(0, -1)}
                </option>
              ))}
              <option value="New:{}/">New Group...</option>
            </select>
          )}
        </>
      }
      {location
        .split("/")
        .slice(0, -1)
        .map((_, i: number) => (
          <>
            <span key={`slash ${i}`} className={styles.slash}>
              /
            </span>
            {location.split("/")[i + 1].match(/New:{[^{}]*}/) ? (
              <div className={styles.newFolder}>
                <input
                  type="text"
                  placeholder="New folder..."
                  value={location.split("/")[i + 1].slice(5, -1)}
                  onChange={(e) =>
                    setLocation((location) => {
                      const newLocation = location.split("/");
                      newLocation[i + 1] = `New:{${e.target.value}}`;
                      return newLocation.join("/");
                    })
                  }
                />
                <img
                  alt="cancel-new-folder"
                  className={`${styles.deleteIcon} ${styles.cancelNewFolder}`}
                  src={Delete}
                  onClick={() =>
                    setLocation((location) => {
                      const newLocation = location.split("/").slice(0, -1);
                      newLocation[i + 1] = "";
                      return newLocation.join("/");
                    })
                  }
                />
              </div>
            ) : (
              <select
                key={i}
                onChange={(e) =>
                  setLocation(
                    (location: string) =>
                      `${location
                        .split("/")
                        .slice(0, i + 1)
                        .join("/")}/${e.target.value}`
                  )
                }
              >
                <option value="">Save at...</option>
                {folders
                  .filter(
                    (
                      folder: { file_path: string },
                      index: number,
                      self: { file_path: string }[]
                    ) =>
                      folder.file_path.startsWith(
                        location
                          .replace(/New:{|}/g, "")
                          .split("/")
                          .slice(0, i + 1)
                          .join("/") + "/"
                      ) &&
                      folder.file_path.split("/").length > i + 2 &&
                      index ===
                        self.findIndex(
                          (t) =>
                            t.file_path
                              .split("/")
                              .slice(0, i + 2)
                              .join() ===
                            folder.file_path
                              .split("/")
                              .slice(0, i + 2)
                              .join()
                        )
                  )
                  .map((folder: any) => (
                    <option
                      key={folder.id}
                      value={`${folder.file_path.split("/")[i + 1]}/`}
                    >
                      {folder.file_path.split("/")[i + 1]}
                    </option>
                  ))}
                <option value="New:{}/">New Folder...</option>
              </select>
            )}
          </>
        ))}
    </div>
  );
};

export default ChooseLocation;
