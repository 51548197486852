import React from "react";
import ClassNames from "classnames";
import { DragSource, DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import flow from "lodash/flow";

import BaseFile, { BaseFileConnectors } from "./../base-file.js";
import { LinkedinIcon, LinkedinShareButton } from "react-share";
import LinkShare from "../../Researcher/LinkShare";

class RawTableFile extends BaseFile {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: "",
    };
  }
  getShareURL() {
    this.props.shareFunc().then((res) => {
      this.setState({ shareUrl: res });
    });
  }
  render() {
    const {
      isDragging,
      isDeleting,
      isRenaming,
      isOver,
      isSelected,
      action,
      url,
      browserProps,
      connectDragPreview,
      depth,
      size,
      modified,
      batteryVolts,
      analysisFunc,
      isFavourite,
      toggleFavourite,
      viewFunc,
      shareFunc,

      // Sensor Settings
      customType,
      statusIcon,
      sensorSettings,
    } = this.props;

    const sampleTypes = ["vibration", "temperature", "audio", "sample"];

    const icon =
      browserProps.icons[
        customType === "sensor"
          ? "Sensor"
          : customType === "vibration" || customType === "unknown"
          ? "Vibration"
          : customType === "temperature"
          ? "Temperature"
          : customType === "audio"
          ? "Audio"
          : customType === "readme"
          ? "Readme"
          : this.getFileType()
      ] || browserProps.icons.File;
    const inAction = isDragging || action;

    const ConfirmDeletionRenderer = browserProps.confirmDeletionRenderer;

    let name;
    if (!inAction && isDeleting && browserProps.selection.length === 1) {
      name = (
        <ConfirmDeletionRenderer
          handleDeleteSubmit={this.handleDeleteSubmit}
          handleFileClick={this.handleFileClick}
          url={url}
        >
          <span style={{ color: statusIcon }}>⬤</span>
          {icon}
          {this.getName()}
        </ConfirmDeletionRenderer>
      );
    } else if (!inAction && isRenaming) {
      name = (
        <form className="renaming" onSubmit={this.handleRenameSubmit}>
          <span style={{ color: statusIcon }}>⬤</span>
          {icon}
          <input
            ref={this.selectFileNameFromRef}
            type="text"
            value={this.state.newName}
            onChange={this.handleNewNameChange}
            onBlur={this.handleCancelEdit}
            autoFocus
          />
        </form>
      );
    } else {
      name = (
        <a>
          <span style={{ color: statusIcon }}>⬤</span>
          {icon}
          {this.props.name}
        </a>
      );
    }

    let draggable = <div className="nameLbl">{name}</div>;
    if (typeof browserProps.moveFile === "function") {
      draggable = connectDragPreview(draggable);
    }

    const row = (
      <tr
        className={ClassNames("file", {
          pending: action,
          dragging: isDragging,
          dragover: isOver,
          selected: isSelected,
        })}
        onClick={this.handleItemClick}
        onDoubleClick={this.handleItemDoubleClick}
      >
        <td className="name">
          <div
            className="nameBox"
            style={{
              paddingLeft:
                Math.max(8, depth * 17 + (isSelected ? -4 : 0)) + "px",
            }}
          >
            {draggable}
            <div className="sensor-file-settings">
              {customType === "readme" && viewFunc && (
                <div
                  className="rowBtn"
                  onClick={() => {
                    viewFunc();
                  }}
                >
                  <i className="fa-regular fa-eye" aria-hidden="true" />
                  {window.innerWidth > 550 && <>View</>}
                </div>
              )}
              {shareFunc && (
                <>
                  <LinkedinShareButton
                    url={this.state.shareUrl}
                    beforeOnClick={() => this.getShareURL()}
                  >
                    <div className="linked-in-icon">
                      <LinkedinIcon size={"1.5rem"} round={true} />
                    </div>
                  </LinkedinShareButton>
                  <LinkShare
                    url={this.state.shareUrl}
                    beforeOnClick={() => this.getShareURL()}
                  />
                </>
              )}
              {(customType === "sensor" ||
                sampleTypes.includes(customType)) && (
                <>
                  {batteryVolts && (
                    <div className="batteryIndicator">
                      <div className="batteryVolts" aria-hidden="true" />
                      {Math.round(batteryVolts)} mV
                    </div>
                  )}
                  {analysisFunc && (
                    <div
                      className="rowBtn"
                      onClick={() => {
                        analysisFunc();
                      }}
                    >
                      <i className="fa fa-chart-simple" aria-hidden="true" />
                      {window.innerWidth > 550 && <>Analysis</>}
                    </div>
                  )}
                  {customType === "sensor" && (
                    <>
                      <div
                        onClick={() => {
                          toggleFavourite();
                        }}
                        className={ClassNames("starred", {
                          favourited: isFavourite,
                        })}
                      />
                      <div
                        className="device-settings"
                        onClick={() => {
                          sensorSettings(this.props.dbID);
                        }}
                      >
                        <i className="fa fa-gear" aria-hidden="true" />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </td>
      </tr>
    );

    return this.connectDND(row);
  }
}

const TableFile = flow(
  DragSource(
    "file",
    BaseFileConnectors.dragSource,
    BaseFileConnectors.dragCollect
  ),
  DropTarget(
    ["file", "folder", NativeTypes.FILE],
    BaseFileConnectors.targetSource,
    BaseFileConnectors.targetCollect
  )
)(RawTableFile);

export default TableFile;
export { RawTableFile };
