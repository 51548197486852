import HelpContent from "../../HelpContent";

const AboutOpenPHM = () => {
  return (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["About OpenPHM"].intro,
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["About OpenPHM"].industry,
        }}
      />
      <p>{HelpContent["About OpenPHM"].researcher}</p>

      <p>{HelpContent["About OpenPHM"].linksP}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["About OpenPHM"].links,
        }}
      />
    </>
  );
};

export default AboutOpenPHM;
