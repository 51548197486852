import styles from "../../Styles/Menu.module.css";

/*
 * This is the top (main) menu used on the home page before a user has logged in.
 * It contains the openPHM logo in the center with no nav links.
 */
const HomeMenu = () => {
  return (
    <div className={styles.container}>
      {/* Logo */}
      <div
        className={styles.logoSection}
        style={{
          borderRight: "none",
          paddingLeft: "0",
        }}
      >
        <div className={styles.logo} />
        <span
          ref={(node) =>
            node?.style.setProperty("display", "block", "important")
          }
          style={{
            paddingRight: "0.5em",
          }}
        >
          openPHM
        </span>
      </div>
    </div>
  );
};

export default HomeMenu;
