import { useEffect, useState } from "react";
import PopUp from "../PopUp";

import inviteStyles from "../../Styles/InvitePage.module.css";
import { User } from "@auth0/auth0-react";

/*
 * This popup notifies the user that they have been invited to an organization
 * They can choose to accept or decline the invitation
 */
const InvitePopUp = ({
  user,
  reloadFiles,
}: {
  user: User | undefined;
  reloadFiles: () => any;
}) => {
  const [invitations, setInvitations] = useState<
    { id: number; created_by: string; org_path: string; org_id: number }[]
  >([]);
  const [currentInvitation, setCurrentInvitation] = useState<{
    id: number;
    created_by: string;
    org_path: string;
    org_id: number;
  } | null>(null);

  useEffect(() => {
    if (!user) return;
    fetch(
      `/invitations?user_id=${user?.sub}&user_type=${localStorage.getItem(
        "user_type"
      )}`
    )
      .then((res) => res.json())
      .then((data) => {
        setCurrentInvitation(data.pop());
        setInvitations(data);
      });
  }, [user]);

  return (
    <PopUp
      isOpen={!!currentInvitation}
      setIsOpen={() => {}}
      title="Invitation"
      closeFunction={() => {
        fetch(`/delete_invite/${currentInvitation?.id}`, {
          method: "DELETE",
        });
        setCurrentInvitation(invitations.pop() || null);
      }}
    >
      <div className={inviteStyles.invite}>
        <h1>
          {currentInvitation?.created_by}
          <br />
          invited you to join
          <br />
          <span>
            {
              /* Get the name of the org (org path is org name + "/") */
              currentInvitation?.org_path.slice(0, -1)
            }
          </span>
        </h1>
        <div>
          <button
            onClick={() => {
              fetch(`/add_viewer/${currentInvitation?.org_id}/${user?.sub}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  user_type: localStorage.getItem("user_type"),
                }),
              }).then(() => {
                fetch(`/delete_invite/${currentInvitation?.id}`, {
                  method: "DELETE",
                });
                setCurrentInvitation(invitations.pop() || null);
                reloadFiles();
              });
            }}
          >
            Accept
          </button>
          <button
            className={inviteStyles.declineBtn}
            onClick={() => {
              fetch(`/delete_invite/${currentInvitation?.id}`, {
                method: "DELETE",
              });
              setCurrentInvitation(invitations.pop() || null);
            }}
          >
            Decline
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default InvitePopUp;
