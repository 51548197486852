import AutoFFTThresholdSettings from "../../../../Images/HelpArticles/AutoFFTThresholdSettings.png";
import ManualFFTThresholdSettings from "../../../../Images/HelpArticles/ManualFFTThresholdSettings.png";
import AutoFFTThreshold from "../../../../Images/HelpArticles/AutoFFTThreshold.png";
import FFTLayersView from "../../../../Images/HelpArticles/FFTLayersView.png";
import FaultAnalysisLines from "../../../../Images/HelpArticles/FaultAnalysisLines.png";
import FaultAnalysisMenu from "../../../../Images/HelpArticles/FaultAnalysisMenu.png";
import FFTAnalysisGraph from "../../../../Images/HelpArticles/FFTAnalysisGraph.png";
import CaptionedImage from "../../CaptionedImage";
import PageContents from "../../PageContents";
import Section from "../../Section";
import HelpContent from "../../HelpContent";

const SpectrumAnalysis = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].intro,
                }}
              />
              <PageContents
                sections={["FFT Layers", "FFT Thresholds", "Fault Analysis"]}
              />
            </div>
            <CaptionedImage
              img={FFTAnalysisGraph}
              title="FFT Analysis View"
              caption="The FFT analysis view with one FFT graph"
              width={100}
              position={"center"}
            />
          </>
        }
      />
      <Section
        title="FFT Layers"
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].layers,
                }}
              />
              <CaptionedImage
                img={FFTLayersView}
                title="FFT Layers"
                caption="The graph layer manager for FFT analysis"
                width={100}
                position={"center"}
              />
            </div>
          </>
        }
      />
      <Section
        title="FFT Thresholds"
        children={
          <>
            <div style={{ height: "400px" }}>
              <p
                style={{ float: "left", width: "60%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].thresh1,
                }}
              />
              <CaptionedImage
                img={AutoFFTThresholdSettings}
                title="Auto Mask Thresholds Settings"
                caption="The automatic mask settings for the FFT thresholds"
                width={38}
                position={"right"}
              />
              <p
                style={{ float: "left", width: "60%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].thresh2,
                }}
              />
            </div>

            <div>
              <CaptionedImage
                img={AutoFFTThreshold}
                title="Auto FFT Thresholds on FFT Graph"
                caption="The FFT graph with warning and danger automatic mask thresholds visible"
                width={50}
                position={"left"}
              />
              <p
                style={{ float: "right", width: "45%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].threshAuto,
                }}
              />
            </div>

            <div>
              <p
                style={{ float: "left", width: "60%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].threshManual,
                }}
              />
              <CaptionedImage
                img={ManualFFTThresholdSettings}
                title="Manual Mask Thresholds Settings"
                caption="The manual FFT threshold settings"
                width={35}
                position={"right"}
              />
            </div>
          </>
        }
      />
      <Section
        title="Fault Analysis"
        children={
          <>
            <div>
              <CaptionedImage
                img={FaultAnalysisMenu}
                title="Fault Analysis Menu"
                caption="Activating the Gear Mesh Frequency fault analysis from the Fault Analysis menu"
                width={100}
                position={"center"}
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["FFT Analysis"].faultAnalysis,
                }}
              />
              <CaptionedImage
                img={FaultAnalysisLines}
                title="FFT Graph with Fault Analysis Lines"
                caption="FFT Graph with Gear Mesh Frequency fault analysis lines overlayed"
                width={100}
                position={"center"}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default SpectrumAnalysis;
