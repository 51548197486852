import {
  useEffect,
  useState,
} from "react";
import styles from "../../Styles/Dashboard.module.css";

// Sections / Views
import TemperatureGraph from "./TemperaturePage/TemperatureGraph";
import TemperatureSettingsView from "./TemperaturePage/TemperatureSettingsView";
import { TemperatureSettings } from "./HTTPRequests/Types";
import { getTemperatureSettings } from "./HTTPRequests/Settings";

interface Props {
  sensorId?: number; // for industry users
  folderPath?: string; // for research users
}

/*
 * This is the temperature dashboard for an industry user
 */
const DashboardTemperature = ({ sensorId, folderPath }: Props) => {
  const [view, setView] = useState<"temperature" | "settings">("temperature");

  const [temperatureSettings, setTemperatureSettings] = useState<TemperatureSettings>({
    units: "celsius",
    relative_temperature: false,
    baseline_temperature: 0,
  });

  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 550) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!sensorId) return;
    getTemperatureSettings(sensorId).then((settings) => {
      if (!settings.units || !settings.relative_temperature || !settings.baseline_temperature) {
        return;
      }
      setTemperatureSettings(settings);
    }).catch(e => console.log(e));
  }, [sensorId]);

  return (
    <div className={`page ${styles.graphPage}`}>
      {view === "temperature" && (
        <TemperatureGraph
          mobile={mobile}
          sensorId={sensorId}
          folderPath={folderPath}
          openTemperatureSettings={() => setView("settings")}
          units={temperatureSettings?.units}
          offset={temperatureSettings?.relative_temperature ? temperatureSettings?.baseline_temperature : 0}
        />
      )}

      {view === "settings" && (
        <TemperatureSettingsView
          mobile={mobile}
          sensorId={sensorId}
          onClose={() => setView("temperature")}
          temperatureSettings={temperatureSettings}
          setTemperatureSettings={setTemperatureSettings}
        />
      )}
    </div>
  );
};

export default DashboardTemperature;
