import styles from "../../../Styles/TrendsGraph.module.css";

// Recharts (import directly to reduce size)
import { LineChart } from "recharts/es6/chart/LineChart";
import { Line } from "recharts/es6/cartesian/Line";
import { XAxis } from "recharts/es6/cartesian/XAxis";
import { YAxis } from "recharts/es6/cartesian/YAxis";
import { Tooltip } from "recharts/es6/component/Tooltip";
import { ResponsiveContainer } from "recharts/es6/component/ResponsiveContainer";
import { ReferenceArea } from "recharts/es6/cartesian/ReferenceArea";
import { ReferenceLine } from "recharts/es6/cartesian/ReferenceLine";
import { Label } from "recharts/es6/component/Label";
import { CartesianGrid } from "recharts/es6/cartesian/CartesianGrid";

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  LineProps,
  getDynamicDate,
  LineColors,
} from "../../../Utils/GraphUtils";

import { zoom, zoomOut } from "../../../Utils/GraphZoomUtils";
import GraphWrapper from "../GraphWrapper";
import CustomTooltip from "../CustomToolTip";
import { LegendDropdown, DropdownOption } from "../LegendDropdown";
import { TemperatureData, TrendThreshold } from "../HTTPRequests/Types";
import { getSensorTemperatureData } from "../HTTPRequests/IndustryData";
import { getFolderTemperatureData } from "../HTTPRequests/ResearchData";
import Spinner from "../../Spinner";
import ThresholdPopup from "../ThresholdPopup";
import { getTrendsThresholds } from "../HTTPRequests/Thresholds";
import { getThresholdName } from "../../../Utils/Utils";

const conversion: {
  [key: string]: {
    [key: string]: (t: number) => number
  };
} = {
  celsius: {
    celsius: (t: number) => t,
    fahrenheit: (t: number) => t * 9 / 5 + 32,
    kelvin: (t: number) => t + 273.15,
  },
  fahrenheit: {
    celsius: (t: number) => (t - 32) * 5 / 9,
    fahrenheit: (t: number) => t,
    kelvin: (t: number) => (t - 32) * 5 / 9 + 273.15,
  },
  kelvin: {
    celsius: (t: number) => t - 273.15,
    fahrenheit: (t: number) => (t - 273.15) * 9 / 5 + 32,
    kelvin: (t: number) => t,
  },
}

function resetMinMax(
  data: TemperatureData[],
  setTop: Dispatch<SetStateAction<string | number>>, 
  setBottom: Dispatch<SetStateAction<string | number>>, 
) {
  let max = -Infinity;
  let min = Infinity;
  for (const t of data) {
    if (t.temperature > max) {
      max = t.temperature;
    }
    if (t.temperature < min) {
      min = t.temperature;
    }
  }
  const span = max - min;

  setTop(max + span * 0.1);
  setBottom(min - span * 0.1);
}


interface Props {
  mobile: boolean;
  sensorId?: number; // for industry users
  folderPath?: string; // for research users
  openTemperatureSettings: () => void;
  units: string;
  offset: number;
}

/*
 * This is the trends graph section
 * It is where stat trends (P2P, Kurtosis, RMS) from a sensor are displayed
 * And where users can navigate to view specific samples
 */
const TemperatureGraph = ({
  mobile,
  sensorId,
  folderPath,
  openTemperatureSettings,
  units,
  offset,
}: Props) => {
  
  const [loading, setLoading] = useState(true);

  // Zoom
  const [zoomSelected, setZoomSelected] = useState(false);
  const [left, setLeft] = useState<number | string>("dataMin");
  const [right, setRight] = useState<number | string>("dataMax");
  const [bottom, setBottom] = useState<number | string>("dataMin - 0.5");
  const [top, setTop] = useState<number | string>("dataMax + 0.5");
  const [refAreaLeft, setRefAreaLeft] = useState<number | string>("");
  const [refAreaRight, setRefAreaRight] = useState<number | string>("");

  // Threshold Settings
  const [thresholdSettingsOpen, setThresholdSettingsOpen] = useState(false);

  // History
  const [historyFrom, setHistoryFrom] = useState<number>(Date.now() - 1000 * 60 * 60 * 24 * 7);
  const [historyTo, setHistoryTo] = useState<number>(Date.now());

  // Legend
  const [thresholds, setThresholds] = useState<TrendThreshold[]>([]);
  const [thresholdsToShow, setThresholdsToShow] = useState<boolean[]>([]);
  
  const [data, setData] = useState<TemperatureData[]>();

  const [ajustedData, setAjustedData] = useState<TemperatureData[]>();

  const loadThreshold = () => {
    if (sensorId) {
      return getTrendsThresholds(sensorId).then((thresholds) => {
        setThresholds(thresholds.sort((a, b) => (a.compared_to + a.type) > (b.compared_to + b.type) ? 1 : -1));
        if (thresholds.length !== thresholdsToShow.length) {
          setThresholdsToShow(thresholds.map(() => false));
        }
      }).catch(e => console.log(e));
    }
  }

  useEffect(() => {
    if (!data) return;
    if (!["celsius", "fahrenheit", "kelvin"].includes(units)) return;

    const ajusted = data.map(d => ({
      temperature: conversion["celsius"][units](d.temperature) + offset,
      date: d.date,
    }));

    setAjustedData(ajusted);
    resetMinMax(ajusted, setTop, setBottom);
  }, [data, offset, units]);

  useEffect(() => {
    setLoading(true);
    if (sensorId) {
      getSensorTemperatureData(sensorId, historyFrom, historyTo).then((temperatureData) => {
        setData(temperatureData.map(t => ({
          temperature: t.temperature,
          date: new Date(t.date).getTime(),
        })));

        setLoading(false);
      }).catch(e => console.log(e));
  
      getTrendsThresholds(sensorId).then((thresholds) => {
        setThresholds(thresholds.sort((a, b) => (a.compared_to + a.type) > (b.compared_to + b.type) ? 1 : -1));
        setThresholdsToShow(thresholds.map(() => false));
      }).catch(e => console.log(e));
    } else if (folderPath) {
      getFolderTemperatureData(folderPath).then((temperatureData) => {
        setData(temperatureData.map(t => ({
          temperature: t.temperature,
          date: new Date(t.date).getTime(),
        })));
        
        setLoading(false);
      }).catch(e => console.log(e));
    }    
  }, [sensorId, historyFrom, historyTo, folderPath]);
  
  const Graph = () => {
    // Sort by Time
    const sortedData = [...((ajustedData ?? data) ?? [])!].sort((a: TemperatureData, b: TemperatureData) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    });

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={sortedData}
          onMouseDown={(e: any) => {
            if (e === null || !zoomSelected) return;
            setRefAreaLeft(e.activeLabel);
          }}
          onMouseMove={(e: any) => {
            if (zoomSelected)
              refAreaLeft && setRefAreaRight(e.activeLabel);
          }}
          onMouseUp={() => {
            if (zoomSelected)
              zoom(
                refAreaLeft,
                refAreaRight,
                setRefAreaLeft,
                setRefAreaRight,
                setLeft,
                setRight,
                setTop,
                setBottom,
                0.1,
                sortedData.map((p: TemperatureData) => {
                    return {
                      x: p.date,
                      y: p.temperature,
                    };
                  }),
              );
          }}
          margin={{
            bottom: mobile ? 20 : 28,
            right: 20,
            left: mobile ? -10 : 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            allowDataOverflow
            dataKey="date"
            type="number"
            tickFormatter={(date: any) =>
              getDynamicDate(date, left, right, data)
            }
            domain={[left, right]}
          >
            <Label
              fontSize={mobile ? 13 : 16}
              value={`Temperature (${
                    units === "celsius"
                      ? "°C"
                      : units === "fahrenheit"
                      ? "°F"
                      : units === "kelvin"
                      ? "K"
                      : ""
                  }) over Time`}
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            type="number"
            domain={[bottom, top]}
            allowDataOverflow={true}
            allowDecimals={true}
            tickFormatter={(value: any) => value.toFixed(2)}
          />
          <Tooltip content={<CustomTooltip />} position={{ x: 60, y: 0 }} />

          {/* The rectangle shown on zoom */}
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}

          {/*---------------------- LINES -----------------------*/}
          <Line
            {...LineProps(sortedData)}
            stroke={LineColors["Temperature"]}
            dataKey={"temperature"}
          />

          {/* Thresholds */}
          {sensorId && thresholds.map((threshold, i) => {
            return thresholdsToShow[i] ? (
              <ReferenceLine
                key={i}
                stroke={threshold.color}
                y={threshold.value}
                ifOverflow="extendDomain"
                strokeWidth={7}
                opacity={0.75}
                strokeDasharray="10 5"
              />
            ) : null;
          })}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      {sensorId && <ThresholdPopup 
        isOpen={thresholdSettingsOpen} 
        setIsOpen={setThresholdSettingsOpen} 
        sensorId={sensorId}
        thresholds={thresholds} 
        setThresholds={setThresholds}
        loadThreshold={loadThreshold} 
        compareOptions={[
          { name: "Temperature", id: "temperature" },
        ]} 
      />}
      <GraphWrapper 
        title={"Temperature"}
        mobile={mobile}
        data={(ajustedData ?? data) ?? []}
        helpPage="trends_view"
        legendItems={[
          ...thresholds.map((threshold, i) => {
            return thresholdsToShow[i] ? {
              name: `${getThresholdName(threshold)}`, 
              color: threshold.color
            } : "";
          }),
        ]}
        titleChildren={
          <div
            className={`${styles.headerButton} ${styles.sensorSettings}`}
            onClick={() => openTemperatureSettings()}
          />
        }
        mobileTitleChildren={
          <div
            className={styles.mobileTitleButton}
            onClick={() => openTemperatureSettings()}
          >
            <div className={styles.sensorSettings} />
            Temperature Settings
          </div>
        }
        options={sensorId && 
          <>
            <LegendDropdown name="Thresholds">
              <>
                <div
                  onClick={() => setThresholdSettingsOpen(!thresholdSettingsOpen)}
                  className={styles.thresholdSettingsBtn}
                >
                  <div />
                  Settings
                </div>
                
                {thresholds.filter(t => "temperature" === t.compared_to.split("_")[0]).length ? thresholds.map((threshold, i) => {
                  if ("temperature" !== threshold.compared_to.split("_")[0]) {
                    return <></>;
                  }
                  return (
                    <DropdownOption 
                      title={getThresholdName(threshold)} 
                      color={threshold.color}
                      state={thresholdsToShow[i]} 
                      setState={() => setThresholdsToShow(thresholdsToShow.map((s, j) => i === j ? !s : s))} 
                      key={i} 
                    />
                  );
                }) : <DropdownOption title="No Thresholds" />}
              </>
            </LegendDropdown>

            <LegendDropdown name="History">
              <>
                <div className={styles.historyDropdownOption}>
                  From: 
                  <input 
                    type="datetime-local" 
                    value={new Date(historyFrom - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0, 16)}
                    onChange={e => setHistoryFrom(new Date(e.target.value).getTime())}
                  />
                </div>
                <div className={styles.historyDropdownOption}>
                  To: 
                  <input 
                    type="datetime-local"
                    value={new Date(historyTo - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0, 16)}
                    onChange={e => setHistoryTo(new Date(e.target.value).getTime())}
                  />
                </div>
              </>
            </LegendDropdown>
          </>
        }
        graph={
          loading ? <div style={{ marginBlock: "auto" }}><Spinner /></div> :
            data && data?.length > 0 ? Graph() : 
            <div style={{ marginBlock: "auto", textAlign: "center" }}>
              No Temperature Data to Show
              <br /><br />
              Try changing the trends history range
            </div>}
        zoomSelected={zoomSelected}
        setZoomSelected={setZoomSelected}
        zoomOut={() => zoomOut(
          setRefAreaLeft,
          setRefAreaRight,
          setLeft,
          setRight,
          () => resetMinMax(ajustedData ?? [], setTop, setBottom),
        )}
      />
    </>
  );
};

export default TemperatureGraph;