import { useCallback, useEffect, useState } from "react";
import PopUp from "../PopUp";
import { useNavigate } from "react-router-dom";
import styles from "../../Styles/Profile.module.css";
import "react-dyn-tabs/style/react-dyn-tabs.css";
import "react-dyn-tabs/themes/react-dyn-tabs-bootstrap.css";
import TransferOwnership from "../FileManager/SettingsModals/TransferOwnership/TransferOwnership";
import InstitutionSelector from "./InstitutionSelector";
import { User } from "@auth0/auth0-react";

interface Props {
  user: User | undefined;
  logout: () => void;
  lastLogin: string | null;
  userType: "industry" | "researcher";
}

/*
 * This is the profile page accessible via the user dropdown.
 * It contains basic user info including: name, last login, profile pic, login type and email
 * Researchers can also see additional info such as: institution, description, and social media links
 */
const Profile = ({ user, logout, lastLogin, userType }: Props) => {
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const navigate = useNavigate();

  const [deletionConfirmOpen, setDeletionConfirmOpenConfirmOpen] = useState<boolean>(false);
  const [transferOwnerOpen, setTransferOwnerOpen] = useState<boolean>(false);

  // for researchers only
  const [researcherDetails, setResearcherDetails] = useState<any>(null);

  const [institutionInput, setInstitutionInput] = useState<string>("");
  const [descriptionInput, setDescriptionInput] = useState<string>("");
  const [socialMediaNameSelect, setSocialMediaNameSelect] =
    useState<string>("");
  const [socialMediaLinkInput, setSocialMediaLinkInput] = useState<string>("");
  const [institutionEdit, setInstitutionEdit] = useState<boolean>(false);
  const [descriptionEdit, setDescriptionEdit] = useState<boolean>(false);
  const [socialMediaEdit, setSocialMediaEdit] = useState<number>(-1);

  const loadResearcherDetails = useCallback(async () => {
    const res = await fetch(`/researcher_details/${user?.sub}`);
    const data = await res.json();
    setResearcherDetails({
      institution: data.institution,
      description: data.description,
      socialMedias: data.social_media ? Object.entries(data.social_media) : [],
    });
    setInstitutionInput(data.institution);
    setDescriptionInput(data.description);
    setInstitutionEdit(false);
    setDescriptionEdit(false);
    setSocialMediaEdit(-1);
  }, [
    user?.sub,
    setResearcherDetails,
    setInstitutionInput,
    setDescriptionInput,
  ]);

  const updateResearcherDetails = async () => {
    await fetch(`/update_researcher_details`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        institution: institutionEdit
          ? institutionInput
          : researcherDetails.institution,
        description: descriptionEdit
          ? descriptionInput
          : researcherDetails.description,
        social_media: researcherDetails.socialMedias
          .map((social_media: string[], i: number) =>
            i === socialMediaEdit
              ? [socialMediaNameSelect, socialMediaLinkInput]
              : social_media
          )
          .filter((social_media: string[]) => social_media[0] !== ""),
        user_id: user?.sub,
      }),
    });
    return await loadResearcherDetails();
  };

  useEffect(() => {
    if (userType !== "industry") {
      loadResearcherDetails();
    }
  }, [loadResearcherDetails, userType]);

  return (
    <div className={`page`} style={{ zIndex: 1 }}>
      <PopUp
        isOpen={deletionConfirmOpen}
        title="Confirm Account Deletion"
        setIsOpen={() => setDeletionConfirmOpenConfirmOpen(false)}
        confirmBtnTxt={"Confirm Delete"}
        confirmBtnFunc={() => {
          fetch(`/delete_user`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: user?.sub,
              user_type: userType,
            }),
          }).then((res) => {
            if (res.status === 200) {
              // Logout and go to home
              logout();
              navigate("/");
            } else {
              console.error(res);
            }
          });
        }}
      >
        <div className={styles.confirmModal}>
          <p style={{ marginTop: "0.5em" }}>
            This will permanently delete all{" "}
            {userType === "industry" ? "organizations" : "groups"} you are the
            owner of and remove your account from our system.
          </p>
          <p>
            To transfer ownership of your{" "}
            {userType === "industry" ? "organizations" : "groups"} before
            deleting your account,
            <button
              onClick={() => {
                setTransferOwnerOpen(true);
              }}
              className={"buttonLink"}
              style={{ marginLeft: "0.25em" }}
            >
              click here
            </button>
            .
          </p>
          <p>
            After deletion, the next time you log in your account will reset as
            a new account.
          </p>
        </div>
      </PopUp>

      <TransferOwnership
        open={transferOwnerOpen}
        setOpen={setTransferOwnerOpen}
        file={null}
        user={user}
        userType={userType}
      />

      <div className={`content ${styles.container}`}>
        <img className={styles.photo} src={user?.picture} alt={"Profile"} />
        <div className={styles.content}>
          <div className={styles.main}>{user?.name}</div>
          <div className={styles.main}>{user?.email}</div>
          <div className={styles.details}>
            Logged in with{" "}
            {user?.sub?.includes("google") ? "Google" : "Microsoft"} account.
          </div>
          <div className={styles.details}>
            {lastLogin
              ? "Last login was " +
                new Date(lastLogin).toLocaleString("en-US", options)
              : "This is your first login."}
          </div>
          {userType === "researcher" && (
            <>
              <div className={styles.institution}>
                {institutionEdit ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateResearcherDetails().then(() => {
                        setInstitutionInput("");
                        setInstitutionEdit(false);
                      });
                    }}
                  >
                    <InstitutionSelector setInstitution={setInstitutionInput} />
                    <button type="submit">Save</button>
                  </form>
                ) : (
                  <>
                    {researcherDetails?.institution
                      ? researcherDetails.institution
                      : ""}
                    {researcherDetails?.institution ? (
                      <div
                        className={styles.edit}
                        onClick={() => setInstitutionEdit(true)}
                      />
                    ) : (
                      <div
                        className={styles.add}
                        onClick={() => setInstitutionEdit(true)}
                      >
                        Add Institution
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className={styles.description}>
                {descriptionEdit ? (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      updateResearcherDetails().then(() => {
                        setDescriptionInput("");
                        setDescriptionEdit(false);
                      });
                    }}
                  >
                    <textarea
                      autoFocus
                      placeholder="Description"
                      value={descriptionInput}
                      onChange={(e) => setDescriptionInput(e.target.value)}
                      cols={50}
                      rows={5}
                    />
                    <button type="submit">Save</button>
                  </form>
                ) : (
                  <>
                    {researcherDetails?.description
                      ? researcherDetails?.description
                      : ""}
                    {researcherDetails?.description ? (
                      <div
                        className={styles.edit}
                        onClick={() => setDescriptionEdit(true)}
                      />
                    ) : (
                      <div
                        className={styles.add}
                        onClick={() => setDescriptionEdit(true)}
                      >
                        Add Description
                      </div>
                    )}
                  </>
                )}
              </div>

              <div className={styles.socialMedias}>
                {researcherDetails?.socialMedias.map(
                  (socialMedia: any, i: number) =>
                    socialMediaEdit === i ? (
                      <form
                        className={styles.socialMedia}
                        onSubmit={(e) => {
                          e.preventDefault();
                          updateResearcherDetails().then(() => {
                            setSocialMediaLinkInput("");
                            setSocialMediaNameSelect("");
                            setSocialMediaEdit(-1);
                          });
                        }}
                        key={i}
                      >
                        <select
                          value={socialMediaNameSelect}
                          onChange={(e) =>
                            setSocialMediaNameSelect(e.target.value)
                          }
                        >
                          <option value="">Select Social Media</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Research-Gate">Research Gate</option>
                          <option value="Facebook">Facebook</option>
                          <option value="Twitter">Twitter</option>
                        </select>
                        <input
                          type="text"
                          autoFocus
                          placeholder="Link"
                          value={socialMediaLinkInput}
                          onChange={(e) =>
                            setSocialMediaLinkInput(e.target.value)
                          }
                        />
                        <button type="submit">Save</button>
                      </form>
                    ) : (
                      <div className={styles.socialMedia} key={i}>
                        <div
                          className={`${styles.socialMediaIcon} ${socialMedia[0]}`}
                        />
                        <div className={styles.socialMediaLink}>
                          <a
                            href={socialMedia[1]}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {socialMedia[0].split("-").join(" ")}
                          </a>
                        </div>
                        <div
                          className={styles.edit}
                          onClick={() => {
                            setSocialMediaEdit(i);
                            setSocialMediaNameSelect(socialMedia[0]);
                          }}
                        />
                        <div
                          className={styles.delete}
                          onClick={() => {
                            setResearcherDetails({
                              ...researcherDetails,
                              socialMedias:
                                researcherDetails?.socialMedias.filter(
                                  (_: any, j: number) => j !== i
                                ),
                            });
                            fetch(`/update_researcher_details`, {
                              method: "POST",
                              headers: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                institution: researcherDetails.institution,
                                description: researcherDetails.description,
                                social_media:
                                  researcherDetails.socialMedias.filter(
                                    (_: any, j: number) => j !== i
                                  ),
                                user_id: user?.sub,
                              }),
                            }).then(() => loadResearcherDetails());
                          }}
                        />
                      </div>
                    )
                )}
                {socialMediaEdit === -1 && (
                  <div
                    className={styles.add}
                    onClick={() => {
                      if (!researcherDetails?.socialMedias) return;
                      setSocialMediaEdit(
                        researcherDetails?.socialMedias.length
                      );
                      setResearcherDetails({
                        ...researcherDetails,
                        socialMedias: [
                          ...researcherDetails?.socialMedias,
                          ["", ""],
                        ],
                      });
                    }}
                  >
                    Add Social Media
                  </div>
                )}
              </div>
            </>
          )}
          <div
            className={styles.deleteBtn}
            onClick={() => {
              setDeletionConfirmOpenConfirmOpen(true);
            }}
          >
            Delete / Reset Account
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
