interface Props {
  text: string;
}

/*
 * This is a tooltip component that displays a tooltip when the user hovers
 * over an element (such as an info icon). It is used in various locations in the app
 */
const InfoTooltip = ({ text }: Props) => {
  return (
    <i
      data-tooltip-id="my-tooltip"
      data-tooltip-html={text}
      data-tooltip-place="top"
      data-tooltip-variant="info"
      className="fa-solid fa-circle-info"
    />
  );
};

export default InfoTooltip;
