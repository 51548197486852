import { useContext, useEffect, useState } from "react";
import styles from "../../../../Styles/ResetGatewayPassword.module.css";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../App";

interface Props {
  isAuthenticated: boolean;
}

/**
 * Password reset page
 * NOT ACCOUNT PASSWORD
 * Password of a Gateway
 */
const ResetGatewayPassword = ({ isAuthenticated }: Props) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [gateway, setGateway] = useState<any>();
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");

  const submitPassword = () => {
    if (password !== repeatPassword) {
      toast.error("Passwords do not match.");
      return;
    } else if (password.length < 3) {
      toast.error("Password must be at least 3 characters long.");
      return;
    } else if (password.includes(" ")) {
      toast.error("Password cannot contain spaces.");
      return;
    }

    // Reset the password and redirect to home page
    fetch(`/reset_gateway_password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
        gwId: gateway.auth_id,
      }),
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Password reset successfully. Redirecting...", {
          autoClose: 2000,
        });
        setTimeout(() => {
          if (isAuthenticated) tabInstance.select("devicesTab");
          navigate("/");
        }, 2000);
      }
    });
  };

  useEffect(() => {
    fetch(`/get_gateway_for_password_reset/${uuid}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.length) {
          setGateway(data[0]);
        } else {
          setGateway("ERROR");
        }
      });
  }, [uuid]);

  if (gateway === undefined) return <></>;
  if (gateway === "ERROR")
    return (
      <div className={styles.container}>
        <div style={{ fontWeight: 500 }}>Invalid password reset URL.</div>
      </div>
    );

  return (
    <div className={styles.container}>
      <div>
        <h1>Reset Password for Gateway "uOttawa"</h1>
        <div className={styles.inputBox}>
          <div>New Password:</div>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
          />
        </div>
        <div className={styles.inputBox}>
          <div>Repeat New Password:</div>
          <input
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            type="password"
          />
        </div>
        <button
          onClick={() => {
            submitPassword();
          }}
        >
          Reset Password
        </button>
      </div>
    </div>
  );
};

export default ResetGatewayPassword;
