import PropTypes from "prop-types";
import React from "react";
import ClassNames from "classnames";

import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";

import { BaseFileConnectors } from "./../base-file.js";

class RawTableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "all",
    };
  }

  static propTypes = {
    connectDropTarget: PropTypes.func,
    isOver: PropTypes.bool,
    isSelected: PropTypes.func,

    browserProps: PropTypes.shape({
      createFiles: PropTypes.func,
      moveFolder: PropTypes.func,
      moveFile: PropTypes.func,
      changeTab: PropTypes.func,
      currentTab: PropTypes.string,
      browserType: PropTypes.string,
    }),
  };

  handleTabClick(tab) {
    this.props.browserProps.changeTab(tab);
  }

  render() {
    const header = (
      <div
        className={ClassNames("file-browser-header", {
          dragover: this.props.isOver,
          selected: this.props.isSelected,
        })}
      >
        {this.props.browserProps.browserType === "sensor" ? (
          <div className={"file-browser-tabs"}>
            <div
              className={ClassNames("browserTab", {
                selected: this.props.browserProps.currentTab === "all",
              })}
              onClick={this.handleTabClick.bind(this, "all")}
            >
              All Devices
            </div>
            <div
              className={ClassNames("browserTab", {
                selected: this.props.browserProps.currentTab === "favourites",
              })}
              onClick={this.handleTabClick.bind(this, "favourites")}
            >
              Favourites
            </div>
            <div
              className={ClassNames("browserTab", {
                selected: this.props.browserProps.currentTab === "map",
              })}
              onClick={this.handleTabClick.bind(this, "map")}
            >
              Map
            </div>
            <div
              className={"tutorialBtn"}
              style={{ cursor: "pointer", padding: "0" }}
            >
              <a
                onClick={() => {
                  this.props.goToTutorial();
                }}
                role="button"
              >
                {this.props.icons.Tutorial}
                Tutorial
              </a>
            </div>
          </div>
        ) : (
          "Samples"
        )}
      </div>
    );

    if (
      typeof this.props.browserProps.createFiles === "function" ||
      typeof this.props.browserProps.moveFile === "function" ||
      typeof this.props.browserProps.moveFolder === "function"
    ) {
      return this.props.connectDropTarget(header);
    } else {
      return header;
    }
  }
}

const TableHeader = DropTarget(
  ["file", "folder", NativeTypes.FILE],
  BaseFileConnectors.targetSource,
  BaseFileConnectors.targetCollect
)(RawTableHeader);

export default TableHeader;
export { RawTableHeader };
