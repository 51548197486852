import HelpContent from "./HelpContent";

// Recursively search the HelpContent object for a string that matches the keyword
// then return the object path to that string.
// Only returns the first matching section per page (if there is any)
export const findPathByKeyword = (
  keyword: string,
  obj: any = HelpContent
): string[] => {
  const recursiveSearch = (
    keyword: string,
    obj: any = HelpContent,
    path: string[] = [],
    paths: string[] = []
  ) => {
    keyword = keyword.toLowerCase();

    for (let key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        let found = false;
        for (let subKey in obj[key]) {
          if (
            typeof obj[key][subKey] === "string" &&
            obj[key][subKey].toLowerCase().includes(keyword) &&
            !found
          ) {
            paths.push(path.concat(key).concat(subKey).join("."));
            found = true;
          }
        }

        if (!found) {
          recursiveSearch(keyword, obj[key], path.concat(key), paths);
        }
      }
    }
    return paths;
  };

  // Results based on article contents
  const results = recursiveSearch(keyword, obj);

  // Add any titles that match the keyword but don't have any content that matches
  const matchedContentTitles = results.map((r: string) => r.split(".")[0]);
  const matchedTitles = Object.keys(HelpContent).filter((f: string) => {
    return f.toLowerCase().includes(keyword.toLowerCase());
  });
  for (let i = 0; i < matchedTitles.length; i++) {
    const title = matchedTitles[i];
    if (!matchedContentTitles.includes(title)) {
      results.push(`${title}.${Object.keys(HelpContent[title])[0]}`);
    }
  }

  // Return the combined content and title search results
  return results;
};

// Get the value of a specific path in the HelpContent object
// This is needed to deal with spaces in the key names
export const getValueByPath = (path: string, obj: any) => {
  if (!obj) return undefined;

  const pathArray = path.split(".");
  let currentObj = obj;

  for (let i = 0; i < pathArray.length; i++) {
    currentObj = currentObj[pathArray[i]];
    if (currentObj === undefined) {
      return undefined;
    }
  }

  return currentObj;
};
