import styles from "../../Styles/PageContents.module.css";

/*
 * This the contents of a page linking to different sections
 * It is used in the help pages
 */
const PageContents = ({ sections }: { sections: string[] }) => {
  return (
    <div className={styles.container}>
      <div>
        <h1>Page Contents</h1>
        {sections.map((section: string, i: number) => {
          return (
            <div key={i}>
              <a href={`#${section}`}>• {section}</a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PageContents;
