import styles from "../../Styles/ThresholdPopup.module.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import PopUp from "../PopUp";
import { TrendThreshold } from "./HTTPRequests/Types";
import ISOStandard from "../../Images/VelocityISOStandard.png";
import AddIcon from "../../Images/AddIcon.png";
import { addTrendsThreshold, deleteTrendsThreshold, updateTrendsThreshold } from "./HTTPRequests/Thresholds";
import { getThresholdName } from "../../Utils/Utils";

const ThresholdPopup = ({ 
  isOpen, 
  setIsOpen,
  sensorId,
  thresholds,
  setThresholds,
  loadThreshold,
  compareOptions,
 }: {
  isOpen: boolean;
  setIsOpen: (value: SetStateAction<boolean>) => void;
  sensorId: number;
  thresholds: TrendThreshold[];
  setThresholds: Dispatch<SetStateAction<TrendThreshold[]>>;
  loadThreshold: () => any;
  compareOptions: { name: string, id: string }[];
}) => {
  const [thresholdISOOpen, setThresholdISOOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage("");
  }, [thresholds, isOpen]);

  const shownThresholds = thresholds.filter(t => compareOptions.find(o => o.id === t.compared_to));
  return (
    <>
      <PopUp isOpen={isOpen} setIsOpen={setIsOpen} title="Thresholds">
        <>
          {compareOptions.find(c => c.id.startsWith("velocity")) && <div>
            <div className={styles.isoTitle}>
              <h3 className={styles.subtitle}>
                <a href="https://www.iso.org/standard/78311.html" target="blank_">ISO 20816-3</a>
              </h3>
              <div
                onClick={() => setThresholdISOOpen(!thresholdISOOpen)}
                className={styles.aboutThresholdsISO}
              />
            </div>
            {thresholds.find(t => t.is_iso) ? <div className={styles.table} style={{ gridTemplateColumns: "0.25fr 1fr 0.5fr" }}>
              <div className={styles.tableTitle}>Color</div>
              <div className={styles.tableTitle}>Name</div>
              <div className={styles.tableTitle}>Notifications</div>
              {shownThresholds.map((threshold, i) => {
                if (threshold.is_iso) {
                  return (
                    <>
                      <div
                        style={{ backgroundColor: threshold.color }}
                        key={`${i}.1`} 
                      >
                        <input 
                          type="color" 
                          defaultValue={threshold.color} 
                          onChange={(e) => {
                            setThresholds(thresholds.map((t) => {
                              if (t.id === threshold.id) {
                                return { ...t, color: e.target.value };
                              }
                              return t;
                            }));
                          }}
                          onBlur={() => {
                            updateTrendsThreshold(threshold)
                              .then(loadThreshold)
                              .catch((e) => console.error(e));
                          }}
                        />
                      </div>
                      <span key={`${i}.2`}>
                        Velocity RMS {threshold.type.charAt(0).toLocaleUpperCase() + threshold.type.substring(1)} (ISO 20816-3)
                      </span>
                      <select 
                        value={threshold.notification_level} 
                        key={`${i}.3`}
                        onChange={(e) => {
                          updateTrendsThreshold({ ...threshold, notification_level: parseInt(e.target.value) })
                            .then(loadThreshold)
                            .catch((e) => console.error(e));
                        }}
                      >
                        <option value="0">None</option>
                        <option value="1">Website</option>
                        <option value="2">Email</option>
                      </select>
                    </>
                  );
                }
                return <></>;
              })}
            </div> : <div style={{ textAlign: "center" }}>Turn on ISO Category Machine Settings to use ISO Thresholds</div>}
          </div>}
          <div>
            <h3>{compareOptions.find(c => c.id.startsWith("velocity")) ? "Custom" : ""}</h3>
            <div className={styles.table} style={{ gridTemplateColumns: "0.25fr 0.5fr 1fr 0.25fr 0.5fr 0.25fr" }}>
              <div className={styles.tableTitle}>Color</div>
              <div className={styles.tableTitle}>Type</div>
              <div className={styles.tableTitle}>Compare To</div>
              <div className={styles.tableTitle}>Value</div>
              <div className={styles.tableTitle}>Notifications</div>
              <div className={styles.tableTitle}>Remove</div>
              {shownThresholds.find(t => !t.is_iso) ? 
                shownThresholds.map((threshold, i) => {
                if (threshold.is_iso) {
                  return <></>
                }
                return (
                  <>
                    <div
                      style={{ backgroundColor: threshold.color }}
                      key={`${i}.1`} 
                    >
                      <input 
                        type="color" 
                        defaultValue={threshold.color} 
                        onChange={(e) => {
                          setThresholds(thresholds.map((t) => {
                            if (t.id === threshold.id) {
                              return { ...t, color: e.target.value };
                            }
                            return t;
                          }));
                        }}
                        onBlur={() => {
                          updateTrendsThreshold(threshold)
                            .then(loadThreshold)
                            .catch((e) => console.error(e));;
                        }}
                      />
                    </div>
                    <select 
                      value={threshold.type} 
                      key={`${i}.2`} 
                      onChange={(e) => {
                        if (thresholds.find(t => t.type === e.target.value && t.compared_to === threshold.compared_to && !t.is_iso)) {
                          setErrorMessage(`${getThresholdName({ ...threshold, type: e.target.value as "warning" | "danger" })} threshold already exists.`);
                          return;
                        }
                        updateTrendsThreshold({ ...threshold, type: e.target.value as "warning" | "danger" })
                          .then(loadThreshold)
                          .catch((e) => console.error(e));
                      }}
                    >
                      <option value="warning">Warning</option>
                      <option value="danger">Danger</option>
                    </select>
                    {compareOptions.length === 1 
                    ? <span>{compareOptions[0].name}</span>
                    : <select 
                        value={threshold.compared_to} 
                        key={`${i}.3`} 
                        onChange={(e) => {
                          if (thresholds.find(t => t.type === threshold.type && t.compared_to === e.target.value && !t.is_iso)) {
                            setErrorMessage(`${getThresholdName({ ...threshold, compared_to: e.target.value })} threshold already exists.`);
                            return;
                          }
                          updateTrendsThreshold({ ...threshold, compared_to: e.target.value })
                            .then(loadThreshold)
                            .catch((e) => console.error(e));
                        }}
                      >
                        {compareOptions.map((option, i) => {
                          return <option key={i} value={option.id}>{option.name}</option> 
                        })}
                      </select>
                    }
                    <input 
                      type="number" 
                      value={threshold.value} 
                      key={`${i}.4`}  
                      onChange={(e) => {
                        setThresholds(thresholds.map((t) => {
                          if (t.id === threshold.id) {
                            return { ...t, value: parseFloat(e.target.value) };
                          }
                          return t;
                        }));
                      }}
                      onBlur={() => {
                        updateTrendsThreshold(threshold)
                          .then(loadThreshold)
                          .catch((e) => console.error(e));
                      }}
                    />
                    <select 
                      value={threshold.notification_level} 
                      key={`${i}.5`} 
                      onChange={(e) => {
                        updateTrendsThreshold({ ...threshold, notification_level: parseInt(e.target.value) })
                          .then(loadThreshold)
                          .catch((e) => console.error(e));
                      }}
                    >
                      <option value="0">None</option>
                      <option value="1">Website</option>
                      <option value="2">Email</option>
                    </select>
                    <div key={`${i}.6`} className={`${styles.tableCell} ${styles.hasActionIcon}`}>
                      <img
                        className={styles.actionIcon}
                        alt="delete"
                        src={AddIcon}
                        style={{ transform: "rotate(45deg)" }}
                        onClick={() => {
                          deleteTrendsThreshold(threshold.id)
                            .then(loadThreshold)
                            .catch((e) => console.error(e));
                        }}
                      />
                    </div>
                  </>
                );
              }): <div className={styles.noResults}>No Results</div>}
            </div>
            <div className={styles.newThresholdWrapper}>
              <div className={errorMessage !== "" ? styles.error : ""}>{errorMessage}</div>
              <div className={styles.newThreshold} onClick={() => {
                let type: "warning" | "danger" | undefined, compared_to: string | undefined;
                for (const t of ["warning", "danger"]) {
                  for (const c of compareOptions) {
                    if (!thresholds.find(threshold => threshold.type === t && threshold.compared_to === c.id)) {
                      type = t as "warning" | "danger";
                      compared_to = c.id;
                      break;
                    }
                  }
                }
                if (!type || !compared_to) {
                  setErrorMessage("All possible thresholds are already in use.");
                  return;
                }

                addTrendsThreshold(
                  sensorId,
                  type,
                  compared_to,
                  false,
                  0,
                  `#${Math.floor(Math.random()*16777215).toString(16).padStart(6, "0")}`,
                  0,
                )
                  .then(loadThreshold)
                  .catch((e) => console.error(e));
              }}>
                <div />
                New Threshold
              </div>
            </div>
          </div>
        </>
      </PopUp>
      <PopUp
        isOpen={thresholdISOOpen}
        title="Threshold ISO Standards"
        setIsOpen={setThresholdISOOpen}
      >
        <div className={styles.thresholdISOSheet}>
          <img alt="iso standards" src={ISOStandard}></img>
        </div>
      </PopUp>
    </>
  );
}

export default ThresholdPopup;