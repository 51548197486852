// Overview
import DocsOverview from "./Articles/Overview/DocsOverview";
import AboutOpenPHM from "./Articles/Overview/AboutOpenPHM";

// Industry Tutorial
import IndustryOverview from "./Articles/IndustryTutorial/IndustryOverview";
import IoTSystemSetup from "./Articles/IndustryTutorial/IoTSystemSetup";
import IoTSystemOverview from "./Articles/IndustryTutorial/IoTSystemOverview";
import DataJSONFormat from "./Articles/IndustryTutorial/DataJSONFormat";

// Researcher Tutorial
import ResearcherTutorialOverview from "./Articles/ResearcherTutorial/ResearcherTutorialOverview";
import TheSampleManager from "./Articles/ResearcherTutorial/TheSampleManager";
import SearchingForData from "./Articles/ResearcherTutorial/SearchingForData";
import UploadingData from "./Articles/ResearcherTutorial/UploadingData";
import DownloadingData from "./Articles/ResearcherTutorial/DownloadingData";
import SharingData from "./Articles/ResearcherTutorial/SharingData";

// Data Analysis
import FFTAnalysisDocs from "./Articles/DataAnalysis/FFTAnalysisDocs";
import AnalyzingData from "./Articles/DataAnalysis/AnalyzingData";
import ExportingData from "./Articles/DataAnalysis/ExportingData";
import SampleView from "./Articles/DataAnalysis/SampleView";
import TrendsView from "./Articles/DataAnalysis/TrendsView";
import MachineSettings from "./Articles/DataAnalysis/MachineSettings";
import DataDownloadAPI from "./Articles/ResearcherTutorial/DataDownloadAPI";

/*
 * This index is used to generate the help pages and sidebar
 * Objects with "body: null" represent a section header
 */
export const HelpIndex = [
  // Overview
  {
    name: "Overview",
    body: null,
  },
  {
    name: "Documentation Overview",
    body: <DocsOverview />,
  },
  {
    name: "About OpenPHM",
    body: <AboutOpenPHM />,
  },

  // Industry Tutorial
  {
    name: "Industry Tutorial",
    body: null,
  },
  {
    name: "Industry Tutorial Overview",
    body: <IndustryOverview />,
  },
  {
    name: "IoT System Overview",
    body: <IoTSystemOverview />,
  },
  {
    name: "IoT System Setup",
    body: <IoTSystemSetup />,
  },
  {
    name: "Data JSON Format",
    body: <DataJSONFormat />,
  },

  // Researcher Tutorial
  {
    name: "Researcher Tutorial",
    body: null,
  },
  {
    name: "Researcher Tutorial Overview",
    body: <ResearcherTutorialOverview />,
  },
  {
    name: "The Sample Manager",
    body: <TheSampleManager />,
  },
  {
    name: "Searching for Data",
    body: <SearchingForData />,
  },
  {
    name: "Uploading Data",
    body: <UploadingData />,
  },
  {
    name: "Downloading Data",
    body: <DownloadingData />,
  },
  {
    name: "Sharing Data",
    body: <SharingData />,
  },
  {
    name: "Data Download API",
    body: <DataDownloadAPI />,
  },

  // Analysis Chapter
  {
    name: "Data Analysis",
    body: null,
  },
  {
    name: "Analysis Overview",
    body: <AnalyzingData />,
  },
  {
    name: "Trends View",
    body: <TrendsView />,
  },
  {
    name: "Sample View",
    body: <SampleView />,
  },
  {
    name: "FFT Analysis",
    body: <FFTAnalysisDocs />,
  },
  {
    name: "Machine Settings",
    body: <MachineSettings />,
  },
  {
    name: "Exporting Data",
    body: <ExportingData />,
  },
].map((page) => {
  return { id: page.name.replaceAll(" ", "_").toLowerCase(), ...page };
});
