import HelpContent from "../../HelpContent";
import Section from "../../Section";
import PageContents from "../../PageContents";

const SearchingForData = () => {
  return (
    <>
      <Section
        title={"Overview"}
        children={
          <>
            <div>
              <p style={{ float: "left", width: "70%" }}>
                {HelpContent["Searching For Data"].intro}
              </p>
              <PageContents
                sections={["Searching Researchers", "Searching Samples"]}
              />
            </div>
          </>
        }
      />
      <Section
        title={"Searching Researchers"}
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Searching For Data"].researcher,
                }}
              />
            </div>
          </>
        }
      />
      <Section
        title={"Searching Samples"}
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Searching For Data"].sample,
                }}
              />
              <p>{HelpContent["Searching For Data"].otherFilters}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Searching For Data"].actions,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default SearchingForData;
