import HelpContent from "../../HelpContent";
import MachineSettingsImg from "../../../../Images/HelpArticles/MachineSettings.png";
import CaptionedImage from "../../CaptionedImage";
import Section from "../../Section";
import PageContents from "../../PageContents";

const MachineSettings = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Machine Settings"].main,
                }}
              />
              <PageContents sections={["Rolling Elements", "Motor", "Gears"]} />
            </div>
            <CaptionedImage
              img={MachineSettingsImg}
              title="Machine Settings View"
              caption="The Machine Settings View for a sensor with all sections activated"
              width={100}
              position={"center"}
            />
          </>
        }
      />
      <Section
        title="Rolling Elements"
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Machine Settings"].rollingElems,
                }}
              />
            </div>
          </>
        }
      />
      <Section
        title="Motor"
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Machine Settings"].motor,
                }}
              />
            </div>
          </>
        }
      />

      <Section
        title="Gears"
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Machine Settings"].gears,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default MachineSettings;
