import { useEffect } from "react";
import { useMap } from "react-leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import L from "leaflet";

interface Props {
  setPosition: React.Dispatch<React.SetStateAction<any>>;
}

export default function LeafletControlGeocoder({ setPosition }: Props) {
  const map = useMap();

  useEffect(() => {
    let geoCoderOptions = {
      geocoder: L.Control.Geocoder.nominatim(),
      collapsed: true,
      placeholder: "Search...",
    };

    const search = L.Control.geocoder(geoCoderOptions).on(
      "markgeocode",
      (e: any) => {
        const position = {
          info: e.geocode.name,
          latLng: {
            lat: e.geocode.center.lat,
            lng: e.geocode.center.lng,
          },
        };
        setPosition(position);
      }
    );

    search.addTo(map);

    // Remove the geocoder from the map when the component is unmounted
    return () => map.removeControl(search);
  }, [map, setPosition]);

  return null;
}
