import React from "react";
import styles from "../../../../Styles/Help.module.css";
import HelpContent from "../../HelpContent";
import ReactJson from "react-json-view";
import Section from "../../Section";
import PageContents from "../../PageContents";

const ExportingData = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["Exporting Data"].overview,
                }}
              />
              <PageContents sections={["Export Types"]} />
            </div>
          </>
        }
      />
      <Section
        title="Export Types"
        children={
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html: HelpContent["Exporting Data"].main,
              }}
            />
            <table
              dangerouslySetInnerHTML={{
                __html: HelpContent["Exporting Data"].table,
              }}
              className={styles.table}
              style={{ width: "250px", marginTop: "1em" }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: HelpContent["Exporting Data"].json,
              }}
            />
            <ReactJson
              style={{
                margin: "1.5em 0",
              }}
              name={false}
              src={[
                {
                  time_ms: 0,
                  amplitude_Gs: 3.9265061885496877,
                },
                {
                  time_ms: 1,
                  amplitude_Gs: -0.11808145156005656,
                },
                {
                  time_ms: 2,
                  amplitude_Gs: 4.2707159397831225,
                },
              ]}
            />
          </div>
        }
      />
    </>
  );
};

export default ExportingData;
