import { useEffect, useRef, useState } from "react";
import { useOutsideDetect } from "../../../Utils/DropdownUtils";

import styles from "../../../Styles/SamplesDropdown.module.css";
import { Sample } from "../HTTPRequests/Types";

const SamplesDropdown = ({
  samples,
  mobile,
  goToSample,
}: {
  samples: Sample[];
  mobile: boolean;
  goToSample: (sample: Sample) => void;
}) => {
  const [samplesDropdownOpen, setSamplesDropdownOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const samplesBtnRef = useRef<HTMLDivElement>(null);
  const samplesDropdownRef = useRef<HTMLDivElement>(null);
  useOutsideDetect(samplesDropdownRef, samplesBtnRef, () => setSamplesDropdownOpen(false));

  const [size, setSize] = useState<number>(25);

  useEffect(() => {
    const handleScroll = () => {
      if (samplesDropdownRef.current === null) return;
      if (
        samplesDropdownRef.current.scrollTop +
        samplesDropdownRef.current.clientHeight >=
        samplesDropdownRef.current.scrollHeight - 50
      ) {
        setSize(size => size + 25);
      }
    };

    const list = samplesDropdownRef.current;
    list?.addEventListener("scroll", handleScroll);
    return () => {
      list?.removeEventListener("scroll", handleScroll);
    };
  }, [samplesDropdownOpen]);

  return (
    <div className={styles.samplesContainer}>
      <div
        ref={samplesBtnRef}
        className={mobile ? styles.mobileTitleButton : styles.samplesBtn}
        onClick={() => setSamplesDropdownOpen(!samplesDropdownOpen)}
      >
        <div className={styles.samples} />
        <span>Samples</span>
        <div
          className={styles.downArrow}
          style={{
            transform: `translateY(1px) ${
              samplesDropdownOpen ? "rotate(180deg)" : ""
            }`,
          }}
        />
      </div>

      {samplesDropdownOpen && (
        <div
          className={styles.samplesDropdown}
          ref={samplesDropdownRef}
        >
          <input
            type="text"
            placeholder={"Search for a sample..."}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {samples &&
          samples.filter((s) =>
            new Date(s.date).toLocaleString().toLowerCase().includes(search.toLowerCase())
          ).length ? (
            samples
              .filter((s) =>
                new Date(s.date).toLocaleString().toLowerCase().includes(search.toLowerCase())
              )
              .slice(0, size)
              .map((sample: Sample, i: number) => {
                return (
                  <div
                    className={`${styles.sampleResult} ${mobile ? styles.mobileSampleResult : ""}`}
                    onClick={() => goToSample(sample)}
                    key={i}
                  >
                    {new Date(sample.date).toLocaleString()}
                  </div>
                );
              })
          ) : (
            <div className={styles.sampleResult}>No samples found...</div>
          )}
        </div>
      )}
    </div>
  );
}

export default SamplesDropdown;