import PageWrapper from "../PageWrapper";
import InfoTooltip from "../../InfoTooltip";
import styles from "../../../Styles/MachineSettings.module.css";
import { useState, ReactNode, useContext, Dispatch, SetStateAction } from "react";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { TemperatureSettings } from "../HTTPRequests/Types";
import { updateTemperatureSettings } from "../HTTPRequests/Settings";

const SettingsSection = ({
  title,
  sensorId,
  tooltip,
  sectionOpen,
  setSectionOpen,
  active_name_db,
  temperatureSettings,
  setTemperatureSettings,
  children,
}: {
  title: string;
  sensorId?: number; // for industry users
  tooltip: string;
  sectionOpen: boolean;
  setSectionOpen: Dispatch<SetStateAction<boolean>>;
  active_name_db: string;
  temperatureSettings: TemperatureSettings | undefined;
  setTemperatureSettings: Dispatch<SetStateAction<TemperatureSettings | undefined>>;
  children: ReactNode;
}) => {
  if (!temperatureSettings) return <></>;

  const toggleOn = temperatureSettings[active_name_db];
  return (
    <div className={styles.section}>
      <div
        className={styles.header}
        style={{
          paddingBottom: `${sectionOpen ? "0.75em" : "0em"}`,
        }}
      >
        {title}
        <div
          onClick={() => {
            const newTemperatureSettings = { ...temperatureSettings }; 
            newTemperatureSettings[active_name_db] = !toggleOn;
            if (sensorId) {
              updateTemperatureSettings(sensorId, newTemperatureSettings).then((settings) => {
                setTemperatureSettings(settings);
              }).catch(e => console.log(e));
            } else {
              setTemperatureSettings(newTemperatureSettings);
            }
          }}
          className={`${styles.toggle} ${toggleOn ? styles.toggleOn : ""}`}
        >
          <div />
        </div>
        <InfoTooltip text={tooltip} />
      </div>
      {toggleOn && (
        <div
          className={styles.collapseArrow}
          onClick={() => setSectionOpen(!sectionOpen)}
          style={{
            transform: `${sectionOpen ? "rotate(180deg)" : "rotate(0deg)"}`,
          }}
        />
      )}

      {sectionOpen && children}
    </div>
  );
};

interface Props {
  mobile: boolean;
  sensorId?: number; // for industry users
  onClose: () => void;
  temperatureSettings: any;
  setTemperatureSettings: any;
}

/*
 * This is the temperature settings page
 * where options on relative temperature and units are changed
 */
const TemperatureSettingsView = ({
  mobile,
  sensorId,
  onClose,
  temperatureSettings,
  setTemperatureSettings,
}: Props) => {
  // Navigation
  const { tabInstance } =  useContext(AppContext) ?? {};
  const navigate = useNavigate();

  const setNumVal = (e: any, type: string) => {
    const newSettings = { ...temperatureSettings };
    if (!newSettings) return;
    newSettings[type] = isNaN(
      parseFloat(e.currentTarget.value)
    ) ? 0 : parseFloat(e.currentTarget.value);

    setTemperatureSettings(newSettings as any);
  };

  // Settings sections
  const [relativeTemperatureOpen, setRelativeTemperatureOpen] =
    useState<boolean>(false);

  return (
    <PageWrapper      
      title={"Temperature Settings"}
      mobile={mobile}
      titleChildren={
        <>
          <div className={styles.menuBtns}>
            {<div
              className={`${styles.headerButton} ${styles.question}`}
              onClick={() => {
                tabInstance.select("");
                tabInstance.refresh();
                setTimeout(() => navigate(`/help/machine_settings`), 0);
              }}
            />}

            <div
              className={`${styles.headerButton} ${styles.close}`}
              onClick={onClose}
            />
          </div>
        </>
      }
      mobileTitleChildren={
        <>
          <div
            className={styles.mobileTitleButton}
            onClick={() => {
              tabInstance.select("");
              tabInstance.refresh();
              setTimeout(() => navigate(`/help/machine_settings`), 0);
            }}
          >
            <div className={styles.question} />
            Help
          </div>

          <div
            className={styles.mobileTitleButton}
            onClick={onClose}
          >
            <div className={`${styles.close} ${styles.mobile}`} />
            Go Back
          </div>  
        </>
      }
    >
      <div className={styles.container}>
        {temperatureSettings && (
          <>
          <div className={styles.section}>
              <div className={styles.inputs}>
                <div>
                  <span>Units:</span>
                  <select
                    value={temperatureSettings.units}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      const newTemperatureSettings = { ...temperatureSettings, units: newValue };
                      if (sensorId) {
                        updateTemperatureSettings(sensorId, newTemperatureSettings).then((settings) => {
                          setTemperatureSettings(settings);
                        }).catch(e => console.log(e));
                      } else {
                        setTemperatureSettings(newTemperatureSettings);
                      }
                    }}
                  >
                    <option value="celsius">°Celsius</option>
                    <option value="fahrenheit">°Fahrenheit</option>
                    <option value="kelvin">Kelvin</option>
                  </select>
                </div>
              </div>
            </div>
            <SettingsSection
              title="Relative temperature"
              sensorId={sensorId}
              tooltip={`Set a baseline temperature and get the relative temperature of the sensor.`}
              sectionOpen={relativeTemperatureOpen}
              setSectionOpen={setRelativeTemperatureOpen}
              active_name_db="relative_temperature"
              temperatureSettings={temperatureSettings}
              setTemperatureSettings={setTemperatureSettings}
            >
              <div className={styles.inputs}>
                <div>
                  <span>Baseline temperature:</span>
                  <input
                    type="text"
                    value={temperatureSettings.baseline_temperature}
                    onChange={(e) => setNumVal(e, "baseline_temperature")}
                  />
                </div>
              </div>
            </SettingsSection>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default TemperatureSettingsView;
