import { FFTLayer } from "./Types";

export async function getSensorFFTLayers(sensorId: number, measurementType: string, axis: "x" | "y" | "z" = "x"): 
Promise<FFTLayer[]> {
  const response = await fetch(`/analysis_sensor_fft_layers/${sensorId}/${measurementType}/${axis}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getFolderFFTLayers(folderPath: string, measurementType: string, axis: "x" | "y" | "z" = "x"): 
Promise<FFTLayer[]> {
  const response = await fetch(`/analysis_folder_fft_layers/${encodeURIComponent(folderPath)}/${measurementType}/${axis}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function addFFTLayer(measurementIds: number[], color: string, orgId: number):
Promise<any> {
  const response = await fetch(`/new_fft_layer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ measurementIds, color, orgId }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function updateFFTLayer(layer: FFTLayer):
Promise<any> {
  const response = await fetch(`/update_fft_layer`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ graph: layer }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

export async function deleteFFTLayer(layerId: number):
Promise<any> {
  const response = await fetch(`/fft_layer/${layerId}`, {
    method: "DELETE",
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}
