import React, { useState, useEffect, useRef, useCallback, createContext, ReactNode } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useDynTabs from "react-dyn-tabs";
import { Tooltip } from "react-tooltip";

// HOME
import Home from "./Home";
import HomeMenu from "./NavBars/HomeMenu";

// DASHBOARDS
import DashboardTemperature from "./AnalysisPage/DashboardTemperature";
import DashboardAudio from "./AnalysisPage/DashboardAudio";

// INDUSTRY
import DeviceManager from "./FileManager/DeviceManager";
import ResetGatewayPassword from "./FileManager/SettingsModals/ResetGatewayPassword/ResetGatewayPassword";

// RESEARCHER
import SampleManager from "./FileManager/SampleManager";
import SearchPage from "./Researcher/SearchPage/SearchPage";
import SharePage from "./Researcher/SharePage/SharePage";

// ERROR PAGES
import Unauthorized from "./ErrorPages/Unauthorized";
import PageNotFound from "./ErrorPages/PageNotFound";

// OTHER PAGES
import Profile from "./ProfilePage/Profile";
import Menu from "./NavBars/Menu";
import Footer from "./Footer";
import Spinner from "./Spinner";

// HELP PAGES
import Help from "./HelpPage/Help";
import { HelpIndex } from "./HelpPage/HelpIndex";
import InvitePage from "./InvitePage/InvitePage";
import DashboardVibration from "./AnalysisPage/DashboardVibration";

export const AppContext = createContext<any>(null);

/*
 * This is the highest level component that should be modified
 * index.tsx should generally remain the same
 * This component is responsible for routing and the top level state (such as authentication)
 */
const App = () => {
  // Authentication States with Auth0
  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    loginWithPopup,
    logout,
    isLoading,
    error,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    // Override fetch function to always pass the auth token
    window.fetch = new Proxy(window.fetch, {
      apply: function (target, that, args) {
        const token = localStorage.getItem("token");
        if (token && args[0].charAt(0) === "/") {
          const authToken = `Bearer ${token}`;
          if (args.length === 2) {
            args[1] = {
              ...args[1],
              headers: {
                ...args[1].headers,
                authorization: authToken,
              },
            };
          } else {
            args.push({
              headers: {
                authorization: authToken,
              },
            });
          }
        }
        let temp = target.apply(that, args as [input: RequestInfo | URL, init?: RequestInit | undefined]);
        temp.then((res) => {
          const getToken = async () => {
            try {
              const token = await getAccessTokenSilently();
              localStorage.setItem("token", token);
              setToken(token);
            } catch (e: any) {
              console.error("TOKEN ERROR:", e.message);
            }
          };
      
          if (user?.sub) {
            getToken();
          }
          
          // After completion of request
          if (res.status === 401) {
            console.error("401");
          }
        });
        return temp;
      },
    });
  }, [getAccessTokenSilently, user?.sub]);

  const navigate = useNavigate();

  // This let's user's go back to the previous tab when they click the back button
  let _instance: any;
  const oldTab = useRef<string>("devicesTab");
  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      if (e.state) {
        _instance.select(oldTab.current);
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [navigate, _instance]);

  // Sort devices tab to end of list
  const sortDevicesTabLast = useCallback(() => {
    const { openTabIDs } = _instance.getData();
    const sorted = [
      ...openTabIDs.filter(
        (id: any) => !["devicesTab", "searchTab", "samplesTab"].includes(id)
      ),
      ...(localStorage.getItem("user_type") === "industry"
        ? ["devicesTab"]
        : ["searchTab", "samplesTab"]),
    ];
    _instance.sort(sorted);
  }, [_instance]);

  /* Setup tabs
  -------------------------------------*/
  const [tabs] = useState({
    tabs: [],
    onSelect: ({ currentSelectedTabId, previousSelectedTabId }: any) => {
      oldTab.current = previousSelectedTabId;
      const panel = document.getElementsByClassName(
        "rc-dyn-tabs-panellist"
      )[0] as HTMLElement;
      if (panel) {
        localStorage.setItem("currentTab", currentSelectedTabId);
        panel.style.display = "block";
        if (currentSelectedTabId === "devicesTab") {
          document.title = "Devices | openPHM";
          navigate("/devices");
          window.history.pushState(null, "", "/devices");
        } else if (currentSelectedTabId === "samplesTab") {
          document.title = "Samples | openPHM";
          navigate("/samples");
          window.history.pushState(null, "", "/samples");
        } else if (currentSelectedTabId === "searchTab") {
          document.title = "Search | openPHM";
          navigate("/search");
          window.history.pushState(null, "", "/search");
        } else if (
          currentSelectedTabId.split("$")[1] === "vibration_analysis"
        ) {
          document.title = "Analysis | openPHM";
          const urlBits = currentSelectedTabId.split("$");
          let url = "";
          for (let i = 2; i < urlBits.length; i++) {
            url += urlBits[i];
          }
          url = "/vibration_analysis/" + url.toLowerCase().replaceAll(" ", "_");
          navigate(url);
          window.history.pushState(null, "", url);
        } else if (
          currentSelectedTabId.split("$")[1] === "temperature_analysis"
        ) {
          document.title = "Analysis | openPHM";
          const urlBits = currentSelectedTabId.split("$");
          let url = "";
          for (let i = 2; i < urlBits.length; i++) {
            url += urlBits[i];
          }
          url =
            "/temperature_analysis/" + url.toLowerCase().replaceAll(" ", "_");
          navigate(url);
          window.history.pushState(null, "", url);
        } else if (currentSelectedTabId.split("$")[1] === "audio_analysis") {
          document.title = "Analysis | openPHM";
          const urlBits = currentSelectedTabId.split("$");
          let url = "";
          for (let i = 2; i < urlBits.length; i++) {
            url += urlBits[i];
          }
          url = "/audio_analysis/" + url.toLowerCase().replaceAll(" ", "_");
          navigate(url);
          window.history.pushState(null, "", url);
        } else if (currentSelectedTabId.split("$")[1] === "analysis") {
          document.title = "Analysis | openPHM";
          const urlBits = currentSelectedTabId.split("$");
          let url = "";
          for (let i = 2; i < urlBits.length; i++) {
            url += urlBits[i];
          }
          url = "/analysis/" + url.toLowerCase().replaceAll(" ", "_");
          navigate(url);
          window.history.pushState(null, "", url);
        } else if (currentSelectedTabId !== "") {
          const type = currentSelectedTabId.split("_")[0];
          const sensor = currentSelectedTabId.split("/").pop();
          document.title = `${sensor} | 
          ${type === "data" ? "Data Log" : "Analysis"} | 
          openPHM`;
          navigate("/");
          window.history.pushState(null, "", "/");
        } else if (currentSelectedTabId === "") {
          panel.style.display = "none";
          window.history.pushState(null, "", "/help");
        }

        // Refresh the tab render
        _instance.refresh();
      }
    },
    onClose: (closedTabIDs: string) => {
      const tabs = JSON.parse(localStorage.getItem("tabs") as string);
      const newTabs = tabs.filter((tab: any) => tab.id !== closedTabIDs[0]);
      localStorage.setItem("tabs", JSON.stringify(newTabs));
    },
    selectedTabID: "",
  });

  const [TabList, PanelList, ready] = useDynTabs(tabs);
  ready((instance: any) => {
    _instance = instance;
  });

  const [lastLogin, setLastLogin] = useState<string | null>(null);
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        localStorage.setItem("token", token);
        setToken(token);
      } catch (e: any) {
        console.error("TOKEN ERROR:", e.message);
      }
    };

    if (user?.sub) {
      getToken();
    }
  }, [getAccessTokenSilently, user?.sub]);

  const _logout = () => {
    // Clear the session of open tabs
    localStorage.setItem("tabs", "[]");

    localStorage.removeItem("user_type");

    // Logout using auth0
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // Shows the page if logged in, otherwise shows "401 - Unauthorized" page
  const ifAuthorized = useCallback(
    (
      uType: "industry" | "researcher" | null,
      elem: ReactNode
    ): ReactNode => {
      if (
        isAuthenticated &&
        (uType === localStorage.getItem("user_type") ||
          (uType === null &&
            ["industry", "researcher"].includes(
              localStorage.getItem("user_type")!
            )))
      ) {
        return elem;
      } else {
        return <Unauthorized />;
      }
    },
    [isAuthenticated]
  );

  // get and update last log in
  useEffect(() => {
    if (isAuthenticated && user?.sub && token) {
      // Get last login
      fetch(`/get_last_login?user_id=${encodeURIComponent(user?.sub)}`)
        .then((res: any) => res.json())
        .then((e) => {
          if (e) {
            setLastLogin(e[0].last_login);
          }
        });

      // Update last login
      fetch(`/update_last_login`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user?.sub,
        }),
      }).then((res) => {
        if (res.status !== 200) {
          console.error(res);
        }
      });
    }
  }, [user, isAuthenticated, token]);

  useEffect(() => {
    if (user && token) {
      fetch(`/new_profile`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...user,
          user_type: localStorage.getItem("user_type"),
        }),
      }).catch((error) => {
        console.error(error);
      });
      if (localStorage.getItem("user_type") === "industry") {
        _instance
          .open({
            id: "devicesTab",
            iconClass: "fa fa-toolbox",
            title: "",
            panelComponent: () =>
              ifAuthorized(
                "industry",
                <DeviceManager
                  user={user}
                  sortDevicesTabLast={sortDevicesTabLast}
                  ifAuthorized={ifAuthorized}
                />
              ),
          })
          .then(sortDevicesTabLast);
      } else if (localStorage.getItem("user_type") === "researcher")
        _instance
          .open({
            id: "searchTab",
            iconClass: "fa fa-search",
            title: "",
            panelComponent: () =>
              ifAuthorized(
                "researcher",
                <SearchPage
                  user_id={user?.sub!}
                  sortDevicesTabLast={sortDevicesTabLast}
                  ifAuthorized={ifAuthorized}
                  initialCategory="samples"
                />
              ),
          })
          .then(() => {
            _instance.open({
              id: "samplesTab",
              iconClass: "fa fa-wave-square",
              title: "",
              panelComponent: () =>
                ifAuthorized(
                  "researcher",
                  <SampleManager
                    user={user}
                    sortDevicesTabLast={sortDevicesTabLast}
                    ifAuthorized={ifAuthorized}
                  />
                ),
            });
          })
          .then(sortDevicesTabLast);
    }
  }, [_instance, user, sortDevicesTabLast, user?.sub, ifAuthorized, token]);

  useEffect(() => {
    if (!isLoading) {
      // Open the tabs from local storage
      const tabs = JSON.parse(localStorage.getItem("tabs") as string);
      const user_type = localStorage.getItem("user_type") as
        | "industry"
        | "researcher"
        | null;
      if (tabs) {
        tabs.forEach((tab: any) => {
          let panel: any = null;
          if (tab.id.split("$")[1] === "vibration_analysis") {
            const path = (tab.id as string).split("/");
            let axis = path.pop();
            if (axis !== undefined && !["x", "y", "z"].includes(axis)) {
              axis = undefined;
            }
            panel = ifAuthorized(
              user_type,
              user_type === "industry" ? (
                <DashboardVibration sensorId={tab.sensorNumId} axis={axis as "x" | "y" | "z" | undefined} />
              ) : (
                <DashboardVibration folderPath={(`${path.join("/")}/`).split("$")[2]} axis={axis as "x" | "y" | "z" | undefined} />
              )
            );
          } else if (tab.id.split("$")[1] === "temperature_analysis") {
            panel = ifAuthorized(
              user_type,
              user_type === "industry" ? (
                <DashboardTemperature sensorId={tab.sensorNumId} />
              ) : (
                <DashboardTemperature folderPath={tab.id.split("$")[2]} />
              )
            );
          } else if (tab.id.split("$")[1] === "audio_analysis") {
            panel = ifAuthorized(
              user_type,
              user_type === "industry" ? (
                <DashboardAudio sensorId={tab.sensorNumId} />
              ) : (
                <DashboardAudio folderPath={tab.id.split("$")[2]} />
              )
            );
          }
          _instance
            .open({
              ...tab,
              panelComponent: () => panel,
            })
            .then(sortDevicesTabLast);
        });
      }

      // Don't select a tab if on profile or help page
      const path = window.location.pathname
        .split("/")
        .filter((part) => part !== "");
      // If the page is not a tab page, do not select the default tab
      const nonTabPages = [
        "profile",
        "help",
        "reset_gateway_password",
        "share",
        "invite",
      ];
      if (path.length && nonTabPages.some((item) => path[0].includes(item))) {
        return;
      }

      // Select the default tab
      _instance.select(
        localStorage.getItem("currentTab")
          ? localStorage.getItem("currentTab")
          : localStorage.getItem("user_type") === "industry"
          ? "devicesTab"
          : "samplesTab"
      );
    }
  }, [isLoading, _instance, sortDevicesTabLast, ifAuthorized]);

  // Handle error case
  if (error) return <div>Oops... {error.message}</div>;

  // The app is still loading data
  if (isLoading) return <Spinner fullscreen={true} />;

  return (
    <AppContext.Provider
      // The auth token is stored in AppContext so it can
      // be accessed from anywhere
      value={{ token: token, tabInstance: _instance }}
    >
      <Tooltip
        id="my-tooltip"
        style={{
          zIndex: 100,
        }}
      />
      {isAuthenticated ? (
        <Menu
          logout={() => _logout()}
          TabList={<TabList />}
          PanelList={<PanelList />}
          user={user}
        />
      ) : (
        <HomeMenu />
      )}

      <Routes>
        {/* Use dashboard as home when logged in */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <></>
            ) : (
              <Home loginWithRedirect={loginWithRedirect} />
            )
          }
        />

        <Route
          path="/reset_gateway_password/:uuid"
          element={
            <ResetGatewayPassword
              isAuthenticated={isAuthenticated}
            />
          }
        />

        <Route
          path="/share/:share_link_id"
          element={
            <SharePage
              user={user}
              sortDevicesTabLast={sortDevicesTabLast}
              ifAuthorized={ifAuthorized}
            />
          }
        />

        <Route
          path="/invite/:invite_link_id"
          element={<InvitePage user={user} loginWithPopup={loginWithPopup} />}
        />

        {token && (
          <>
            <Route
              path="profile"
              element={ifAuthorized(
                null,
                <Profile
                  user={user}
                  lastLogin={lastLogin}
                  logout={() => _logout()}
                  userType={
                    localStorage.getItem("user_type") as
                      | "industry"
                      | "researcher"
                  }
                />
              )}
            />

            {/* Sensor Info */}
            <Route
              path="sample-manager"
              element={ifAuthorized(
                "researcher",
                <SampleManager
                  user={user}
                  sortDevicesTabLast={sortDevicesTabLast}
                  ifAuthorized={ifAuthorized}
                />
              )}
            />

            {/* ----- Help Pages ----- */}
            <Route path="help/:id" element={<Help pages={HelpIndex} />} />
            <Route
              path="help"
              element={<Navigate to="help/documentation_overview" />}
            />

            {/* Analyst Mode */}
            <Route path="vibration_analysis/*" element={<></>} />
            <Route path="temperature_analysis/*" element={<></>} />
            <Route path="audio_analysis/*" element={<></>} />
            <Route path="analysis/*" element={<></>} />
            <Route path="data_log/*" element={<></>} />
            <Route path="devices" element={<></>} />
            <Route path="samples" element={<></>} />
            <Route path="search" element={<></>} />
          </>
        )}
        {!token && (
          <>
            <Route path="profile" element={<Unauthorized />} />

            {/* Sensor Info */}
            <Route path="temperature_analysis" element={<Unauthorized />} />
            <Route path="audio_analysis" element={<Unauthorized />} />
            <Route path="sample-manager" element={<Unauthorized />} />

            {/* ----- Help Pages ----- */}
            <Route path="help" element={<Unauthorized />} />
            <Route path="help/:id" element={<Unauthorized />} />

            {/* Analyst Mode */}
            <Route path="vibration_analysis/*" element={<Unauthorized />} />
            <Route path="temperature_analysis/*" element={<Unauthorized />} />
            <Route path="audio_analysis/*" element={<Unauthorized />} />
            <Route path="analysis/*" element={<Unauthorized />} />
            <Route path="data_log/*" element={<Unauthorized />} />
            <Route path="devices" element={<Unauthorized />} />
            <Route path="samples" element={<Unauthorized />} />
            <Route path="search" element={<Unauthorized />} />
          </>
        )}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </AppContext.Provider>
  );
};

export default App;
