import React from "react";
import ClassNames from "classnames";
import { DragSource, DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import flow from "lodash/flow";

import BaseFolder, { BaseFolderConnectors } from "./../base-folder.js";
import { BaseFileConnectors } from "./../base-file.js";

import { LinkedinIcon, LinkedinShareButton } from "react-share";
import LinkShare from "../../Researcher/LinkShare";

class RawTableFolder extends BaseFolder {
  constructor(props) {
    super(props);
    this.state = {
      shareUrl: "",
    };
  }
  getShareURL() {
    this.props.shareFunc().then((res) => {
      this.setState({ shareUrl: res });
    });
  }
  render() {
    const {
      isOpen,
      isDragging,
      isDeleting,
      isRenaming,
      isDraft,
      isOver,
      isSelected,
      action,
      url,
      browserProps,
      connectDragPreview,
      depth,

      // Gateway Data
      statusIcon,
      isGateway,
      isOrg,
      analysisFunc,

      shareFunc,

      gatewaySettings,
      orgSettings,
      name,
      id,
      password,
      description,
      location,
    } = this.props;

    const icon =
      browserProps.icons[
        isOrg ? "Org" : isGateway ? "Gateway" : isOpen ? "FolderOpen" : "Folder"
      ];
    const inAction = isDragging || action;

    const ConfirmDeletionRenderer = browserProps.confirmDeletionRenderer;

    let nameLbl;
    if (!inAction && isDeleting && browserProps.selection.length === 1) {
      nameLbl = (
        <ConfirmDeletionRenderer
          handleDeleteSubmit={this.handleDeleteSubmit}
          handleFileClick={this.handleFileClick}
          url={url}
        >
          {icon}
          {isOrg ? this.getName().split("#")[0] : this.getName()}
          {isOrg && (
            <span
              style={{ marginLeft: "0.25rem", color: "hsl(208 15% 50% / 1)" }}
            >
              #{this.getName().split("#")[1]}
            </span>
          )}
        </ConfirmDeletionRenderer>
      );
    } else if ((!inAction && isRenaming) || isDraft) {
      nameLbl = (
        <div>
          <form className="renaming" onSubmit={this.handleRenameSubmit}>
            <span style={{ color: statusIcon }}>⬤</span>
            {icon}
            <input
              type="text"
              ref={this.selectFolderNameFromRef}
              value={this.state.newName}
              onChange={this.handleNewNameChange}
              onBlur={this.handleCancelEdit}
              autoFocus
            />
            {isOrg && (
              <span
                style={{ marginLeft: "0.25rem", color: "hsl(208 15% 50% / 1)" }}
              >
                #{this.getName().split("#")[1]}
              </span>
            )}
          </form>
        </div>
      );
    } else {
      nameLbl = (
        <div>
          <a onClick={this.toggleFolder}>
            <span style={{ color: statusIcon }}>⬤</span>
            {icon}
            {isOrg ? this.getName().split("#")[0] : this.getName()}
            {isOrg && (
              <span
                style={{ marginLeft: "0.25rem", color: "hsl(208 15% 50% / 1)" }}
              >
                #{this.getName().split("#")[1]}
              </span>
            )}
          </a>
        </div>
      );
    }

    let draggable = <div className="nameLbl">{nameLbl}</div>;
    if (typeof browserProps.moveFile === "function") {
      draggable = connectDragPreview(draggable);
    }

    const folder = (
      <tr
        className={ClassNames("folder", {
          pending: action,
          dragging: isDragging,
          dragover: isOver,
          selected: isSelected,
        })}
        onClick={this.handleFolderClick}
        onDoubleClick={this.handleFolderDoubleClick}
      >
        <td className="name">
          <div
            className="nameBox"
            style={{
              paddingLeft:
                Math.max(8, depth * 17 + (isSelected ? -5 : 0)) + "px",
            }}
          >
            {draggable}
            {shareFunc && (
              <>
                <LinkedinShareButton
                  url={this.state.shareUrl}
                  beforeOnClick={() => this.getShareURL()}
                >
                  <div className="linked-in-icon">
                    <LinkedinIcon size={"1.5rem"} round={true} />
                  </div>
                </LinkedinShareButton>
                <LinkShare
                  url={this.state.shareUrl}
                  beforeOnClick={() => this.getShareURL()}
                />
              </>
            )}
            {analysisFunc && (
              <div
                className="rowBtn"
                onClick={() => {
                  analysisFunc();
                }}
              >
                <i className="fa fa-chart-simple" aria-hidden="true" />
                {window.innerWidth > 550 && <>Analysis</>}
              </div>
            )}
            {(isGateway || isOrg) && browserProps.showOrgSettings && (
              <>
                <div
                  className="device-settings"
                  onClick={() => {
                    if (isGateway) {
                      gatewaySettings({
                        name: name,
                        id: id,
                        password: password,
                        description: description,
                        location: location,
                      });
                    } else if (isOrg) {
                      orgSettings(this.props);
                    }
                  }}
                >
                  <i className="fa fa-gear" aria-hidden="true" />
                </div>
              </>
            )}
          </div>
        </td>
      </tr>
    );

    return this.connectDND(folder);
  }
}

const TableFolder = flow(
  DragSource(
    "folder",
    BaseFolderConnectors.dragSource,
    BaseFolderConnectors.dragCollect
  ),
  DropTarget(
    ["file", "folder", NativeTypes.FILE],
    BaseFileConnectors.targetSource,
    BaseFileConnectors.targetCollect
  )
)(RawTableFolder);

export default TableFolder;
export { RawTableFolder };
