import React, { useEffect, useRef, useState } from "react";
import styles from "../../../Styles/RelatedSamples.module.css";
import { capitalizeFirstLetter } from "../../../Utils/Utils";

const SelectTable = ({
  samples,
  samplesSelected,
  setSamplesSelected,
  loadMoreResults,
}: {
  samples: any[];
  samplesSelected: any[];
  setSamplesSelected:
    | React.Dispatch<React.SetStateAction<any[]>>
    | ((func: (curr: any[]) => any[]) => void);
  loadMoreResults: () => any;
}) => {
  const resultsRef = useRef<HTMLDivElement>(null);
  const [shiftDown, setShiftDown] = useState(false);

  // Add shift event listener
  // To shift select multiple results
  useEffect(() => {
    const handleEvent = (e: KeyboardEvent, down: boolean) => {
      if (e.key === "Shift") {
        setShiftDown(down);
      }
    };

    document.addEventListener("keydown", (e) => handleEvent(e, true));
    document.addEventListener("keyup", (e) => handleEvent(e, false));
    return () => {
      document.removeEventListener("keydown", (e) => handleEvent(e, true));
      document.removeEventListener("keyup", (e) => handleEvent(e, false));
    };
  }, []);

  // Add scroll event listener to load more results when the user scrolls to the bottom
  const loadingRef = useRef(false);
  useEffect(() => {
    const handleScroll = () => {
      if (resultsRef.current === null) return;
      if (
        resultsRef.current.scrollTop + resultsRef.current.clientHeight >=
          resultsRef.current.scrollHeight - 100 &&
        !loadingRef.current
      ) {
        loadingRef.current = true;
        loadMoreResults().then(() => {
          loadingRef.current = false;
        });
      }
    };

    const results = resultsRef.current;
    results?.addEventListener("scroll", handleScroll);
    return () => {
      results?.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreResults]);

  return (
    <div className={styles.searchResults} ref={resultsRef}>
      <div className={`${styles.tableTitle} ${styles.hasCheckbox}`}>
        <input
          type="checkbox"
          checked={samplesSelected.length === samples.length}
          onChange={() =>
            setSamplesSelected((samplesSelected) =>
              samplesSelected.length !== samples.length ? samples : []
            )
          }
        />
      </div>
      <div className={styles.tableTitle}>Name</div>
      <div className={styles.tableTitle}>Type</div>
      <div className={styles.tableTitle}>Tags</div>
      {samples.length === 0 ? (
        <div className={styles.noResults}>No Results</div>
      ) : (
        <>
          {samples.map((result: any, i: number) => (
            <>
              <div
                className={`${styles.tableCell} ${styles.hasCheckbox}`}
                key={`${i}.1`}
              >
                <input
                  type="checkbox"
                  checked={samplesSelected.map((s) => s.id).includes(result.id)}
                  onChange={() => {
                    if (samplesSelected.map((s) => s.id).includes(result.id)) {
                      setSamplesSelected((samplesSelected) =>
                        samplesSelected.filter((s) => s.id !== result.id)
                      );
                    } else if (shiftDown && samplesSelected.length > 0) {
                      const lastSelectedIndex = samples.findIndex(
                        (s) =>
                          s.id ===
                          samplesSelected[samplesSelected.length - 1].id
                      );
                      setSamplesSelected((samplesSelected) => [
                        ...samplesSelected,
                        ...samples
                          .slice(
                            lastSelectedIndex < i ? lastSelectedIndex + 1 : i,
                            lastSelectedIndex < i ? i + 1 : lastSelectedIndex
                          )
                          .filter(
                            (s) =>
                              !samplesSelected.map((s) => s.id).includes(s.id)
                          ),
                      ]);
                    } else {
                      setSamplesSelected((samplesSelected) => [
                        ...samplesSelected,
                        result,
                      ]);
                    }
                  }}
                />
              </div>
              <div className={styles.tableCell} key={`${i}.2`}>
                {result.mtime}
              </div>
              <div className={styles.tableCell} key={`${i}.3`}>
                {capitalizeFirstLetter(result.type)}
              </div>
              <div className={styles.tableCell} key={`${i}.4`}>
                {result.tags.map((tag: any, i: number) => {
                  return (
                    <div
                      style={{
                        backgroundColor: tag.color,
                      }}
                      key={i}
                      className={styles.tag}
                    >
                      {tag.value}
                    </div>
                  );
                })}
              </div>
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default SelectTable;
