import axios from "axios";

// BEARING FAULTS

// Ball pass frequency, outer race
export const BPFO = (
  Bd: number,
  Pd: number,
  angle: number,
  rpm: number,
  Nb: number
): number => {
  return ((rpm * Nb) / 120) * (1 - (Bd / Pd) * Math.cos(angle));
};

// Ball pass frequency, inner race
export const BPFI = (
  Bd: number,
  Pd: number,
  angle: number,
  rpm: number,
  Nb: number
): number => {
  return ((rpm * Nb) / 120) * (1 + (Bd / Pd) * Math.cos(angle));
};

// Ball speed frequency
export const BSF = (Bd: number, Pd: number, angle: number, rpm: number): number => {
  return (
    ((rpm * Pd) / (120 * Bd)) * (1 - (Bd / Pd) ** 2 * Math.cos(angle) ** 2)
  );
};

// Fundamental Train Frequency (FTF)
export const FTF = (Bd: number, Pd: number, angle: number, rpm: number): number => {
  return (rpm / 120) * (1 - (Bd / Pd) * Math.cos(angle));
};

// ELECTRIC MOTOR FAULTS

// Shaft/Motor Frequency (Hz)
export const MotorFreq = (rpm: number): number => {
  return rpm / 60;
};

// Slip frequency
export const SF = (Aa: number, As: number): number => {
  return (As - Aa) / 60;
};

// Pole pass frequency
export const PPF = (Aa: number, As: number, P: number): number => {
  return P * SF(Aa, As);
};

// Rotor bar passing frequency
export const RBF = (Aa: number, As: number, R: number): number => {
  return R * SF(Aa, As);
};

// Fan blade frequency
export const FBD = (Aa: number, As: number, B: number): number => {
  return B * SF(Aa, As);
};

// GEAR FAULTS

// Gear Mesh Frequency
export const GMF = (rpm: number, T: number): number => {
  return (rpm * T) / 60;
};

// Hunting Tooth Frequency
export const HTF = (
  rpm: number,
  T: number,
  Tin: number,
  Tout: number,
  Na: number
): number => {
  return (rpm / 60) * GMF(rpm, T) * (Na / (Tin * Tout));
};

// Gear Assembly Phase Frequency
export const GAPF = (rpm: number, T: number, Na: number): number => {
  return GMF(rpm, T) / Na;
};

// IMBALANCE FAULTS

// Dynamic Imbalance Frequency
export const DIF = (rpm: number): number => {
  return rpm / 60;
};

// Static Imbalance Frequency
export const SIF = (rpm: number): number => {
  return rpm / 60;
};

// Couple Imbalance Frequency
export const CIF = (rpm: number): number => {
  return rpm / 60;
};

// Overhung Machine Frequency
export const OMF = (rpm: number): number => {
  return rpm / 60;
};

// Get raw FFT and envelope data
export const getEnvelope = async (
  startPoint: number,
  endPoint: number,
  resolution: number,
  interpolationType: string,
  dataIds: number[],
  dataType: "acceleration" | "velocity" | "audio",
  token: string
) => {
  if (!dataIds.length) {
    return null;
  }
  if (isNaN(startPoint)) {
    return null;
  }
  if (startPoint < 0) {
    return null;
  }
  const endData = await axios.get("/envelope", {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    params: {
      startTime: startPoint,
      endTime: endPoint,
      resolution: resolution,
      interpolationType: interpolationType,
      dataType: dataType,
      dataIds: dataIds,
    },
  });

  return endData?.data;
};

// The properties for graph lines
export const LineProps = (data: any) => {
  return {
    type: "monotone" as any,
    strokeWidth: 4,
    dot: {
      r: data && data.length !== 1 ? 0 : 4,
      strokeWidth: 5,
    },
    activeDot: {
      r: 7,
    },
    isAnimationActive: false,
  };
};

// Time label dynamically changes based on number of
// data points (hours, days, months etc.)
// Used for the tick label
export const getDynamicDate = (
  date: string,
  left: string | number,
  right: string | number,
  sampleData: any
): string => {
  let leftTime: any = left;
  let rightTime: any = right;
  if (left === "dataMin") {
    leftTime = sampleData[0].Time;
  }
  if (right === "dataMax") {
    rightTime = sampleData[sampleData.length - 1].Time;
  }

  const diff = Math.abs(rightTime - leftTime);
  const day = 86400000;
  const hour = 3600000;

  let dateOption: any = null;
  if (diff > day) {
    // Example: Nov 24
    dateOption = {
      month: "short",
      day: "numeric",
    };
  } else if (diff > hour) {
    // Example: Nov 24, 11PM
    dateOption = {
      month: "short",
      day: "numeric",
      hour: "numeric",
    };
  } else {
    // Example: Nov 24, 11:44PM
    dateOption = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
  }

  return new Date(date)
    .toLocaleDateString(undefined, dateOption)
    .replaceAll("\u202F", " ");
};

// This is for the basic key value pair object
// associating line names with colors (in hex or rgb)
export const LineColors: {
  [key: string]: string;
} = {
  None: "rgb(70, 70, 70)",

  // Threshold
  "Warning": "rgb(237, 132, 0)",
  "Danger": "rgb(200, 0, 0)",

  // Bearing
  "Ball Pass Frequency of Inner Race (BPFI)": "rgb(50, 0, 255)",
  "Ball Pass Frequency of Outer Race (BPFO)": "rgb(225, 0, 0)",
  "Ball Spin Frequency (BSF)": "rgb(0, 100, 0)",
  "Fundamental Train Frequency (FTF)": "rgb(220, 100, 0)",

  // Electric Motor
  "Shaft/Motor Frequency": "rgb(230, 0, 200)",
  "Pole Pass Frequency (PPF)": "rgb(40,190,40)",
  "Slip Frequency (SF)": "rgb(174, 77, 40)",
  "Rotor Bar Passing Frequency (RBF)": "rgb(0, 165, 246)",
  "Fan Blade Frequency (FBD)": "rgb(0, 103, 18)",

  // Gear
  "Gear Mesh Frequency (GMF)": "#16c955",
  "Hunting Tooth Frequency (HTF)": "#1630c9",
  "Gear Assembly Phase Frequency (GAPF)": "#c91689",

  // Imbalance
  "Dynamic Imbalance Frequency (DIF)": "#7c5ad6",
  "Static Imbalance Frequency (SIF)": "#132c26",
  "Couple Imbalance Frequency (CIF)": "#c9b400",
  "Overhung Machine Frequency (OMF)": "#d65a76",

  "Custom": "rgb(127, 200, 135)",

  Temperature: "rgb(5,122,68)",

  RMS: "rgb(5,122,68)",
  Kurtosis: "#c51a9e",
  P2P: "rgb(6 104 133)",

  Unbalance: "#de9400",
  Misalignment: "#d63402",
  "Inner Race": "#000757",
  "Outer Race": "#001bc7",
  "Ball Bearing": "#c700c4",
  Shaft: "#b00c48",
  Gear: "#00ba63",
  Cage: "#54ba00",
};