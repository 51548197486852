import { ChangeEvent, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import InfoTooltip from "../../InfoTooltip";
import styles from "../../../Styles/FFTThresholds.module.css";
import { getEnvelope } from "../../../Utils/GraphUtils";
import DeleteBtn from "../../../Images/X.png";
import AddBtn from "../../../Images/+.png";
import PopUp from "../../PopUp";
import SampleDropdown from "../SampleDropdown";
import { AppContext } from "../../App";
import { FFTThreshold, Sample } from "../HTTPRequests/Types";
import { getFFTThresholds, updateFFTThreshold } from "../HTTPRequests/Thresholds";

interface Props {
  setThreshold: Dispatch<SetStateAction<FFTThreshold | undefined>>;
  threshold: FFTThreshold;
  thresholdSettingsOpen: boolean;
  setThresholdSettingsOpen: Dispatch<SetStateAction<boolean>>;
  sensorId: number;
  samples: Sample[];
  dataType: "acceleration" | "velocity" | "audio";
  axis?: "x" | "y" | "z";
}

/*
 * This is the popup for FFT threshold settings
 * FFT thresholds which can be set manually based on
 * frequency bins or calculated automatically based on
 * the FFT of a chosen sample
 */
const FFTThresholds = ({
  setThreshold,
  threshold,
  thresholdSettingsOpen,
  setThresholdSettingsOpen,
  sensorId,
  samples,
  dataType,
  axis,
}: Props) => {
  const [thresholdType, setThresholdType] = useState<"mask" | "band">(threshold.is_custom ? "band" : "mask");

  const handleThresholdTypeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const val = event.target.value;
    if (val === "mask" || val === "band") {
      setThresholdType(val);
    }
  };

  const [warningPercent, setWarningPercent] = useState<number>(threshold.warning_percent);
  const [dangerPercent, setDangerPercent] = useState<number>(threshold.danger_percent);
  const [dropoffPercent, setDropoffPercent] = useState<number>(threshold.dropoff_percent);
  const [bandWarnings, setBandWarnings] = useState<number[]>(threshold.warnings);
  const [bandDangers, setBandDangers] = useState<number[]>(threshold.dangers);
  const [bandFrequencies, setBandFrequencies] = useState<number[]>(threshold.frequencies);
  const [notificationLevel, setNotificationLevel] = useState<number>(threshold.notification_level);

  // The sample picker (for mask thresholds)
  const [measurementIds, setMeasurementIds] = useState<number[]>(threshold.measurements);
  const [sampleSearch, setSampleSearch] = useState<string>("");
  const [sampleDropdownOpen, setSampleDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setThresholdType(threshold.is_custom ? "band" : "mask");
    setWarningPercent(threshold.warning_percent);
    setDangerPercent(threshold.danger_percent);
    setDropoffPercent(threshold.dropoff_percent);
    setBandWarnings(threshold.warnings);
    setBandDangers(threshold.dangers);
    setBandFrequencies(threshold.frequencies);
    setMeasurementIds(threshold.measurements);
    setNotificationLevel(threshold.notification_level);
  }, [threshold]);

  const bandRow = (rowType: string, index: number, s: any) => {
    return (
      <div className={styles.bandRow}>
        <div className={styles.deleteCol}>
          {rowType === "band_frequency" &&
            index !== 0 &&
            index !== bandFrequencies.length - 1 &&
            bandWarnings.length > 1 && (
              <img
                alt={"Delete Band"}
                src={DeleteBtn}
                onClick={() => {
                  // Remove the band
                  const newFrequencies = [...bandFrequencies];
                  const newWarnings = [...bandWarnings];
                  const newDangers = [...bandDangers];

                  newFrequencies.splice(index, 1);
                  newWarnings.splice(index - 1, 1);
                  newDangers.splice(index - 1, 1);

                  setBandFrequencies(newFrequencies);
                  setBandWarnings(newWarnings);
                  setBandDangers(newDangers);
                }}
              />
            )}
        </div>
        <div
          className={`${styles.frequencyCol} 
            ${rowType !== "band_frequency" ? styles.greyedOut : ""}`}
        >
          {rowType === "band_frequency" && (
            <input
              type="number"
              min="0"
              max="10000"
              step="1"
              value={s.frequency}
              disabled={index === 0}
              onChange={(e) => {
                const newVal = Number(e.target.value);
                if (
                  newVal > bandFrequencies[index - 1] &&
                  (newVal < bandFrequencies[index + 1] ||
                    !bandFrequencies[index + 1])
                ) {
                  const newFrequencies = [...bandFrequencies];
                  newFrequencies[index] = newVal;
                  setBandFrequencies(newFrequencies);
                }
              }}
              className={styles.bandInput}
            />
          )}
        </div>
        <div
          className={`${styles.warningCol} 
        ${rowType !== "threshold" ? styles.greyedOut : ""}`}
        >
          {rowType === "threshold" && (
            <input
              type="number"
              min="0"
              max="10000"
              step="1"
              value={s.warning}
              onChange={(e) => {
                const newVal = Number(e.target.value);
                if (newVal < bandDangers[index - 1]) {
                  const newWarnings = [...bandWarnings];
                  newWarnings[index - 1] = newVal;
                  setBandWarnings(newWarnings);
                }
              }}
              className={styles.bandInput}
            />
          )}
        </div>
        <div
          className={`${styles.dangerCol} 
        ${rowType !== "threshold" ? styles.greyedOut : ""}`}
        >
          {rowType === "threshold" && (
            <input
              type="number"
              min="0"
              max="10000"
              step="1"
              value={s.danger}
              onChange={(e) => {
                const newVal = Number(e.target.value);
                if (newVal > bandWarnings[index - 1]) {
                  const newDangers = [...bandDangers];
                  newDangers[index - 1] = newVal;
                  setBandDangers(newDangers);
                }
              }}
              className={styles.bandInput}
            />
          )}
        </div>
        <div className={styles.addCol}>
          {rowType === "threshold" && bandWarnings.length < 12 && (
            <img
              alt={"Add Band"}
              src={AddBtn}
              onClick={() => {
                const newFrequencies = [...bandFrequencies];
                const newWarnings = [...bandWarnings];
                const newDangers = [...bandDangers];

                const prevF = newFrequencies[index - 1];
                const nextF = newFrequencies[index];
                const newFrequency = Math.floor((prevF + nextF) / 2);
                newFrequencies.splice(index, 0, newFrequency);
                newWarnings.splice(index, 0, 50);
                newDangers.splice(index, 0, 100);

                setBandFrequencies(newFrequencies);
                setBandWarnings(newWarnings);
                setBandDangers(newDangers);
              }}
            />
          )}
        </div>
      </div>
    );
  };

  const { token } =  useContext(AppContext) ?? {};
  const updateSettings = async (updateMask: boolean) => {
    let frequencies = bandFrequencies;
    let warnings = bandWarnings;
    let dangers = bandDangers;
    if (updateMask) {
      // Recalculate the mask for warning and danger if the settings changed
      const envelopeReq = await getEnvelope(
        0,
        1000,
        5,
        "nearest",
        measurementIds,
        dataType,
        token
      );
      const envelope = envelopeReq.envelope;
      frequencies = [];
      const baseLevel = [];
      let currVal = 0;
      for (let i = 0; i < envelope.length; i++) {
        const val = envelope[i].y;
        if (val !== currVal) {
          currVal = val;
          frequencies.push(envelope[i].x / 2);
          baseLevel.push(val);
        }
      }
      warnings = [...baseLevel].map((val: number, index: number) => {
        const amplifyPercent = (warningPercent - 100) / 100;
        const dropoff = 1 - (dropoffPercent / 100) * (index / baseLevel.length);
        return val + val * amplifyPercent * dropoff;
      });
      dangers = [...baseLevel].map((val: number, index: number) => {
        const amplifyPercent = (dangerPercent - 100) / 100;
        const dropoff = 1 - (dropoffPercent / 100) * (index / baseLevel.length);
        return val + val * amplifyPercent * dropoff;
      });
    }

    const newThreshold: FFTThreshold = {
      ...threshold,
      is_custom: thresholdType === "band",
      frequencies: frequencies,
      dangers: dangers,
      warnings: warnings,
      danger_percent: dangerPercent,
      warning_percent: warningPercent,
      dropoff_percent: dropoffPercent,
      notification_level: notificationLevel,
      measurements: measurementIds,
    };

    updateFFTThreshold(newThreshold).then(() => 
      getFFTThresholds(sensorId, dataType, axis).then((threshold) => {
        if (threshold.length > 0) {
          setThreshold(threshold[0]);
        }
      })
    ).catch(e => console.log(e));
  };

  return (
    <PopUp
      isOpen={thresholdSettingsOpen}
      title="FFT Threshold Settings"
      setIsOpen={(e: boolean) => setThresholdSettingsOpen(e)}
      closeFunction={() => updateSettings(false)}
    >
      <div style={{ maxWidth: 350 }}>
        <div className={styles.thresholdType}>
          <span>Threshold Type:</span>
          <div>
            <label>
              <input
                type="radio"
                name="thresholdType"
                value="mask"
                checked={thresholdType === "mask"}
                onChange={handleThresholdTypeChange}
              />
              Auto Mask
            </label>
            <label>
              <input
                type="radio"
                name="thresholdType"
                value="band"
                checked={thresholdType === "band"}
                onChange={handleThresholdTypeChange}
              />
              Manual Bands
            </label>
          </div>
        </div>
        <div>
          {thresholdType === "mask" ? (
            <>
              {/* Auto Mask Settings */}
              <p className={styles.bandDescription}>
                The auto mask will be calculated using a boxy envelope around
                the chosen sample(s) FFT. You can also add a threshold dropoff
                for higher frequencies.
              </p>
              <div>
                <b>Sample(s):</b>
                <div style={{ position: "relative", top: "0" }}>
                  <div
                    id="sampleDropdownParent"
                    style={{ position: "relative", top: "0" }}
                    className={styles.samplesDropdown}
                  >
                    <div style={{ display: "flex" }}>
                      {sampleDropdownOpen && (
                        <input
                          type="text"
                          id="sampleDropdown"
                          placeholder={"Search for a sample..."}
                          value={sampleSearch}
                          onChange={(e) => setSampleSearch(e.target.value)}
                          style={{ height: "25px", flexGrow: "1" }}
                        />
                      )}
                      {!sampleDropdownOpen && (
                        <div
                          onClick={() => setSampleDropdownOpen(!sampleDropdownOpen)}
                          className={styles.sampleLabel}
                        >
                          {measurementIds?.length === 1 && samples.some((sample) => measurementIds.includes(sample.id)) 
                            ? (samples.filter((sample) => measurementIds.includes(sample.id))[0].date) 
                            : measurementIds?.length === 0 ? (
                              <>No samples selected</>
                            ) : (<>Multiple samples selected</>)}
                        </div>
                      )}
                      <div
                        id="sampleDropdown"
                        onClick={() => setSampleDropdownOpen(!sampleDropdownOpen)}
                        className={styles.samplesDropdownBtn}
                      >
                        {sampleDropdownOpen && <>⏶</>}
                        {!sampleDropdownOpen && <>⏷</>}
                      </div>
                    </div>
                  </div>
                  {sampleDropdownOpen && (
                    <SampleDropdown
                      i={0}
                      measurement_ids={measurementIds}
                      sampleList={samples}
                      sampleSearch={sampleSearch}
                      setSamplesDropdownOpen={() => {}}
                      setSampleDropdownOpen={setSampleDropdownOpen}
                      updateMeasurementIds={setMeasurementIds}                      
                    />
                  )}
                </div>
              </div>
              <div className={styles.autoSection}>
                <button
                  className={styles.input}
                  onClick={() => updateSettings(true)}
                >
                  Re-Calculate Mask
                </button>
                <InfoTooltip
                  text={`
                  The new mask will be recalculated using the current settings
                  and the average of selected sample FFTs.
                  `}
                />
              </div>
              <div className={styles.autoSection}>
                <div className={styles.input}>
                  Warning (%):
                  <input
                    type="number"
                    min="100"
                    max="500"
                    step="1"
                    value={warningPercent}
                    onChange={(e) => {
                      const val = Number(e.target.value);
                      if (val < dangerPercent) {
                        setWarningPercent(val);
                      }
                    }}
                  />
                </div>
                <InfoTooltip
                  text={`
                  What percentage of the current amplitudes should be considered
                  a warning threshold.
                  `}
                />
              </div>
              <div className={styles.autoSection}>
                <div className={styles.input}>
                  Danger (%):
                  <input
                    type="number"
                    min="100"
                    max="999"
                    step="1"
                    value={dangerPercent}
                    onChange={(e) => {
                      const val = Number(e.target.value);
                      if (val > warningPercent) {
                        setDangerPercent(val);
                      }
                    }}
                  />
                </div>
                <InfoTooltip
                  text={`
                  What percentage of the current amplitudes should be considered
                  a danger threshold.
                  `}
                />
              </div>
              <div className={styles.autoSection}>
                <div className={styles.input}>
                  Dropoff (%):
                  <input
                    type="number"
                    min="0"
                    max="80"
                    step="1"
                    value={dropoffPercent}
                    onChange={(e) =>
                      setDropoffPercent(Number(e.target.value))
                    }
                  />
                </div>
                <InfoTooltip
                  text={`
                  How much should the warning and danger thresholds be reduced
                  at the maximum frequency relative to the minimum frequency.
                  `}
                />
              </div>
            </>
          ) : (
            <>
              {/* Manual Band Settings */}
              <p className={styles.bandDescription}>
                Set up band intervals and thresholds for up to 12 bands. Band
                frequencies are defined in Hz and thresholds correspond to the
                FFT amplitude.
              </p>
              <div className={styles.bandTableContainer}>
                <div className={`${styles.bandRow} ${styles.header}`}>
                  <div className={styles.deleteCol} />
                  <div className={styles.frequencyCol}>
                    <span>Bands (Hz)</span>
                  </div>
                  <div className={styles.warningCol}>
                    <span>Warning</span>
                  </div>
                  <div className={styles.dangerCol}>
                    <span>Danger</span>
                  </div>
                  <div className={styles.addCol} />
                </div>

                {bandRow("band_frequency", 0, {
                  frequency: bandFrequencies[0],
                })}
                {[...bandWarnings].map((_, index) => {
                  const warning = bandWarnings[index];
                  const danger = bandDangers[index];
                  const frequency = bandFrequencies[index + 1];
                  return (
                    <div key={index}>
                      {bandRow("threshold", index + 1, {
                        warning: warning,
                        danger: danger,
                      })}
                      {bandRow(`band_frequency`, index + 1, {
                        frequency: frequency,
                      })}
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className={styles.resetWrapper}>
            <div className={styles.notifications}>
              <label>Notifications: </label>
              <select 
                value={notificationLevel} 
                onChange={(e) => {
                  setNotificationLevel(parseInt(e.target.value));
                }}
              >
                <option value="0">None</option>
                <option value="1">Website</option>
                <option value="2">Email</option>
              </select>
            </div>
            {thresholdType === "band" && <div 
              className={styles.reset}
              onClick={() => {
                setBandFrequencies([0, Math.max(...bandFrequencies)])
                setBandWarnings([parseFloat((bandWarnings.reduce((acc, val) => acc + val, 0) / bandWarnings.length).toFixed(2))]);
                setBandDangers([parseFloat((bandDangers.reduce((acc, val) => acc + val, 0) / bandDangers.length).toFixed(2))]);
              }}
            >
              Reset
            </div>}
          </div>
        </div>
      </div>
    </PopUp>
  );
};

export default FFTThresholds;
