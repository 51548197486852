import styles from "../../../Styles/TrendsGraph.module.css";

// Recharts (import directly to reduce size)
import { LineChart } from "recharts/es6/chart/LineChart";
import { Line } from "recharts/es6/cartesian/Line";
import { XAxis } from "recharts/es6/cartesian/XAxis";
import { YAxis } from "recharts/es6/cartesian/YAxis";
import { Tooltip } from "recharts/es6/component/Tooltip";
import { ResponsiveContainer } from "recharts/es6/component/ResponsiveContainer";
import { ReferenceArea } from "recharts/es6/cartesian/ReferenceArea";
import { ReferenceLine } from "recharts/es6/cartesian/ReferenceLine";
import { Label } from "recharts/es6/component/Label";
import { CartesianGrid } from "recharts/es6/cartesian/CartesianGrid";

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import {
  LineProps,
  getDynamicDate,
  LineColors,
} from "../../../Utils/GraphUtils";

import { zoom, zoomOut } from "../../../Utils/GraphZoomUtils";
import SamplesDropdown from "./SamplesDropdown";
import GraphWrapper from "../GraphWrapper";
import CustomTooltip from "../CustomToolTip";
import { LegendDropdown, DropdownOption } from "../LegendDropdown";
import { Sample, TrendThreshold, TrendsData, MachineSettings } from "../HTTPRequests/Types";
import { getSensorTrendsData } from "../HTTPRequests/IndustryData";
import { getFolderTrendsData } from "../HTTPRequests/ResearchData";
import Spinner from "../../Spinner";
import TypeDropdown from "../TypeDropdown";
import ThresholdPopup from "../ThresholdPopup";
import { getTrendsThresholds } from "../HTTPRequests/Thresholds";
import { getThresholdName } from "../../../Utils/Utils";

const compareOptions = (type: "vibration" | "audio", axis: "x" | "y" | "z" = "x") => {
  const options = {
      "vibration": [
      { name: "Acceleration RMS", id: `acceleration_${axis}_rms` },
      { name: "Acceleration Kurtosis", id: `acceleration_${axis}_kurtosis` },
      { name: "Acceleration Peak to Peak", id: `acceleration_${axis}_p2p` },
      { name: "Velocity RMS", id: `velocity_${axis}_rms` },
      { name: "Velocity Kurtosis", id: `velocity_${axis}_kurtosis` },
      { name: "Velocity Peak to Peak", id: `velocity_${axis}_p2p` },
    ],
    "audio": [
      { name: "RMS", id: "audio_rms" },
      { name: "Kurtosis", id: "audio_kurtosis" },
      { name: "Peak to Peak", id: "audio_p2p" },
    ],
  }
  return options[type];  
};

export const ISO = (level: string, group: number, flexible: boolean): number => {
  if (level === "warning") {
    switch (group) {
      case 1:
        return flexible ? 7.1 : 4.5 ;
      case 2:
        return flexible ? 4.5 : 2.8;
    }
  } else {
    switch (group) {
      case 1:
        return flexible ? 11 : 7.1;
      case 2:
        return flexible ? 7.1 : 4.5;
    }
  }
  return 0;
};

interface Props {
  mobile: boolean;
  sensorId?: number; // for industry users
  folderPath?: string; // for research users
  measurementType: "vibration" | "audio";
  axis?: "x" | "y" | "z";
  openGraphManager: () => void;
  samples: Sample[];
  goToSample: (sample: Sample) => void;
  openSensorSettings?: () => void;
  machineSettings?: MachineSettings;
}

function resetMinMax(
  data: TrendsData[],
  setTop: Dispatch<SetStateAction<string | number>>, 
  setBottom: Dispatch<SetStateAction<string | number>>, 
) {
  let max = -Infinity;
  let min = Infinity;
  for (const t of data) {
    if (t.rms > max) {
      max = t.rms;
    }
    if (t.rms < min) {
      min = t.rms;
    }
    if (t.kurtosis > max) {
      max = t.kurtosis;
    }
    if (t.kurtosis < min) {
      min = t.kurtosis;
    }
    if (t.p2p > max) {
      max = t.p2p;
    }
    if (t.p2p < min) {
      min = t.p2p;
    }
  }
  const span = max - min;

  setTop(max + span * 0.05);
  setBottom(min - span * 0.05);
}

/*
 * This is the trends graph section
 * It is where stat trends (P2P, Kurtosis, RMS) from a sensor are displayed
 * And where users can navigate to view specific samples
 */
const TrendsGraph = ({
  mobile,
  sensorId,
  folderPath,
  measurementType, 
  axis,
  openGraphManager,
  samples,
  goToSample,
  openSensorSettings,
  machineSettings,
}: Props) => {

  const [loading, setLoading] = useState(true);
  
  // Zoom
  const [zoomSelected, setZoomSelected] = useState(false);
  const [left, setLeft] = useState<number | string>("dataMin");
  const [right, setRight] = useState<number | string>("dataMax");
  const [bottom, setBottom] = useState<number | string>("dataMin - 0.5");
  const [top, setTop] = useState<number | string>("dataMax + 0.5");
  const [refAreaLeft, setRefAreaLeft] = useState<number | string>("");
  const [refAreaRight, setRefAreaRight] = useState<number | string>("");

  // Threshold Settings
  const [thresholdSettingsOpen, setThresholdSettingsOpen] = useState(false);
  const [thresholds, setThresholds] = useState<TrendThreshold[]>([]);
  const [thresholdsToShow, setThresholdsToShow] = useState<boolean[]>([]);

  // History
  const [historyFrom, setHistoryFrom] = useState<number>();
  const [historyTo, setHistoryTo] = useState<number>();

  useEffect(() => {
    if (samples.length > 0) {
      setHistoryFrom(h => Math.min(new Date(samples[0].date).getTime() - 1000 * 60 * 60 * 24 * 7, h ?? Infinity));
      setHistoryTo(new Date(samples[0].date).getTime());
    } else {
      setHistoryFrom(Date.now() - 1000 * 60 * 60 * 24 * 7);
      setHistoryTo(Date.now());
    }
  }, [samples]);

  // Legend
  const [showRMS, setShowRMS] = useState(true);
  const [showKurtosis, setShowKurtosis] = useState(true);
  const [showPeakToPeak, setShowPeakToPeak] = useState(true);

  // Axis and data type
  const [dataType, setDataType] = useState<"acceleration" | "velocity" | "audio">(measurementType === "vibration" ? "acceleration" : "audio");
  
  const [data, setData] = useState<TrendsData[]>();

  const loadThreshold = () => {
    if (sensorId) {
      return getTrendsThresholds(sensorId).then((thresholds) => {
        setThresholds(thresholds.sort((a, b) => (a.compared_to + a.type) > (b.compared_to + b.type) ? 1 : -1));
        if (thresholds.length !== thresholdsToShow.length) {
          setThresholdsToShow(thresholds.map(() => false));
        }
      }).catch(e => console.log(e));
    }
  }

  useEffect(() => {
    setLoading(true);
    if (sensorId) {
      if (historyFrom !== undefined && historyTo !== undefined) {
        getSensorTrendsData(sensorId, measurementType, axis, dataType, historyFrom, historyTo).then((trendsData) => {
          setData(trendsData.map(t => ({
            ...t,
            date: new Date(t.date).getTime(),
          })));
          resetMinMax(trendsData, setTop, setBottom);
          setLoading(false);
        }).catch(e => console.log(e));
      }
      
      getTrendsThresholds(sensorId).then((thresholds) => {
        setThresholds(thresholds.sort((a, b) => (a.compared_to + a.type) > (b.compared_to + b.type) ? 1 : -1));
        setThresholdsToShow(thresholds.map(() => false));
      }).catch(e => console.log(e));
    } else if (folderPath) {
      getFolderTrendsData(folderPath, measurementType, axis, dataType).then((trendsData) => {
        setData(trendsData.map(t => ({
          ...t,
          date: new Date(t.date).getTime(),
        })));

        resetMinMax(trendsData, setTop, setBottom);
        setLoading(false);
      }).catch(e => console.log(e));
    }
  }, [sensorId, folderPath, measurementType, axis, dataType, historyFrom, historyTo]);
  
  const handleSampleSelect = (payload: any) => {
    if (!goToSample) return;
    const date = new Date(payload.date);
    goToSample(samples.find((s: Sample) => s.date === date.toISOString()) as Sample);
  };

  const ViewSampleButton = ({ cx, cy, payload }: any) => {
    const h = mobile ? 30 : 40;
    const w = mobile ? 100 : 120;
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={w}
        height={h}
        x={cx - w / 2}
        y={cy - h - 5}
        onMouseUp={() => handleSampleSelect(payload)}
        className={styles.sensorHover}
      >
        <rect width="100%" height="100%" rx={"3px"} />
        <text fill="black" y={h / 1.55} x={8} fontSize={mobile ? "0.9rem" : "1rem"}>
          View Sample
        </text>
      </svg>
    );
  };  

  const Graph = () => {
    // Sort by Time
    const sortedData = [...data!].sort((a: TrendsData, b: TrendsData) => {
      if (a.date < b.date) {
        return -1;
      }
      if (a.date > b.date) {
        return 1;
      }
      return 0;
    }).map((d: TrendsData) => {
      if (typeof top !== "number" || typeof bottom !== "number") {
        return d
      }
      return {
        ...d,
        "REF": (top + bottom) / 2,
      };
    });

    return (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={sortedData}
          onMouseDown={(e: any) => {
            if (e === null || !zoomSelected) return;
            setRefAreaLeft(e.activeLabel);
          }}
          onMouseMove={(e: any) => {
            if (zoomSelected)
              refAreaLeft && setRefAreaRight(e.activeLabel);
          }}
          onMouseUp={() => {
            if (zoomSelected)
              zoom(
                refAreaLeft,
                refAreaRight,
                setRefAreaLeft,
                setRefAreaRight,
                setLeft,
                setRight,
                setTop,
                setBottom,
                0.05,
                sortedData.map((p: TrendsData) => {
                    return [
                      {
                        x: p.date,
                        y: p.rms,
                      },
                      {
                        x: p.date,
                        y: p.kurtosis,
                      },
                      {
                        x: p.date,
                        y: p.p2p,
                      },
                    ];
                  })
                  .reduce((acc: any, curr: any) => {
                    return acc.concat(curr);
                  }, [])
              );
          }}
          margin={{
            bottom: mobile ? 20 : 28,
            right: 20,
            left: mobile ? -10 : 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            allowDataOverflow
            dataKey="date"
            type="number"
            tickFormatter={(date: any) =>
              getDynamicDate(date, left, right, data)
            }
            domain={[left, right]}
          >
            <Label
              fontSize={mobile ? 13 : 16}
              value="Samples over Time"
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            type="number"
            domain={[bottom, top]}
            allowDataOverflow={true}
            allowDecimals={true}
            tickFormatter={(value: any) => value.toFixed(2)}
          />
          <Tooltip content={<CustomTooltip />} position={{ x: 60, y: 0 }} />

          {/* The rectangle shown on zoom */}
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}

          {/*---------------------- LINES -----------------------*/}

          {/* Stats */}
          {showRMS && (
            <Line
              {...LineProps(sortedData)}
              stroke={LineColors["RMS"]}
              dataKey={"rms"}
            />
          )}
          {showPeakToPeak && (
            <Line
              {...LineProps(sortedData)}
              stroke={LineColors["P2P"]}
              dataKey={"p2p"}
            />
          )}
          {showKurtosis && (
            <Line
              {...LineProps(sortedData)}
              stroke={LineColors["Kurtosis"]}
              dataKey={"kurtosis"}
            />
          )}

          {/* Thresholds */}
          {sensorId && thresholds.map((threshold, i) => {
            return thresholdsToShow[i] ? (
              <ReferenceLine
                key={i}
                stroke={threshold.color}
                y={threshold.is_iso && machineSettings ? ISO(threshold.type, machineSettings.iso_group, machineSettings.iso_flexible) : threshold.value}
                ifOverflow="extendDomain"
                strokeWidth={7}
                opacity={0.75}
                strokeDasharray="10 5"
              />
            ) : null;
          })}

          {/* This hidden line is used for the "View Sample" button */}
          <Line
            type="monotone"
            dataKey="REF"
            strokeWidth={0}
            dot={{ r: 0 }}
            activeDot={<ViewSampleButton />}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  const titleChildren = (
    <>
      {/* Settings for the Sensor/Machine */}
      {openSensorSettings && <div
        className={`${styles.headerButton} ${styles.sensorSettings}`}
        onClick={() => openSensorSettings()}
      />}

      {/* Access the graph manager */}
      <div
        className={`${styles.headerButton} ${styles.graphManager}`}
        onClick={() => openGraphManager()}
      />

      <SamplesDropdown samples={samples} mobile={false} goToSample={goToSample} />
    </>
  );

  return (
    <>
      {sensorId && <ThresholdPopup 
        isOpen={thresholdSettingsOpen} 
        setIsOpen={setThresholdSettingsOpen} 
        sensorId={sensorId}
        thresholds={thresholds} 
        setThresholds={setThresholds}
        loadThreshold={loadThreshold} 
        compareOptions={compareOptions(measurementType, axis)} 
      />}
      <GraphWrapper 
        title={"Trends"}
        mobile={mobile}
        data={data}
        helpPage="trends_view"
        legendItems={[
          ...thresholds.map((threshold, i) => {
            return thresholdsToShow[i] ? {
              name: `${getThresholdName(threshold)}`, 
              color: threshold.color
            } : "";
          }),
        ]}
        titleChildren={titleChildren}
        mobileTitleChildren={
          <>
            {/* Settings for the Sensor/Machine */}
            {openSensorSettings && <div
              className={styles.mobileTitleButton}
              onClick={() => openSensorSettings()}
            >
              <div className={styles.sensorSettings} />
              Machine Settings
            </div>}

            {/* Access the graph manager */}
            <div
              className={styles.mobileTitleButton}
              onClick={() => openGraphManager()}
            >
              <div className={styles.graphManager} />
              FFT Graph Manager
            </div>

            <SamplesDropdown samples={samples} mobile={true} goToSample={goToSample} />

            {measurementType === "vibration" && <TypeDropdown type={dataType} setType={setDataType} mobile={true} />}
          </>
        }
        headerChildren={
          measurementType === "vibration" && <TypeDropdown type={dataType} setType={setDataType} />
        }
        options={
          <>
            <div className={styles.legendCheckbox}>
              <input
                type="checkbox"
                id="legend-rms"
                defaultChecked={showRMS}
                onChange={e => setShowRMS(e.target.checked)}
              />
              <label
                style={{ color: LineColors["RMS"] }}
                htmlFor="legend-rms"
              >
                RMS
              </label>
            </div>
            <div className={styles.legendCheckbox}>
              <input
                type="checkbox"
                id="legend-kurtosis"
                defaultChecked={showKurtosis}
                onChange={e => setShowKurtosis(e.target.checked)}
              />
              <label
                style={{ color: LineColors["Kurtosis"] }}
                htmlFor="legend-kurtosis"
              >
                Kurtosis
              </label>
            </div>
            <div className={styles.legendCheckbox}>
              <input
                type="checkbox"
                id="legend-p2p"
                defaultChecked={showPeakToPeak}
                onChange={e => setShowPeakToPeak(e.target.checked)}
              />
              <label
                style={{ color: LineColors["P2P"] }}
                htmlFor="legend-p2p"
              >
                {mobile ? "P2P" : "Peak to Peak"}
              </label>
            </div>
            {sensorId && <LegendDropdown name="Thresholds">
              <>
                <div
                  onClick={() => setThresholdSettingsOpen(!thresholdSettingsOpen)}
                  className={styles.thresholdSettingsBtn}
                >
                  <div />
                  Settings
                </div>
                
                {thresholds.filter(t => {
                  const parts = t.compared_to.split("_");
                  return dataType === parts[0] && (!axis || axis === parts[1]);
                }).length ? thresholds.map((threshold, i) => {
                  if (dataType !== threshold.compared_to.split("_")[0] || (axis && axis !== threshold.compared_to.split("_")[1])) {
                    return <></>;
                  }
                  return (
                    <DropdownOption 
                      title={getThresholdName(threshold)} 
                      color={threshold.color}
                      state={thresholdsToShow[i]} 
                      setState={() => setThresholdsToShow(thresholdsToShow.map((s, j) => i === j ? !s : s))} 
                      key={i} 
                    />
                  );
                }) : <DropdownOption title="No Thresholds" />}
              </>
            </LegendDropdown>}

            {sensorId && <LegendDropdown name="History">
              <>
                <div className={styles.historyDropdownOption}>
                  From: 
                  <input 
                    type="datetime-local" 
                    value={new Date((historyFrom ?? Date.now()) - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0, 16)}
                    onChange={e => setHistoryFrom(new Date(e.target.value).getTime())}
                  />
                </div>
                <div className={styles.historyDropdownOption}>
                  To: 
                  <input 
                    type="datetime-local"
                    value={new Date((historyTo ?? Date.now()) - new Date().getTimezoneOffset()*60*1000).toISOString().slice(0, 16)}
                    onChange={e => setHistoryTo(new Date(e.target.value).getTime())}
                  />
                </div>
              </>
            </LegendDropdown>}
          </>
        }
        graph={
          loading ? <div style={{ marginBlock: "auto" }}><Spinner /></div> :
            data && data?.length > 0 ? Graph() : 
            <div style={{ marginBlock: "auto", textAlign: "center" }}>
              No Trends Data to Show
              {sensorId && <>
                <br /><br />
                Try changing the trends history range
              </>}
            </div>}
        zoomSelected={zoomSelected}
        setZoomSelected={setZoomSelected}
        zoomOut={() => zoomOut(
          setRefAreaLeft,
          setRefAreaRight,
          setLeft,
          setRight,
          () => resetMinMax(data ?? [], setTop, setBottom),
        )}
      />
    </>
  );
};

export default TrendsGraph;