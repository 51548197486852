import { useEffect, useState, useRef, ReactNode, useContext } from "react";
import styles from "../../Styles/Menu.module.css";
import "react-dyn-tabs/style/react-dyn-tabs.css";
import "react-dyn-tabs/themes/react-dyn-tabs-bootstrap.css";
import UserSettings from "./UserSettings";
import { User } from "@auth0/auth0-react";
import { AppContext } from "../App";

interface Props {
  TabList: ReactNode;
  PanelList: ReactNode;
  logout: () => void;
  user: User | undefined;
}

/*
 * This is the top (main) menu which contains the analysis tabs and the user settings dropdown button.
 */
const Menu = ({
  user,
  logout,
  TabList,
  PanelList,
}: Props) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  const [tabsMobileOpen, setTabsMobileOpen] = useState<boolean>(false);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<boolean>(dropdown);
  const dropdownDiv = useRef<HTMLDivElement>(null);
  const img = useRef<HTMLImageElement>(null);
  useEffect(() => {
    dropdownRef.current = dropdown;
  }, [dropdown]);

  const onClick = (e: any) => {
    // Close settings panel if clicked outside detected
    if (
      dropdownRef.current &&
      dropdownDiv.current &&
      !dropdownDiv.current.contains(e.target) &&
      ((img.current && !img.current.contains(e.target)) || !img.current)
    ) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, []);

  return (
    <>
      {tabsMobileOpen && (
        <div className={styles.mobileTabsContainer}>
          <div className={styles.tabsHeader}>
            <div className={styles.tabsHeaderLogo}></div>
            <span>Analysis Tabs</span>
            <div
              onClick={() => {
                setTabsMobileOpen(false);
              }}
              className={styles.exitMobileTabs}
            ></div>
          </div>
          <div
            id="mobileTabs"
            onClick={(e: any) => {
              if (!e.target.classList.contains("rc-dyn-tabs-close")) {
                setTabsMobileOpen(false);
              }
            }}
            className={styles.tabsContent}
          >
            {((tabInstance.getData().openTabIDs.length === 1 &&
              localStorage.getItem("user_type") === "industry") ||
              (tabInstance.getData().openTabIDs.length === 2 &&
                localStorage.getItem("user_type") === "researcher")) && (
              <div className={styles.analysisTabsNone}>
                No analysis tabs open.
              </div>
            )}
            {TabList}
          </div>
        </div>
      )}
      <>
        <div className={styles.container}>
          {/* Logo */}
          <div
            className={styles.logoSection}
            onClick={() => {
              if (localStorage.getItem("user_type") === "industry")
                tabInstance.select("devicesTab");
              else if (localStorage.getItem("user_type") === "researcher")
                tabInstance.select("samplesTab");
            }}
          >
            <div className={styles.logo} />
            <span>openPHM</span>
          </div>
          {/* Tabs */}
          <div className={styles.mobileTabs}>
            <div
              onClick={() => {
                setTabsMobileOpen(true);
              }}
              className={`${styles.analysisContainer}`}
            >
              <div className={`${styles.analysisTabs}`} />
              Tabs
            </div>

            {localStorage.getItem("user_type") === "industry" ? (
              <div
                onClick={() => {
                  tabInstance.select("devicesTab");
                }}
                className={`fa fa-toolbox ${styles.devicesTabs}`}
              />
            ) : (
              <>
                <div
                  onClick={() => {
                    tabInstance.select("searchTab");
                  }}
                  className={`fa fa-search ${styles.devicesTabs}`}
                />
                <div
                  onClick={() => {
                    tabInstance.select("samplesTab");
                  }}
                  className={`fa fa-wave-square ${styles.devicesTabs}`}
                />
              </>
            )}
          </div>

          <div className={styles.tabsSection}>{TabList}</div>
          {/* Profile / Settings & Help Menu */}
          <div
            ref={img}
            onClick={() => setDropdown(!dropdown)}
            className={styles.profileSection}
          >
            <img
              alt="ProfilePhoto"
              className={styles.profileImage}
              src={user?.picture}
              referrerPolicy="no-referrer"
            />
            <i className={`fa-solid fa-angle-down`} />
          </div>
        </div>
        {dropdown && (
          <div ref={dropdownDiv} className={styles.dropdown}>
            <UserSettings
              closeDropdown={() => setDropdown(false)}
              
              logout={logout}
              user={user}
            />
          </div>
        )}
      </>
      {PanelList}
    </>
  );
};

export default Menu;
