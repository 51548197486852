import { FFTThreshold, TrendThreshold } from "./Types";

export async function getTrendsThresholds(sensorId: number): 
Promise<TrendThreshold[]> {
  const response = await fetch(`/trends_thresholds/${sensorId}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function deleteTrendsThreshold(id: number): Promise<void> {
  const response = await fetch(`/trends_threshold/${id}`, { method: "DELETE" });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function updateTrendsThreshold(threshold: TrendThreshold):
Promise<void> {
  const response = await fetch("/update_trends_threshold", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(threshold),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function addTrendsThreshold(
  sensor_id: number,
  type: "warning" | "danger",
  compared_to: string,
  is_iso: boolean,
  notification_level: number,
  color: string,
  value?: number,
): Promise<void> {
  const response = await fetch("/add_trends_threshold", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      sensor_id,
      type,
      compared_to,
      value,
      is_iso,
      notification_level,
      color
    }),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}

export async function getFFTThresholds(sensorId: number, type: "velocity" | "acceleration" | "audio", axis: "x" | "y" | "z" = "x"): 
Promise<FFTThreshold[]> {
  const response = await fetch(`/fft_thresholds/${sensorId}/${type}/${axis}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function updateFFTThreshold(threshold: FFTThreshold):
Promise<void> {
  const response = await fetch("/update_fft_threshold", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(threshold),
  });
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
