import HelpContent from "../../HelpContent";

const TheSampleManager = () => {
  return (
    <>
      <p>{HelpContent["The Sample Manager"].intro}</p>
      <p>{HelpContent["The Sample Manager"].organization}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["The Sample Manager"].readme,
        }}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["The Sample Manager"].save,
        }}
      />
      <p>{HelpContent["The Sample Manager"].analysis}</p>
    </>
  );
};

export default TheSampleManager;
