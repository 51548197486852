import { useEffect } from "react";

// Detect clicks outside of dropdowns
export const useOutsideDetect = (
  dropdownRef: React.MutableRefObject<any>,
  dropdownBtnRef: React.MutableRefObject<any>,
  func: () => any
): void => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        dropdownBtnRef.current &&
        !dropdownBtnRef.current.contains(event.target)
      ) {
        func();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, dropdownBtnRef, func]);
};
