import { FileError } from "../../../Utils/FileUtils";

interface Props {
  fileError: FileError | null;
}

/**
 * PopUp used to display errors when managing files
 * Props:
 * - fileError: FileError object containing all the relevant information about the error
 */
const FileErrorModal = ({ fileError }: Props) => {
  return (
    <>
      {fileError ? (
        <div style={{ width: "350px" }}>
          {fileError.errorType === "SameName" && (
            <>
              <p>
                There is already another file in this directory with the name '
                {fileError.fileName}'.
              </p>
              <p>
                Please choose another name for the {fileError.fileType} and try
                again.
              </p>
            </>
          )}
          {fileError.errorType === "InvalidMove" && (
            <>
              <p>Files cannot be moved into Gateways.</p>
              <p>
                Gateways can only contain Sensors which are added automatically
                when new data is sent.
              </p>
            </>
          )}
          {fileError.errorType === "InvalidName" && (
            <>
              <p>
                {fileError.fileType} name cannot be empty or contain '/', '\', or '#'.
              </p>
              <p>Please pick a new name and try again.</p>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default FileErrorModal;
