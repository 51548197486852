import { Dispatch, SetStateAction, useRef } from "react";
import PopUp from "../PopUp";
import styles from "../../Styles/ZoomPopUp.module.css";

/*
 * When a user first selects the zoom tool, this pop up will appear
 * It is there to clarify to the user how to use the zoom tool (click and drag)
 * Since it may not be immediately obvious
 */
const ZoomPopUp = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen:
    | Dispatch<SetStateAction<boolean>>
    | ((b: boolean) => void);
}) => {
  const dontShowCheckboxRef = useRef<HTMLInputElement>(null);

  return (
    <PopUp
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Zoom"
      closeFunction={() => {
        if (dontShowCheckboxRef.current?.checked)
          localStorage.setItem("showZoomPopUp", "false");
      }}
    >
      When the zoom in tool is selected,
      <br />
      click and drag on the graph to zoom in the selected portion.
      <div className={styles.dontShowAgain}>
        Don't show again
        <input type="checkbox" ref={dontShowCheckboxRef} />
      </div>
    </PopUp>
  );
};

export default ZoomPopUp;
