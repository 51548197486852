import ReactJson from "react-json-view";
import PageContents from "../../PageContents";
import Section from "../../Section";
import HelpContent from "../../HelpContent";

const diffDate = () => {
  const currentDate = new Date(); // Get current date and time
  const hoursToAdd = 1;
  const minutesToAdd = 15;
  const secondsToAdd = 10;

  // Calculate the new date by adding the desired time
  currentDate.setHours(currentDate.getHours() + hoursToAdd);
  currentDate.setMinutes(currentDate.getMinutes() + minutesToAdd);
  currentDate.setSeconds(currentDate.getSeconds() + secondsToAdd);

  return currentDate.toISOString();
};

const DataJSONFormat = () => {
  return (
    <>
      <Section
        title={"Overview"}
        children={
          <>
            <div>
              <div>
                <p
                  style={{ float: "left", width: "70%" }}
                  dangerouslySetInnerHTML={{
                    __html: HelpContent["Data JSON Format"].intro,
                  }}
                />
                <PageContents
                  sections={[
                    "Top Level Fields",
                    "Vibration Measurement",
                    "Audio Measurement",
                    "Temperature Measurement",
                    "Battery Measurement",
                    "Full JSON Example",
                  ]}
                />
              </div>
            </div>
          </>
        }
      />

      <Section
        title="Top Level Fields"
        children={
          <>
            <div>
              <div>
                <p>
                  All data sent to openPHM must follow the JSON format shown
                  below (field order does not matter):
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      gateway_id: "Gateway123",
                      gateway_password: "AStrongPassword!",
                      measurements: [],
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].topLevelFields,
                    }}
                  />
                </p>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].measurementsIntro,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      sensor_id: "TempSensor",
                      time: new Date().toISOString(),
                      raw_data: [23.46],
                      measurement_type: "temperature",
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        HelpContent["Data JSON Format"].measurementsIntro2,
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title={"Vibration Measurement"}
        children={
          <>
            <div>
              <div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].vib,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      sensor_id: "VibrationSensor",
                      time: new Date().toISOString(),
                      raw_data: [10.21, 3.14, 511.6],
                      measurement_type: "vibration",
                      sampling_frequency: 3500,
                      axis: "y",
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title={"Audio Measurement"}
        children={
          <>
            <div>
              <div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].audio,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      sensor_id: "AudioSensor",
                      time: new Date().toISOString(),
                      raw_data: [10.21, 3.14, 511.6],
                      measurement_type: "audio",
                      sampling_frequency: 44100,
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title={"Temperature Measurement"}
        children={
          <>
            <div>
              <div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].temp,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      sensor_id: "TempSensor",
                      time: new Date().toISOString(),
                      raw_data: [23.46],
                      measurement_type: "temperature",
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title={"Battery Measurement"}
        children={
          <>
            <div>
              <div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].battery,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      sensor_id: "BatterySensor",
                      time: new Date().toISOString(),
                      raw_data: [3141],
                      measurement_type: "battery",
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title={"Full JSON Example"}
        children={
          <>
            <div>
              <div>
                <p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["Data JSON Format"].fullJSON,
                    }}
                  />
                  <ReactJson
                    style={{
                      margin: "1.5em 0",
                    }}
                    name={false}
                    src={{
                      gateway_id: "Gateway123",
                      gateway_password: "AStrongPassword!",
                      measurements: [
                        {
                          sensor_id: "LargeMotor",
                          time: new Date().toISOString(),
                          raw_data: [10.21, 3.14, 511.6],
                          measurement_type: "vibration",
                          sampling_frequency: 3500,
                          axis: "x",
                        },
                        {
                          sensor_id: "LargeMotor",
                          time: new Date().toISOString(),
                          raw_data: [10.21, 3.14, 511.6],
                          measurement_type: "vibration",
                          sampling_frequency: 3500,
                          axis: "y",
                        },
                        {
                          sensor_id: "LargeMotor",
                          time: new Date().toISOString(),
                          raw_data: [10.21, 3.14, 511.6],
                          measurement_type: "vibration",
                          sampling_frequency: 3500,
                          axis: "z",
                        },
                        {
                          sensor_id: "LargeMotor",
                          time: new Date().toISOString(),
                          raw_data: [32.46],
                          measurement_type: "temperature",
                        },
                        {
                          sensor_id: "NoisyMachine",
                          time: diffDate(),
                          raw_data: [3141],
                          measurement_type: "battery",
                        },
                        {
                          sensor_id: "NoisyMachine",
                          time: diffDate(),
                          raw_data: [10.21, 3.14, 511.6],
                          measurement_type: "audio",
                          sampling_frequency: 44100,
                        },
                      ],
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default DataJSONFormat;
