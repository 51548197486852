import { ReactNode } from "react";
import styles from "../../Styles/HelpSection.module.css";

/*
 * This is a section of the help page
 * It has a title and styling for sub components
 * It is used in the help pages
 */
const Section = ({
  title,
  children,
}: {
  title?: string;
  children: ReactNode | null;
}) => {
  return (
    <div
      className={styles.container}
      style={{ paddingTop: title ? "1em" : "0px" }}
      id={title}
    >
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Section;
