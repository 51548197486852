import HelpContent from "../../HelpContent";

const IndustryOverview = () => {
  return (
    <>
      <p>{HelpContent["Industry Tutorial Overview"].intro}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["Industry Tutorial Overview"].details,
        }}
      />
      <p>
        <h3>Tutorial Sections:</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: HelpContent["Industry Tutorial Overview"].toc,
          }}
        />
      </p>
    </>
  );
};

export default IndustryOverview;
