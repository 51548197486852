/*
 * This is all the text content (including inline HTML) for the help pages
 * The object key (such as "Industry Tutorial Overview") is the title of the page
 * and each sub item is a section of the page with it's content
 * This is used to insert into the page layout which can be found in the "Articles" folder
 * The content in this file can then be used for content search using the documentation search bar
 */
const content: any = {
  "Data Download API": {
    intro: `The openPHM data download API allows you to download measurements 
    from the sample manager in JSON format (see the format below). This API is designed
    to be used by researchers to access the data collected by industry without
    the need to manually download the data from the website.`,
    apiSecrets: `The API requires a secret key to be sent in the request authorization header
    with the scheme "openPHMSecretKey". This secret key can be generated in the settings popup
    of a group. Once generated, the user won't be able to see it again so it is important to
    save it somewhere safe. It is possible to regenerate or delete a key if needed.`,
    apiRoutes: `The API has one route which is <pre>https://openphm.org/get_data?file_path=&categories=</pre>
    The <pre>file_path</pre> is the path to the file or the folder you want to download. This path should include
    the group with its id: <pre>group_name#group_id</pre>. The <pre>categories</pre> is a comma seperated list of the
    data type you want to download. This parameter is optional and you will get every category in the response if it is
    not specified. Here is a list of the possible categories:
    <ul style="margin-top: 1rem">
    <li>Sample ID: <pre>id</pre></li>
    <li>File Path: <pre>file_path</pre></li>
    <li>Timestamp: <pre>timestamp</pre></li>
    <li>Sample Type: <pre>type</pre></li>
    <li>Axis: <pre>axis</pre></li>
    <li>Temperature: <pre>temperature</pre></li>
    <li>Sampling Frequency: <pre>sampling_frequency</pre></li>
    <li>Tags: <pre>tags</pre></li>
    <li>Vibration Raw Data (acceleration): <pre>vibration_raw</pre></li>
    <li>Audio Raw Data: <pre>audio_raw</pre></li>
    <li>Acceleration FFT: <pre>acceleration_fft</pre></li>
    <li>Velocity FFT: <pre>velocity_fft</pre></li>
    <li>Audio FFT: <pre>audio_fft</pre></li>
    <li>Acceleration RMS: <pre>acceleration_rms</pre></li>
    <li>Velocity RMS: <pre>velocity_rms</pre></li>
    <li>Audio RMS: <pre>audio_rms</pre></li>
    <li>Acceleration Kurtosis: <pre>acceleration_krutosis</pre></li>
    <li>Velocity Kurtosis: <pre>velocity_kurtosis</pre></li>
    <li>Audio Kurtosis: <pre>audio_kurtosis</pre></li>
    <li>Acceleration P2P: <pre>acceleration_p2p</pre></li>
    <li>Velocity P2P: <pre>velocity_p2p</pre></li>
    <li>Audio P2P: <pre>audio_p2p</pre></li>
    </ul>`,
    dataFormat: `The data route returns an array of JSON object with fields corresponding to the categories. Here is an example of a response:`,
    requestFormat: `Here is an example of a request using the JavaScript fetch API:`,
  },
  "FFT Analysis": {
    intro: `
    FFT Analysis (or Spectrum Analysis) is done by taking the Fast
    Fourier Transform (FFT) of a digital signal. The FFT is a
    mathematical algorithm that takes a signal in the time domain
    and converts it to the frequency domain. The frequency domain is
    a representation of the signal in terms of its frequency
    components. This view can help identify the main frequencies of
    a vibration or audio signal, which can be used to identify
    specific problems associated with certain frequencies. OpenPHM
    provides the ability to compare multiple FFTs with a FFT graph
    layer manager as well as visually identify frequencies related
    faults and create alerts using automatic and manual mask
    thresholds.
    `,
    layers: `
    The FFT Graph View is controlled by the FFT Layers Manager. Each
    FFT graph is a "layer" which represents an FFT for a specific
    sample (or an averaged combination of samples). Each graph layer
    has various customizable properties, including choosing the
    sample(s) to use, the color of the graph, and the line width.
    Each layer can also be of type "FFT" or "Envelope" where "FFT"
    represents the raw transform, and "Envelope" represents a
    smoother version of the FFT. The envelope option can be further
    customized with different resolution and interpolation types.
    The buttons in the top right of the manager can be used to
    access a list of all layer options as well as toggle the layer
    manager being full screen.
    `,
    thresh1: `
    Like the <a href="/help/trends_view">Trends View</a>, the FFT
    Analysis View has warning and danger thresholds that can be set.
    When these thresholds are surpassed the system can be configured
    to send notifications. The FFT thresholds can either be set
    using an auto mask method based on a specified FFT or using a
    manual bands method.
    `,
    thresh2: `
    Below you can see what an automatically generated mask looks
    like when visualized. The threshold visualization can be
    activated via the "Threshold" dropdown above the graph.
    `,
    threshAuto: `
    The auto mask method works by multiplying the amplitudes of an
    existing FFT. Select the sample (or multi-sample average) you
    wish to use and a mask will be generated by multiplying the
    amplitudes by the specified warning and danger amounts. For
    example a warning value of 115% will mean the FFT of new sample
    must surpass 115% the amplitude of the selected FFT to send a
    warning notification. There is also a "Dropoff" value which
    decreases the thresholds linearly to the set value at the max
    frequency. For example a dropoff value of 80% would mean the
    highest frequencies must only exceed 100% + (0.8 * 15%) = 112%
    of the set FFT value to trigger a warning notification. A value
    of 100% means no dropoff. This dropoff is used since higher
    frequencies tend to have lower amplitude variability.
    `,
    threshManual: `
    The manual thresholds are created by setting specific warning
    and danger values for each specified frequency band. This gives
    more customizability and fine grain control over the threshold.
    Each band has a start frequency and end frequency and bands can
    be added or removed using the "+" and "x"" buttons. The manual
    threshold requires at least on frequency band to be functional.
    `,
    faultAnalysis: `
    The Fault Analysis menu allows you to overlay fault analysis
    lines to see if FFT peaks line up with any known fault
    frequencies. In order for this analysis to work the values in 
    <a href="/help/machine_settings">Machine Settings</a> must be
    accurate since these frequencies are calculated based on the
    machine parameters. The Fault Analysis menu can be accessed by
    the dropdown available over the FFT graph. This menu is split
    into sections based on fault type and some sections may be
    disabled based on the activated/disactivated machine settings
    (for example machines without gears will not allow for gear
    fault analysis). The number of lines can be modified using the
    "Harmonics" input which will show the specified number of
    harmonics of the fault frequency. When a fault frequency is
    activated it will appear on the graph with vertical lines.
    <br /><br />
    The different fault types are:
    <ul>
    <li>Fundamental Train Frequency (FTF)</li>
    <li>Ball Spin Frequency (BSF)</li>
    <li>Ball Pass Frequency of Inner Race (BPFI)</li>
    <li>Ball Pass Frequency of Outer Race (BPFO)</li>
    <li>Shaft/Motor Frequency</li>
    <li>Pole Pass Frequency (PPF)</li>
    <li>Slip Frequency (SF)</li>
    <li>Rotor Bar Passing Frequency (RBF)</li>
    <li>Fan Blade Frequency (FBD)</li>
    <li>Gear Mesh Frequency (GMF)</li>
    <li>Hunting Tooth Frequency (HTF)</li>
    <li>Gear Assembly Phase Frequency (GAPF)</li>
    <li>Dynamic Imbalance Frequency (DIF)</li>
    <li>Static Imbalance Frequency (SIF)</li>
    <li>Couple Imbalance Frequency (CIF)</li>
    <li>Overhung Machine Frequency (OMF)</li>
    </ul>
    `,
  },
  "Analysis Overview": {
    main: `
    <p>
    The Data Analysis documentation covers the various views openPHM
    provides for machine data analysis. Data analysis is accessible by
    clicking the "Analysis" button for a sensor in the Device Manager which
    will open an analysis tab for that sensor. Each analysis tab is made of
    two main adjustable views, which by default are the 
    <a href="/help/trends_view">Trends View</a> and the 
    <a href="/help/fft_analysis">FFT Analysis View</a>. These views allow
    you to analyze the data in different ways, and see trends over the long
    term as well as dive into the details of specific measurements. Get
    started learning about data anlysis with openPHM using the links below.
    </p>
    <h3>Analysis Sections:</h3>
    <ul>
    <li>
      <a href="/help/trends_view">Analyzing Trends</a>
    </li>
    <li>
      <a href="/help/sample_view">Analyzing Individual Samples</a>
    </li>
    <li>
      <a href="/help/fft_analysis">FFT Analysis</a>
    </li>
    <li>
    <a href="/help/machine_settings">Adjusting Machine Settings</a>
    </li>
    <li>
      <a href="/help/exporting_data">Exporting Data</a>
    </li>
    </ul>`,
  },
  "Machine Settings": {
    main: `The Machine Settings View is accessible by clicking the gear icon in the 
    <a href="/help/trends_view">Trends View</a>. This is where settings
    about the machine can be adjusted. These settings must be accurate in
    order to get accurate results from FFT harmonics analysis since the
    frequencies for various fault types are calculated based on the machine
    parameters. Disabling sections of the Machine Settings View will disable the
    corresponding FFT harmonics in the <a href="/help/fft_analysis">FFT Analysis View</a>.
    Below is an example Machine Settings View with all sections activated and filled in 
    as well as more info on the various settings sections.`,
    rollingElems: `
    <p>This section relates to rolling machine parts such as ball bearings.</p>
    <p>
    <b># of rolling elements: </b>
    The number of rolling elements in the machine.
    </p>
    <p>
    <b>Angle of contact (deg): </b>
    The offset from 0 degrees of the rolling element contacting the casing.
    </p>
    <p>
    <b>Pitch diameter: </b>
    The distance between the centre of two rolling elements across eachother on a shaft in cm.
    </p>
    <p>
    <b>Rolling element diameter: </b>
    The diameter of the rolling elements in the machine in cm.
    </p>
    `,
    motor: `
    <p>This section relates to the motor in the machine.</p>
    <p>
    <b>Motor speed (RPM): </b>
    The speed of the motor in revolutions per minute.
    </p>
    <p>
    <b># of poles: </b>
    The number of magnetic poles in the motor.
    </p>
    <p>
    <b># of rotors: </b>
    The number of rotors in the motor. The rotor is the rotating part of the motor.
    </p>
    <p>
    <b># of fan blades: </b>
    The number of fan blades in the motor. This only applies to motors with rotating fans.
    </p>
    `,
    gears: `
    <p>This section relates to gears in the machine.</p>
    <p>
    <b># of teeth: </b>
    The total number of teeth on the gears in the machine.
    </p>
    <p>
    <b># of teeth (in): </b>
    The number of teeth on the input gear of the machine also known as the pinion.
    </p>
    <p>
    <b># of teeth (out): </b>
    The number of teeth on the output gear of the machine.
    </p>
    <p>
    <b># of assembly phases: </b>
    The number of assembly phases in the machine.
    </p>
    `,
  },
  "Trends View": {
    intro: `The Trends view for audio and vibration data shows the change in RMS,
    Kurtosis, and Peak to Peak values of samples over time. This view can be
    used to quickly identify when a machine is on or off, as well as other
    significant changes in the machine's condition. The Trends view is also
    used as a way to set acceleration and velocity amplitude thresholds and
    to access individual samples via the "Samples" dropdown or by clicking a
    data point on the Trends graph.`,
    thresholds: `
    Using the "Thresholds" dropdown users can view and edit the
    thresholds for the sensor being analyzed.
    These thresholds can send notifications to the user when the are
    surpassed to ensure faults are detected and dealt with early. 
    Below you can see the threshold settings accesible
    via the "Thresholds" dropdown. For the velocity RMS, there are
    ISO standard tresholds (<a href="https://www.iso.org/standard/78311.html" target="blank_">ISO 20816-3</a>). These can be activated by settings the 
    ISO Category Settings in the <a href="/help/machine_settings">Machine Settings</a> page.`,
  },
  "Exporting Data": {
    overview: `The analysis page lets users save graph data and raw data in several formats: 
    PNG, SVG, CSV, and JSON. These export options can be accessed by clicking the 
    download button in any Analysis View which has a graph.`,
    main: `<p>
    </p>
    <p>
      <b>PNG:</b> Saves the current graph as a PNG image.
    </p>
    <p>
      <b>SVG:</b> Saves the current graph as a Scalable Vector Graphics
      (SVG) type. This can be rendered cleanly at any size and is easy to
      add to websites.
    </p>
    <p>
      <b>CSV:</b> Saves the graph data as a table of Time and Amplitude.
      Comma-seperated value (CSV) files can be opened in any spreadsheet
      program.
    </p>
    <p>Example CSV:</p>
    <code>Times (μs), Amplitude (Gs)</code>
    <br />
    <code>1, 3.25</code>
    <br />
    <code>2, 5.1</code>
    <br />
    <code>3, 2</code>`,
    table: `<thead>
      <tr>
        <th>Time (μs)</th>
        <th>Amplitude (Gs)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>3.25</td>
      </tr>
      <tr>
        <td>2</td>
        <td>5.1</td>
      </tr>
      <tr>
        <td>3</td>
        <td>2</td>
      </tr>
    </tbody>`,
    json: `<p>
      <b>JSON:</b> Saves the graph data as a JSON list.
    </p>
    <p> Example JSON:</p>`,
  },
  "Sample View": {
    intro: `The sample view is available for audio and vibration measurements and
    shows the waveform of a single measurement. This view can be accesed
    through the dropdown in the Trends View or via hovering over the Trends
    graph and clicking "View Sample". Earlier and later samples can easily
    be accessed with the left/right arrows beside the sample title/date.
    Below is an example vibration sample with a sample rate of 6756 Hz and
    the tags "Faulty" and "Inner Race Fault". You can learn more about
    tagging and annotating samples below.`,
    tagging1: `
    OpenPHM has a tagging system that allows you to associate samples with
    properties. For example the tags can be used to identify specific fault
    types or the general status of a machine at the time when a sample was
    measured. These tags are shared as meta-data of the anonymized samples
    researchers can access which can help researchers to identify samples
    that are relevant to their research.`,
    tagging2: `When hovering over a tag you can click the edit button to change certain
    properties of the tag including the color and recommended action when
    this tag is used. The image below shows the edit tag menu for the "Outer
    Race Fault" tag. The recommended action for this tag is to "Replace the
    bearing" and the color is the default blue.`,
    sampleNotes: `
    Samples can also be annotated with notes. These notes can be used to
    provide further details about the specific sample and are not shared
    with researchers. The image below shows the sample notes editor which
    allows you to format notes using bold, underline, lists, and a variety
    of other options.`,
  },
  "Industry Tutorial Overview": {
    intro: `Industry professionals can use openPHM for the purpose of remotely
    monitoring machinery and factory environments to ensure operations run
    smoothly and to help detect issues early. This remote monitoring is done
    via "Internet of Things" (IOT) sensor systems which can collect and
    transmit telemetry data from the machinery to the openPHM platform. This
    data can then be analyzed to detect potential issues and alert the user.
    The openPHM platform can also be used to store and visualize the data
    for further analysis.`,
    details: `This tutorial will guide you through the process of connecting a new IoT
    gateway/sensor system to the app and give you an overview of how these
    systems work. OpenPHM is designed to support a wide variety of sensor
    systems and does not require proprietary hardware. All that is needed is
    a gateway device with access to the internet and sensors to collect and
    transmit to the gateway. Below is the table of contents for this tutorial
    containing info on IoT systems, how to connect one to openPHM, and how
    to then transmit data to the web app. If you want to learn more about
    analysis of data once it is recieved, look at the documentation on 
    <a href="/help/analysis_overview">Data Analysis</a>.`,
    toc: `<ul>
    <li>
      <a href="/help/iot_system_overview">IoT System Overview</a>
    </li>
    <li>
      <a href="/help/iot_system_setup">IoT System Setup</a>
    </li>
    <li>
      <a href="/help/data_json_format">Data JSON Format</a>
    </li>
    </ul>`,
  },
  "About OpenPHM": {
    intro: `OpenPHM (styled "<span
    style="color: rgb(100,100,100); font-size: 1.05em; font-family: var(--logoFont);"
    >openPHM</span>") is a web-based application that streamlines the process of
    collecting, organizing, and analyzing machine data for industry
    professionals and researchers. It is capable of handling data from
    various sources such as sensors measuring vibration, audio, and
    temperature.`,
    industry: `Industry professionals can use openPHM for prognostics and health
    management (PHM) to monitor the health of their machines and predict
    when faults may occur in order to act early and prevent damage. The
    system is designed to be as open as possible so that a wide variety of
    sensors can be used to collect data. To learn more about the monitoring
    systems openPHM is compatible with, see the 
    <a href="/help/iot_system_overview">IoT System Overview</a> 
    documentation.`,
    researcher: `OpenPHM also provides open access to a large pool of real world machine
    data. Researchers can freely access the anonimized data collected by
    industry while also contributing their own data to the system. OpenPHM
    provides tools to help researchers analyze the data and publish their
    findings as well as find and collaborate with other researchers.`,
    linksP: `The links below provide a brief overview of the main features and use
    cases of the app:`,
    links: `<ul>
    <li>
      <a href="/help/industry_tutorial_overview">Industry Machine Maintenance</a>
    </li>
    <li>
      <a href="/help/researcher_overview">Doing Research with OpenPHM</a>
    </li>
    <li>
      <a href="/help/analysis_overview">Data Analysis Tools</a>
    </li>
  </ul>`,
  },
  "Documentation Overview": {
    intro: `Welcome to the openPHM documentation! Here you will find tutorials on
        how to use the web app for both industry and researchers. Search for
        topics using the search bar in the top left or navigate to a specific
        article using the table of contents on the side bar. Below are some
        quick links to help you get started with openPHM.`,
    quickStart: `<h3>Quick Start</h3>
    <ul>
      <li>
        <a href="/help/about_openphm">About OpenPHM</a>
      </li>
      <li>
        <a href="/help/industry_tutorial_overview">Industry Tutorial Overview</a>
      </li>
      <li>
        <a href="/help/researcher_overview">Researcher Overview</a>
      </li>
      <li>
        <a href="/help/analysis_overview">Analysis Overview</a>
      </li>
    </ul>`,
  },
  "Data JSON Format": {
    intro: `The openPHM system is designed to recieve authenticated data sent from
    gateways in a specific format. The data should be sent as a JSON body of
    an HTTP POST request to <pre>https://openphm.org/gateway_data</pre>.
    <br/><br/>This article will detail the types of measurements accepted by the
    openPHM system and the required JSON format as well as give examples.`,
    topLevelFields: `There are three top level fields: <pre>gateway_id</pre>, 
    <pre>gateway_password</pre>, and <pre>measurements</pre>. The 
    <pre>gateway_id</pre> and <pre>gateway_password</pre> correspond to the
    unique ID and password chosen when adding a gateway to the openPHM
    system. To learn more about how to connect a gateway to openPHM see the 
    <a href="/help/iot_system_setup">IoT System Setup</a> article. The id
    and password are used for authentication and identification purposes to
    ensure only the owner of a gateway can modify it's corresponding data.
    The <pre>measurements</pre> field is an array of measurements, which
    themselves are JSON objects containing a combination of the raw data as
    well as measurement meta-data.`,
    measurementsIntro: `All measurements must have at least the following fields: 
    <pre>sensor_id</pre>, <pre>time</pre>, <pre>raw_data</pre>, and 
    <pre>measurement_type</pre>. Depending on the type of measurement, there
    may be additional meta-data fields required such as 
    <pre>sampling_frequency</pre> or <pre>axis</pre>. The JSON below is an
    example of a basic temperature measurement format:`,
    measurementsIntro2: `The <pre>sensor_id</pre> field is a string that corresponds to the id of
    the sensor which collected the measurement. This is used to group
    measurements together which allows for analyzing trends over time. If
    you have multiple sensors on a single machine (such as a temperature and
    a vibration sensor), you may choose to use the same sensor_id for both
    sensors, so they will be treated as a single multi-sensor by the openPHM
    system. The <pre>time</pre> field is a string that represents the time
    the measurement was taken. The time must be formatted in
    "YYYY-MM-DDTHH:MM:SSZ" format (the ISO 8601 standard), in UTC time.
    OpenPHM will adjust data to be displayed in the user's time zone during
    analysis. The <pre>raw_data</pre> field is an array of numbers that
    represent the data signal or value collected by the sensor. The units of
    the raw data values are dependent on the measurement type. In the case
    of the temperature example shown above, the raw_data consists of one
    value measured in Celsius. Finally the <pre>measurement_type</pre> field
    is a string that represents the type of measurement. Below are examples
    with further details for all measurement types currently supported by
    openPHM.`,
    vib: `<ul>
    <li>
      <pre>raw_data</pre>: List of numbers representing the vibration
      signals in Gs (G-force).
    </li>
    <li>
      <pre>sampling_frequency</pre>: The sampling frequency in hertz (Hz).
    </li>
    <li>
      <pre>axis</pre>: The axis of the vibration measurement. This can be
      "x", "y", or "z". If no axis is specified, the default is "x".
    </li>
    </ul>`,
    audio: `<ul>
    <li>
        <pre>raw_data</pre>: List of numbers representing the audio signal
        in decibels (dB).
    </li>
    <li>
        <pre>sampling_frequency</pre>: The audio recording sample rate in
        hertz (Hz) (e.g: 44.1 kHz = 44100).
    </li>
    </ul>`,
    temp: `<ul>
    <li>
    <pre>raw_data</pre>: One number representing the temperature in
    degrees Celsius (°C).
    </li>
    </ul>`,
    battery: `<ul>
        <li>
        <pre>raw_data</pre>: One number representing the battery level in
        millivolts (mV).
        </li>
    </ul>`,
    fullJSON: `Finally, below is an example of a full JSON POST body containing
    multiple measurements (one of each type). You'll notice that the
    vibration and temperature measurements have the same ID and the audio
    and battery measurements also share an ID. This is because the vibration
    and temperature measurements are from the same sensor (or machine) and
    the audio and battery measurements are from the same sensor (or
    machine). This allows openPHM to group measurements together for
    analysis. This measurement would be accepted by the system if there was
    a gateway setup with the corresponding ID and password.
    <div style="padding-top: 1em;">
      <b>Note:</b> Real raw vibration and audio data is typically much
      larger than the ones shown below for example purposes. OpenPHM can
      handle raw data thousands of values long.
    </div>`,
  },
  "IoT System Overview": {
    intro: `The openPHM app is designed to work with Internet of Things (IoT)
    systems which periodically send telemetry data to the website. The
    telemetry data is then securely stored in the openPHM database and can
    be analyzed both manually and automatically using the built-in tools to
    monitor the health of the machines from which the data is collected. A
    common use case may be to monitor the vibration of a motor to determine
    if the vibration amplitudes at various frerquencies are within
    acceptable ranges, or to monitor the temperature of a machine to
    determine if it is overheating.`,
    conclusion: `The sensors send data to the gateway wirelessly using a protocol such as
    Bluetooth or Zigbee. The gateway then sends the data to the openPHM app
    via an authenticated HTTP POST request to 
    <pre>https://openphm.org/gateway_data</pre>. The POST request must be
    sent with a body containing a JSON object with the associated
    measurements and gateway authentication parameters. To learn more about
    the specific format of the POST body, see the 
    <a href="/help/data_json_format">Data JSON Format</a> article. To learn
    more about connecting a gateway so that it can send data to the openPHM
    web app, take a look at the <a href="/help/iot_system_setup">
    IoT System Setup</a> article.`,
  },
  "IoT System Setup": {
    intro: `This article will walk you through the steps required to connect your
    gateway and sensors IoT system to the openPHM web app. This involves the
    adding an organization, and adding a gateway to that organization. Once
    a gateway has been added, sensors may be added to the gateway by sending
    data from the gateway to the openPHM app. The following sections will
    walk you through each of these steps.`,
    addOrg: `Upon logging in for the first time, you will be redirected to a device
    manager page with no devices. This is where you can create
    organizations, folders, and gateways, as well as manage associated
    sensors. All devices must belong to an organization which is a top-level
    folder. Organizations each have an owner and can be shared with other
    openPHM users to allow for easy collaboration.`,
    addGateway: `Once an organization has been created, the next step is to add a gateway
    to this organization. The gateway name must be unique within the
    directory containing the gateway, whereas the gateway ID (Unique ID)
    must be unique within the entire openPHM system and cannot be changed
    after it is chosen (like a username). A password must also be chosen to
    authenticate data coming from the gateway. Finally you may optionally
    add an additional description to the gateway and choose a location (such
    as the factory where it is located) to be associated with the gateway.
    Once the gateway is added it appears as a sub-item below the
    organization in which it is added.`,
    forgotPass: `If you have forgotten the password on a gateway, it can be reset via the
    "Forgot Password?" link as shown below. This will send a password reset
    email to the owner of the organization which the gateway is a part of.
    Following the link will bring users to a page where a new password may
    be chosen for the gateway.`,
    addingSensors: `Now that you have added your first gateway, you can add sensors to the
    gateway by simply sending sensor data from the added gateway using the
    ID and password you have chosen for authentication. The gateway should
    send the data to the openPHM app via an HTTP POST request to 
    <pre>https://openphm.org/gateway_data</pre>. The POST request must be
    sent with a body containing a JSON object in the following format:`,
    jsonLearnMore: `To learn more about the JSON format accepted by the openPHM system, see
    the article on the
    <a href="/help/data_json_format">Data JSON Format</a>. Sending the above
    POST request would automatically add the sensors "VibrationSensor" and
    "TempSensor" to the gateway resulting in the image shown below.`,
    nextSteps: `After following the above steps you should have a gateway and sensors
    configured and connected to openPHM. Now you can begin to analyze the
    incoming data so you can spot machine issues earlier and keep everything
    running smoothly. A good place to start learning more about data
    analysis with openPHM is the <a href="/help/analysis_overview">Analysis Overview</a> page.`,
    sensorSettingsIntro: `A sensor can be edited by clicking on the cog
    icon to the right of the sensor. This will open a sensor settings modal
    such as the one seen below.`,
    renameSensor: `These sensors can then be renamed to something more descriptive by
    clicking on the sensor and selecting the "Rename" option in the top
    menu.`,
    sensorSettings: `There are 3 main collapsible sections in the sensor settings modal:
    Description, Images, and Sensors.
    <b style="display: block; margin: 0.75em 0;">Description:</b>
    The Description section can be used to add additional notes about the
    sensor or the machine it is monitoring.
    <b style="display: block; margin: 0.75em 0;">Images:</b>
    The Images section can be used to add images associated with the sensor.
    This can be useful in large factory settings with many sensors in the
    IoT system to help visually identify the machine a sensor is associated
    with as well as document any photos of damage to the machinery.
   `,
    sensorsSection: ` <b style="display: block; margin: 0.75em 0;">Sensors:</b>
    Finally, there is the Sensors section. This section is used to control
    which types of data the system should accept from this sensor. This will
    also control which types of analysis are available for this sensor. For
    example, if the sensor is set to only send vibration data then
    temperature analysis will not be available on the sensor.`,
  },
  "Researcher Tutorial Overview": {
    intro: `OpenPHM for researchers is a web application which gives free access to
    anonymized bearing monitoring data from the industry. You have access to
    vibration, temperature and audio data. You have also the possibility to
    upload your own data and to use our powerful analysis tools to get a
    better understanding of the raw data.`,
    profile: `You also have the possibility to see which other researchers are using
    the platform. To make your profile interesting for your colleages, you
    can add a description, an institution and a list of social medias to
    your profile.`,
    sections: `This tutorial will guide you through the process of setting up a new
    project, searching for industry data, uploading your own data, sharing
    it, and doing data analysis. The following is a list of the topics
    covered in this tutorial, skip to the section relevant to you, or start
    from the beginning for a full setup tutorial.`,
  },
  "Downloading Data": {
    intro: `You can download data in either CSV or JSON format.`,
    csv: `When downloading a CSV, you will get a file with a header row with the data you chose to download and
    all other rows will consist of the values. The frequency for the FFT values start at 0 and increment by 1.`,
    json: `JSON is the preffered way to download samples as it is more readable. Again, you will get a file with
    the data you chose to download.`,
  },
  "Searching For Data": {
    intro: `On the search page, you have the ability to search for both samples and
    researchers. The search page is the primary way to find data to add to your project
    as well as other researchers to collaborate with.`,
    researcher: `
    When searching for a researcher, you can search by their name or email.
    The search results will display the researcher's profile, provided they
    have filled it out. The profile includes information about their
    institution, a description, and a list of their social media profiles.`,
    sample: `
    When searching for data samples, the text you enter in the search bar
    will be interpreted as a timestamp in the format: YYYY-MM-DD HH:MM:SS.
    This feature is particularly useful when looking for a very specific
    sample. However, if you prefer a more general search, you can apply
    filters based on the sample type (such as vibration in the x, y, and z
    axes, temperature, and audio), the machine settings associated with the
    sample, and tags set by the sensor's owner.`,
    otherFilters: `Additionally, you have the option to exclude samples that you already
    have in your sample manager, ensuring that you only see new and relevant
    results. To further enhance usability, you can choose to group the
    samples by sensor, which prevents an overwhelming number of similar
    samples from appearing in the results.`,
    actions: `Once you find the desired sample, you can
    <a href="/help/downloading_data">download</a> it individually or choose
    to download all samples associated with a specific sensor. Furthermore,
    you can add a sample to your sample manager by clicking the "+" button.
    For more detailed information about a particular sample, you can click
    on the wave icon to access specific machine settings for that sample.
    Additionally, you can explore related samples from the same sensor. By
    applying filters, you can view related samples of the same type, axis,
    and within a specified time interval. To efficiently manage and access
    multiple related samples, you have the option to select and download
    them all at once.`,
  },
  "Uploading Data": {
    intro: `If you want to use our analysis tools on some of your own data, you can
    upload it on the openPHM platform.`,
    intro_p2: `The data can be uploaded in two different formats: JSON and CSV.
    JSON is the preferred format, as it allows for more flexibility
    in the data structure.`,
    csv: `The CSV format should consist of a header row (x, y), with the first
    column containing the time in seconds, and the second column containing
    the sensor value at that time. When uploading data in CSV format, you
    won't be able to specify the type of data (vibration, temperature or
    audio) and it will automatically treated as vibration data when it comes
    to the analysis of the data.`,
    json: `JSON allows for more specifications of the data. You can
    specify the sampling frequency, which will be calculated automatically
    if not specified, the timestamp at which the sample was taken, and the
    type/axis of the sample.`,
    jsonDataTypes: `
    Here are the expected datatypes for each property of the JSON file:
    <ul style={{ fontSize: "0.9em", marginTop: "0.5rem" }}>
      <li>
        <b>sampling_frequency (optional)</b>: number
      </li>
      <li>
        <b>timestamp (optional)</b>: string (YYYY-MM-DDTHH:MM:SSZ) or Date
        object
      </li>
      <li>
        <b>type (optional)</b>: string ("vibration", "temperature" or
        "audio")
      </li>
      <li>
        <b>axis (optional)</b>: string ("x", "y" or "z")
      </li>
      <li>
        <b>raw_data</b>: array of objects with number properties x and y (x is the time in seconds and y is the sensor value)
      </li>
    </ul>
    `,
  },
  "Sharing Data": {
    intro: `You can easily share a project on social medias by creating a unique
    link to the project.`,
    explanation: `Anybody who has access to the link will be able to view the project and
    its data. They will have the ability to download the data, view the
    analysis, and save the project in their own sample manager.`,
  },
  "The Sample Manager": {
    intro: `The sample manager serves as a centralized location where you can save
    and organize interesting data. It is structured with groups,
    which can represent teams of people who all have access to the
    subfolders, folders that can act as projects, and individual samples
    that you have chosen to save.`,
    organisation: `When creating a group, you have the ability to invite other
    people to join. These individuals will also be able to view and access
    the group in their sample manager. This facilitates seamless
    sharing of interesting data among team members. Furthermore, if you wish
    to share data with someone outside the openPHM application, you have the
    option to generate a unique shareable link that will direct them to the
    shared directory.`,
    readme: `To make any shared folder more informative, you can include a
    description in the form of a README file. Simply select the folder and
    click the "Add README" button. The README file supports the use of
    <a
      href="https://www.markdownguide.org/"
      target="_blank"
      rel="noreferrer"
    >
      markdown syntax
    </a>
    , enabling you to add images, links, text formatting, and more.`,
    save: `You can save samples to your sample manager in two ways:
    <ol>
      <li>
        Using the <a href="/help/searching_for_data">search page</a>: You
        can search for data and save the relevant samples directly to your
        sample manager.
      </li>
      <li>
        <a href="/help/uploading_data">Uploading</a> your own data: You have
        the option to upload your own data and store it in your sample
        manager for future reference.
      </li>
    </ol>`,
    analysis: `Once you have saved samples, you can navigate to the parent folder and
    click the analysis button to access the analysis page. This allows you
    to perform in-depth analysis and gain valuable insights from the data.`,
  },
};

export default content;
