import { Sample, TemperatureData, TrendsData } from "./Types";

export async function getAnalysisFolderSamples(folderPath: string, measurementType: string, axis: "x" | "y" | "z" = "x"): 
Promise<Sample[]> {
  const response = await fetch(`/analysis_folder_samples/${encodeURIComponent(folderPath)}/${measurementType}/${axis}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getFolderTrendsData(folderPath: string, measurementType: string, axis: "x" | "y" | "z" = "x", dataType: "acceleration" | "velocity" | "audio"): 
Promise<TrendsData[]> {
  const response = await fetch(`/analysis_folder_trends_data/${encodeURIComponent(folderPath)}/${measurementType}/${axis}/${dataType}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}

export async function getFolderTemperatureData(folderPath: string): 
Promise<TemperatureData[]> {
  const response = await fetch(`/analysis_folder_temperature_data/${encodeURIComponent(folderPath)}`);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}
