import React, { useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Popup, Marker } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import LeafletControlGeocoder from "./LeafletControlGeocoder";
import Spinner from "../../../Spinner";

interface Props {
  position: {
    info: string;
    latLng: {
      lat: number;
      lng: number;
    };
  };
  gateways?: any;
  setPosition: React.Dispatch<React.SetStateAction<any>>;
  setModalFile?: any;
  setGatewayData?: any;
  setGatewaySettingsModalOpen?: any;
  canSearch?: boolean;
  height?: number;
}

/**
 * Map to choose the location of a gateway
 */
const SearchMap = ({
  position,
  setPosition,
  gateways,
  setModalFile,
  setGatewayData,
  setGatewaySettingsModalOpen,
  canSearch,
  height,
}: Props) => {
  const [maxZoom] = useState<number>(18);
  const [maxBounds] = useState<any>([
    [-90, -180],
    [90, 180],
  ]);

  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 100);

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const customPopup = (SearchInfo: any, gateway: any) => {
    return (
      <Popup>
        <div>
          <p
            style={{
              fontWeight: "700",
              fontSize: "1.1em",
              marginBottom: "0",
              marginTop: "0.75em",
            }}
          >
            {gateway ? `Gateway: ${gateway.gateway_name}` : "Gateway Location"}
          </p>
          <p style={{ margin: "0.75em 0" }}>
            <b>Address: </b>
            {gateway ? gateway.address : position.info}
          </p>
          <p style={{ marginBottom: "0px", marginTop: "0px" }}>
            <b>Latitude: </b>
            {Math.round(
              (gateway ? gateway.latitude : SearchInfo.latLng.lat) * 100000
            ) / 100000}
          </p>
          <p style={{ marginBottom: "0.75em", marginTop: "0px" }}>
            <b>Longitude: </b>
            {Math.round(
              (gateway ? gateway.longitude : SearchInfo.latLng.lng) * 100000
            ) / 100000}
          </p>
          {gateway && (
            <button
              onClick={() => {
                const gwData = {
                  description: gateway.description,
                  id: gateway.auth_id,
                  location: {
                    info: gateway.address,
                    latLng: {
                      lat: gateway.latitude,
                      lng: gateway.longitude,
                    },
                  },
                  name: gateway.gateway_name,
                  password: gateway.password,
                  path: gateway.file_path,
                };
                setModalFile(gateway);
                setGatewayData(gwData);
                setGatewaySettingsModalOpen(true);
              }}
              style={{ marginBottom: "10px", padding: "5px 10px" }}
            >
              Gateway Settings
            </button>
          )}
        </div>
      </Popup>
    );
  };

  return (
    <>
      {height === 0 ? (
        <Spinner />
      ) : (
        <MapContainer
          scrollWheelZoom={true}
          minZoom={2}
          maxZoom={maxZoom}
          center={
            gateways && gateways.length
              ? [gateways[0].latitude, gateways[0].longitude]
              : [position.latLng.lat, position.latLng.lng]
          }
          zoom={gateways && gateways.length ? 2 : 12}
          maxBounds={maxBounds}
          style={{ height: height !== undefined ? height : "100%" }}
        >
          <TileLayer
            noWrap={true}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* The markups */}
          {!gateways && (
            <>
              <Marker position={position?.latLng}>
                {customPopup(position, null)}
              </Marker>
              {/* The search bar */}
              {canSearch && (
                <LeafletControlGeocoder setPosition={setPosition} />
              )}
            </>
          )}
          {gateways && (
            <MarkerClusterGroup chunkedLoading>
              {gateways.map((e: any, i: number) => {
                return (
                  <Marker
                    key={i}
                    position={{
                      lat: e.latitude,
                      lng: e.longitude,
                    }}
                  >
                    {customPopup(position, e)}
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          )}
        </MapContainer>
      )}
    </>
  );
};

export default SearchMap;
