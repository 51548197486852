import GatewayAndSensors from "../../../../Images/HelpArticles/GatewayAndSensors.png";
import CaptionedImage from "../../CaptionedImage";
import Section from "../../Section";
import HelpContent from "../../HelpContent";

const IoTSystemOverview = () => {
  return (
    <>
      <Section
        children={
          <>
            <div style={{ height: "375px" }}>
              <p style={{ float: "left", width: "45%" }}>
                {HelpContent["IoT System Overview"].intro}
              </p>
              <CaptionedImage
                img={GatewayAndSensors}
                title="The Gateway And Sensors System"
                caption="Diagram of openPHM connected to sensor IoT system"
                width={50}
                position={"right"}
              />
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: HelpContent["IoT System Overview"].conclusion,
              }}
            />
          </>
        }
      />
    </>
  );
};

export default IoTSystemOverview;
