import FileSaver from "file-saver";
import JSZip from "jszip";
import { MachineSettings } from "../Components/AnalysisPage/HTTPRequests/Types";

export enum AccessType {
  Editor,
  Viewer,
  Owner,
}

export interface Collaborator {
  email: string; // Unique identifier
  name: string;
  type?: AccessType;
  user_id: string;
  id?: number;
  pending?: boolean;
}

// This is used to create the file error popup modal
export interface FileError {
  errorType: "SameName" | "InvalidMove" | "InvalidName";
  fileType: "Gateway" | "Sensor" | "Folder" | "Organization" | "Sample";
  fileName: string;
  actionType: "Move" | "Rename" | "Creation";
}

// Get the files for a user based on ID and type
export const getFiles = async (
  user_id: string,
  user_type: "researcher" | "industry"
) => {
  return await fetch(
    `/files?user_id=${encodeURIComponent(user_id)}&user_type=${user_type}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).then((res) => res.json());
};

// Get the sampling frequency based on a rawData array
export const getSamplingFrequency = (rawData?: { x: number, y: number}[]): number => {
  if (!rawData || rawData.length === 0) return 0;
  const firstPoint = rawData[0];
  const lastPoint = rawData[rawData.length - 1];
  const timeDiff = lastPoint.x - firstPoint.x;
  return rawData.length / timeDiff;
};

export const parseCSV = (content: string) => {
  const lines = content.split("\n").slice(1, -1);
  const rawData: { x: number, y: number}[] = lines.map((line: string) => {
    const [x, y] = line.split(",");
    return { x: parseFloat(x), y: parseFloat(y) };
  });

  return {
    sampling_frequency: getSamplingFrequency(rawData),
    timestamp: new Date(),
    type: "unknown",
    axis: null,
    raw_data: rawData.map((point: any) => point.y) as number[],
  };
};

export const parseJSON = (content: string): {
  sampling_frequency: number;
  timestamp: Date;
  type: string;
  axis: string | null;
  raw_data: number[];
} => {
  if (content === "")
    return {
      sampling_frequency: 0,
      timestamp: expect.any(Date),
      type: "unknown",
      axis: null,
      raw_data: [],
    };
  const json = JSON.parse(content);

  return {
    sampling_frequency: json.sampling_frequency
      ? json.sampling_frequency
      : getSamplingFrequency(json.raw_data),
    timestamp: json.timestamp ? new Date(json.timestamp) : new Date(),
    type: json.type ? json.type : "unknown",
    axis: json.type === "vibration" && json.axis ? json.axis : null,
    raw_data: json.raw_data ? json.raw_data.map((point: any) => point.y) : [],
  };
};

export const getDownloadData = async (
  filePaths: string[],
  categories: string[]
) => {
  if (!filePaths) return;
  return await Promise.all(
    filePaths.map(async (filePath: string) => {
      const data = await fetch(
        `/get_download_data/${encodeURIComponent(
          JSON.stringify({ file_path: filePath, categories: categories })
        )}`
      ).then((data) => data.json());
      return data;
    })
  );
};

export interface Data {
  axis?: string;
  file_path?: string[];
  id?: number;
  name?: string;
  raw_data?: { x: number, y: number}[];
  sampling_frequency?: number;
  timestamp?: string;
  type?: string;
  content?: Data[] | string;
}

export const saveCSV = (data: Data) => {
  const getCSVFile = (file: any) => {
    let csvData = "";
    Object.keys(file).forEach((key: any) => {
      csvData += `${key},`;
    });
    csvData = csvData.slice(0, -1);
    csvData += "\n";
    const maximumLength = Math.max(
      ...Object.keys(file)
        .filter((key) => Array.isArray(file[key]))
        .map((key: any) => file[key].length)
    );
    for (let i = 0; i < maximumLength; i++) {
      for (let key of Object.keys(file)) {
        if (key.endsWith("raw") || key.endsWith("fft")) {
          csvData += `${file[key] && file[key][i] ? file[key][i].y : ""},`;
        } else {
          csvData += `${
            file[key] && file[key][i] && Array.isArray(file[key])
              ? file[key][i]
              : i === 0
              ? file[key]
              : ""
          },`;
        }
      }
      csvData = csvData.slice(0, -1);
      csvData += "\n";
    }

    return csvData;
  };

  if (!data.content) {
    const blob = new Blob([getCSVFile(data)], {
      type: "text/csv;charset=utf-8;",
    });
    FileSaver.saveAs(blob, data.name + ".csv");
    return;
  }
  if (data.type === "readme") {
    const blob = new Blob([data.content as string], {
      type: "text/markdown",
    });
    FileSaver.saveAs(blob, data.name);
    return;
  }

  const setContent = (data: any, currentZip: JSZip): JSZip => {
    data.forEach((file: any, i: number) => {
      if (file.type === "readme") {
        currentZip.file(file.name, file.content);
      } else if (file.content) {
        const folder = currentZip.folder(file.name);
        setContent(file.content, folder!);
      } else {
        currentZip.file(
          `${file.name}-${file.type}${
            file.type === "vibration" ? `-${file.axis.toUpperCase()}` : ""
          }.csv`,
          getCSVFile(file)
        );
      }
    });
    return currentZip;
  };

  const zip = setContent(data.content, new JSZip());

  zip.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, data.name + ".zip");
  });
};

export const saveJSON = (data: Data) => {
  if (!data.content) {
    const blob = new Blob([JSON.stringify(data)], {
      type: "application/json",
    });
    FileSaver.saveAs(blob, data.name + ".json");
    return;
  }

  const setContent = (data: any, currentZip: JSZip): JSZip => {
    data.forEach((file: any) => {
      if (file.type === "readme") {
        currentZip.file(file.name, file.content);
      } else if (file.content) {
        const folder = currentZip.folder(file.name);
        setContent(file.content, folder!);
      } else {
        currentZip.file(
          `${file.name}-${file.type}${
            file.type === "vibration" ? `-${file.axis.toUpperCase()}` : ""
          }.json`,
          JSON.stringify(file)
        );
      }
    });
    return currentZip;
  };

  const zip = setContent(data.content, new JSZip());

  zip.generateAsync({ type: "blob" }).then((content) => {
    FileSaver.saveAs(content, data.name + ".zip");
  });
};

// Add an organization
export const handleCreateOrganization = (
  team: any,
  orgName: string,
  update: any,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>,
  setShareSettingsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  userType: "industry" | "researcher"
) => {
  if (!team) {
    console.error("Organization creation failed - Team details not found.");
    return;
  }

  // Ensure name does not contain / or \
  if (
    orgName.trim() === "" ||
    orgName.includes("/") ||
    orgName.includes("\\") ||
    orgName.includes("#")
  ) {
    setFileErrorModalOpen({
      errorType: "InvalidName",
      fileType: "Organization",
      fileName: orgName,
      actionType: "Creation",
    });
    return;
  }

  return fetch(`/new_organization`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      team: team.filter((user: any) => user.type === AccessType.Owner),
      orgName: orgName,
      user_type: userType,
    }),
  }).then((res) => {
    if (res.status === 200) {
      return res.json().then((id) => {
        team
          .filter((user: any) => user.type !== AccessType.Owner)
          .forEach((user: any) => {
            fetch(`/new_invite`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                org_id: id,
                user_id: team.filter(
                  (user: any) => user.type === AccessType.Owner
                )[0].user_id,
                user_to_id: user.id,
              }),
            });
          });
        if (update) update();
        setShareSettingsModalOpen(false);
        return id;
      });
    } else {
      console.error(res);
    }
  });
};

// Add a gateway
export const handleCreateGateway = (
  files: any,
  userId: string | undefined,
  gateway: any,
  update: any,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>,
  setAddGatewayModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  // User login undefined
  if (!userId) {
    console.error("Gateway creation failed - User details not found.");
    return;
  }

  // Ensure name does not contain / or \
  const fileName = gateway.name;
  if (fileName.includes("/") || fileName.includes("\\")) {
    setFileErrorModalOpen({
      errorType: "InvalidName",
      fileType: "Gateway",
      fileName: fileName,
      actionType: "Creation",
    });
    return;
  }

  // Check if a folder / gateway with the same name already exists
  // in the new directory
  const dir = gateway.key.slice(0, -1);
  const newFolderContents = files
    .filter((f: any) => {
      return (
        f.key.substring(0, dir.length) === dir &&
        f.key.split("/").length ===
          dir.split("/").length + (dir === "" ? 1 : 2) &&
        !f.isSensor
      );
    })
    .map((f: any) => f.key.split("/").reverse()[1]);
  const duplicate = newFolderContents.includes(fileName);
  if (duplicate) {
    setFileErrorModalOpen({
      errorType: "SameName",
      fileType: "Gateway",
      fileName: fileName,
      actionType: "Creation",
    });
    return;
  }

  const orgId = files.filter((f: any) => {
    return f.isOrg && f.key === gateway.key.split("/")[0] + "/";
  })[0].dbID;

  fetch(`/new_gateway`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gw: gateway,
      userId: userId,
      orgId: orgId,
    }),
  }).then((res) => {
    if (res.status === 200) {
      update();
      setAddGatewayModalOpen(false);
    } else {
      console.error(res);
    }
  });
};

// Add a folder
export const handleCreateFolder = (
  files: any,
  userId: string | undefined,
  key: any,
  update: any,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>
) => {
  // User login undefined
  if (!userId) {
    console.error("Folder creation failed - User details not found.");
    return;
  }

  // Check if a folder / gateway with the same name already exists
  // in the new directory
  const fileName = key.split("/").reverse()[1];
  const dir = key.split("/").slice(0, -2).join("/");
  const newFolderContents = files
    .filter((f: any) => {
      return (
        f.key.substring(0, dir.length) === dir &&
        f.key.split("/").length ===
          dir.split("/").length + (dir === "" ? 1 : 2) &&
        !f.isSensor
      );
    })
    .map((f: any) => f.key.split("/").reverse()[1]);
  const duplicate = newFolderContents.includes(fileName);
  if (duplicate) {
    setFileErrorModalOpen({
      errorType: "SameName",
      fileType: "Folder",
      fileName: fileName,
      actionType: "Creation",
    });
    return;
  }

  const orgId = files.filter((f: any) => {
    return f.isOrg && f.key === key.split("/")[0] + "/";
  })[0].dbID;

  return fetch(`/new_folder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      path: key,
      userId: userId,
      orgId: orgId,
    }),
  }).then((res) => {
    if (res.status === 200) {
      if (update) update();
    } else {
      console.error(res);
    }
  });
};

// Rename/Move a gateway or folder
export const handleRenameFolder = (
  files: any,
  oldKey: string,
  newKey: string,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>,
  actionType: "Rename" | "Move",
  update: any
) => {
  const affectedFiles = files.filter((f: any) => {
    return f.key.substring(0, oldKey.length) === oldKey;
  });
  const isGW = affectedFiles.filter((f: any) => f.key === oldKey)[0]?.isGateway;
  const isOrg = affectedFiles.filter((f: any) => f.key === oldKey)[0]?.isOrg;
  const fileName = newKey.split("/").reverse()[newKey.endsWith("/") ? 1 : 0];
  const newPath = newKey.split("/").slice(0, -2).join("/");
  const newFolderObj = files.filter((f: any) => f.key === newPath + "/")[0];

  if (isOrg) {
    newKey = newKey.replace("/", "") + "#" + oldKey.split("#")[1];
  }

  // No moving organizations
  if (actionType === "Move" && isOrg) {
    return;
  }

  // Only file owner can move items
  if (
    affectedFiles[0].permissions !== "owner" &&
    affectedFiles[0].permissions !== "editor"
  ) {
    return;
  }

  // Cannot move files outside of organizations (to root)
  if (actionType === "Move" && newKey.split("/").length === 2) {
    return;
  }

  // Check if the file is being moved into a Gateway
  if (actionType === "Move" && newFolderObj && newFolderObj.isGateway) {
    setFileErrorModalOpen({
      errorType: "InvalidMove",
      fileType: isGW ? "Gateway" : "Folder",
      fileName: fileName,
      actionType: actionType,
    });
    return;
  }

  // Check if a folder / gateway with the same name already exists
  // in the new directory
  const newFolderContents = files
    .filter((f: any) => {
      return (
        f.key.substring(0, newPath.length) === newPath &&
        f.key.split("/").length ===
          newPath.split("/").length + (newPath === "" ? 1 : 2) &&
        !f.isSensor
      );
    })
    .map((f: any) => f.key.split("/").reverse()[1]);
  const duplicate = newFolderContents.includes(fileName);

  const orgID = files.filter(
    (f: any) => f.isOrg && f.key === oldKey.split("/")[0] + "/"
  )[0].dbID;

  if (duplicate) {
    setFileErrorModalOpen({
      errorType: "SameName",
      fileType: isGW ? "Gateway" : "Folder",
      fileName: fileName,
      actionType: actionType,
    });
  } else {
    fetch(`/rename_folder`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orgID: orgID,
        isGW: isGW,
        files: affectedFiles,
        oldKey: oldKey,
        newKey: newKey,
        actionType: actionType,
        newName: fileName,
      }),
    }).then((res) => {
      if (res.status === 200) {
        update();
      } else {
        console.error(res);
      }
    });
  }
};

// Rename a sensor
export const handleRenameFile = (
  files: any,
  oldKey: string,
  newKey: string,
  loadFiles: any,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>,
  fileType: "Sensor" | "Sample"
) => {
  // Check if another sensor in this gateway already has the same name
  const directory = oldKey.split("/").slice(0, -1).join("/");
  const fileNames = files
    .filter((f: any) => {
      return (
        f.key.substring(0, directory.length) === directory &&
        f.key !== oldKey &&
        (f.isSensor || f.sampleId)
      );
    })
    .map((f: any) => {
      return f.key.split("/").reverse()[0];
    });
  const newName = newKey.split("/").reverse()[0];
  const duplicate = fileNames.includes(newName);
  if (duplicate) {
    setFileErrorModalOpen({
      errorType: "SameName",
      fileType: fileType,
      fileName: newName,
      actionType: "Rename",
    });
  } else {
    fetch(fileType === "Sensor" ? `/rename_sensor` : "/rename_sample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        oldName: oldKey.split("/").reverse()[0],
        newName: newKey.split("/").reverse()[0],
        path: oldKey.split("/").slice(0, -1).join("/") + "/",
      }),
    }).then((res) => {
      if (res.status === 200) {
        loadFiles();
      } else {
        console.error(res);
      }
    });
  }
};

// Delete a folder / gateway
export const handleDeleteFolder = (files: any, key: any, update: any) => {
  fetch(`/delete_folder`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      files: files,
      key: key,
    }),
  }).then((res) => {
    if (res.status === 200) {
      update();
    } else {
      console.error(res);
    }
  });
};

// Edit a gateway
export const editGateway = (gw: any, update: any) => {
  fetch(`/edit_gateway`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gw: gw,
    }),
  }).then((res) => {
    if (res.status === 200) {
      update();
    } else {
      console.error(res);
    }
  });
};

// Delete a sensor or sample
export const handleDeleteFile = (
  files: any,
  key: any,
  update: any,
  type: "sensor" | "sample" | "readme"
) => {
  const file = files.filter((f: any) => f.key === key[0])[0];
  fetch(`/delete_${type}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      dbID: file.dbID,
      path: file.key,
    }),
  }).then((res) => {
    if (res.status === 200) {
      update();
    } else {
      console.error(res);
    }
  });
};

// View a file (used for the warning banners)
export const handleOpenFile = (file: any, ref: any) => {
  // Open the file's folder in the file tree
  const folders = (file.file_path + file.sensor_name)
    .split("/")
    .filter((f: any) => f !== "")
    .slice(0, -1);
  let url = "";
  for (let i = 0; i < folders.length; i++) {
    let f = folders[i];
    url += f + "/";
    if (!Object.keys(ref.current.state.openFolders).includes(url)) {
      ref.current.toggleFolder(url);
    }
  }

  // Find and highlight the file in the file tree
  setTimeout(() => {
    // get all <a> elements inside elements with class "nameLbl"
    const elements = document.querySelectorAll(".nameLbl a");

    // loop through the <a> elements and find the one that contains the correct text
    let smallBearingElement;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element && element.textContent?.includes(file.sensor_name)) {
        smallBearingElement = element;
        break;
      }
    }

    if (smallBearingElement) {
      const parentElement = smallBearingElement.parentElement;

      if (parentElement) {
        parentElement.style.backgroundColor = "#f8ff5f";
        setTimeout(() => {
          parentElement.classList.add("backgroundFade");
          setTimeout(() => {
            parentElement.style.backgroundColor = "transparent";
            parentElement.classList.remove("backgroundFade");
          }, 500);
        }, 1800);
      }
    }
  }, 0);
};

export const saveFiles = async (
  uploadLocation: string,
  folders: any,
  files: any,
  user_id: string,
  setFileErrorModalOpen: React.Dispatch<React.SetStateAction<FileError | null>>,
  filesToSave: any[],
  machineSettings?: MachineSettings | null
) => {
  const path = uploadLocation.split("/");
  const fs = files;

  if (path[0].match(/New:{[^{}]*}/)) {
    const orgId = await handleCreateOrganization(
      [
        {
          type: AccessType.Owner,
          user_id: user_id,
        },
      ],
      path[0].replace(/New:{|}/g, ""),
      undefined,
      setFileErrorModalOpen,
      () => {},
      "researcher"
    );
    fs.push({
      dbID: orgId,
      isOrg: true,
      key: path[0].replace(/New:{|}/g, "") + "#" + orgId + "/",
    });
    path[0] = path[0].replace(/New:{|}/g, "") + "#" + orgId;
    uploadLocation = path.join("/");
  }

  // Create new folders if they don't exist
  path.slice(1).forEach((p, i) => {
    if (
      p.match(/New:{[^{}]*}/) &&
      !folders
        .map((folder: { file_path: string }) => folder.file_path)
        .includes(
          path
            .slice(0, i + 2)
            .join("/")
            .replace(/New:{|}/g, "") + "/"
        )
    ) {
      handleCreateFolder(
        fs,
        user_id,
        path
          .slice(0, i + 2)
          .join("/")
          .replace(/New:{|}/g, "") + "/",
        undefined,
        setFileErrorModalOpen
      );
    }
  });

  // Upload/Save files
  await Promise.all(
    filesToSave.map(async (file) => {
      //If file already exists (ie. saving from search page instead of uploading a new one)
      if (!file.upload) {
        return await fetch(`/save_file`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file_path:
              uploadLocation.replace(/New:{|}/g, "") +
              file.name.replace("/[[]^$.|?*+(){}\\]/g", ""),
            id: file.id,
            file_type: file.type,
            content: file.content,
          }),
        });
      } else {
        if (!["application/json", "text/csv"].includes(file.type)) return;
        return await fetch(`/upload_measurement`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            file_path:
              uploadLocation.replace(/New:{|}/g, "") +
              file.name.split(".")[0].replace("/[[]^$.|?*+(){}\\]/g", ""),
            content:
              file.type === "application/json"
                ? parseJSON(file.content)
                : file.type === "text/csv"
                ? parseCSV(file.content)
                : null,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (machineSettings) {
              fetch(`/create_machine_settings`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  for_id: res.id,
                  type: "measurement",
                  initial_settings: machineSettings,
                }),
              });
            }
          });
      }
    })
  );
};
