import HelpContent from "../../HelpContent";

const AnalyzingData = () => {
  return (
    <>
      <p
        dangerouslySetInnerHTML={{
          __html: HelpContent["Analysis Overview"].main,
        }}
      />
    </>
  );
};

export default AnalyzingData;
