import { useEffect, useRef, useState } from "react";
import styles from "../../Styles/LinkShare.module.css";
import CopyIcon from "../../Images/CopyIcon.png";
import ShareIcon from "../../Images/ShareIcon.png";

/**
 * Share Icon in the sample manager
 * When clicked, it will display a modal with a link
 * Props:
 * - url: the url to display (can be a fixed value or a state)
 * - beforeOnClick: a function to execute before the modal is displayed (e.g. api call to get the url)
 */
const LinkShare = ({
  url,
  beforeOnClick,
}: {
  url: string;
  beforeOnClick: () => any | Promise<any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.wrapper} ref={ref}>
      {isOpen && (
        <div className={styles.modal}>
          <div className={styles.link}>
            {url.slice(0, 40) + (url.length > 40 ? "..." : "")}
          </div>
          <button onClick={() => navigator.clipboard.writeText(url)}>
            <img alt="copy-icon" src={CopyIcon} />
            Copy
          </button>
        </div>
      )}
      <div
        className={styles.share}
        onClick={async () => {
          await beforeOnClick();
          setIsOpen(!isOpen);
        }}
      >
        <img alt="share-link" src={ShareIcon} />
      </div>
    </div>
  );
};

export default LinkShare;
