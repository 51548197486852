import { useState, useEffect, useRef, SetStateAction, Dispatch } from "react";

/*
 * This is the institution selector for researchers.
 * It is a dropdown that allows the researcher to select the university
 * which appears on their profile page
 */
const InstitutionSelector = ({
  setInstitution,
}: {
  setInstitution?: Dispatch<SetStateAction<string>>;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestionIndex, setSuggestionIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const inputValueRef = useRef<string>();
  inputValueRef.current = inputValue;

  const handleUserInputChange = (e: any) => {
    if (e.target.value.length !== 0 && e.target.value.length > suggestionIndex)
      findSuggestionFor(e.target.value).then((universityName) => {
        if (!universityName) return;
        if (universityName.indexOf(inputValueRef.current) === 0) {
          setInputValue(universityName);
        }
      });
    setInputValue(e.target.value);
    setSuggestionIndex(e.target.value.length);
  };

  // Might be a good idea in the futur to download the university list on our own server
  // and make the request to our server instead of the hipolabs one
  // just for security and reliability reasons
  const findSuggestionFor = async (phrase: string) => {
    return fetch("http://universities.hipolabs.com/search?name=" + phrase)
      .then((res) => res.json())
      .then((data) => {
        const found = data.find(
          (university: any) => university.name.indexOf(phrase) === 0
        );
        if (found) {
          return found.name;
        } else {
          return null;
        }
      });
  };

  useEffect(() => {
    if (setInstitution) setInstitution(inputValue);
  }, [inputValue, setInstitution]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.selectionStart = suggestionIndex;
      inputRef.current.selectionEnd = inputValue.length;
    }
  }, [suggestionIndex, inputValue]);

  return (
    <input
      type="text"
      ref={inputRef}
      onChange={(e) => handleUserInputChange(e)}
      value={inputValue}
      placeholder="Institution"
      autoFocus
    />
  );
};

export default InstitutionSelector;
