import { SetStateAction, useEffect, useRef, useState } from "react";
import styles from "../../Styles/FFTLayers.module.css";

/*
 * This is a dropdown used to select samples
 * It is used in FFT Layers as well as the FFT threshold settings modal
 */
const SampleDropdown = ({
  i,
  measurement_ids,
  sampleList,
  sampleSearch,
  setSamplesDropdownOpen,
  setSampleDropdownOpen,
  updateMeasurementIds,
}: {
  i: number;
  measurement_ids: number[];
  sampleList: any[];
  sampleSearch: string;
  setSamplesDropdownOpen: (value: SetStateAction<boolean[]>) => void;
  setSampleDropdownOpen?: any;
  updateMeasurementIds?: (newMeasurementIds: number[]) => void;
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [measurementsSelected, setMeasurementsSelected] = useState<number[]>([]);

  const [size, setSize] = useState<number>(25);

  useEffect(() => {
    setMeasurementsSelected(
      sampleList
        .filter((sample) => measurement_ids.includes(sample.id))
        .map((sample) => sample.id)
    );
  }, [sampleList, measurement_ids]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        event.target.id !== "sampleDropdown" &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        // Close the dropdown on click outside
        if (setSampleDropdownOpen) {
          setSampleDropdownOpen(false);
        } else {
          setSamplesDropdownOpen((samplesDropdownOpen) =>
            samplesDropdownOpen.map((isOpen, j) => (j === i ? false : isOpen))
          );
        }

        // Update the selected measurement ids
        if (updateMeasurementIds) {
          updateMeasurementIds(measurementsSelected);
        }
      }
    };
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    i,
    setSamplesDropdownOpen,
    measurementsSelected,
    setSampleDropdownOpen,
    updateMeasurementIds,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      if (dropdownRef.current === null) return;
      if (
        dropdownRef.current.scrollTop +
        dropdownRef.current.clientHeight >=
        dropdownRef.current.scrollHeight - 50
      ) {
        setSize(size => size + 25);
      }
    };

    const list = dropdownRef.current;
    list?.addEventListener("scroll", handleScroll);
    return () => {
      list?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      id="sampleResults"
      ref={dropdownRef}
      className={`${styles.sampleResults} ${styles.sampleMaxHeight}`}
      style={{
        position: "absolute",
        zIndex: 3,
        width: "215px",
        boxSizing: "border-box",
        backgroundColor: "rgb(235, 235, 235)",
      }}
    >
      {sampleList &&
      sampleList.filter((d) =>
        new Date(d.date).toLocaleString().toLowerCase().includes(sampleSearch.toLowerCase())
      ).length ? (
        sampleList
          .filter((d) =>
            new Date(d.date).toLocaleString().toLowerCase().includes(sampleSearch.toLowerCase())
          )
          .sort((a, b) => {
            if (measurement_ids.includes(a.id)) {
              return -1;
            } else if (measurement_ids.includes(b.id)) {
              return 1;
            }
            return 0;
          })
          .slice(0, size)
          .map((d: any, j: number) => {
            return (
              <div id="sampleDropdown" key={j}>
                {new Date(d.date).toLocaleString()}
                <input
                  type="checkbox"
                  checked={measurementsSelected.includes(d.id)}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setMeasurementsSelected([d.id, ...measurementsSelected]);
                    } else {
                      if (
                        measurementsSelected.length === 1 &&
                        measurementsSelected[0] === d.id
                      ) {
                        event.target.checked = true;
                        return;
                      } else {
                        setMeasurementsSelected(
                          measurementsSelected.filter(
                            (sampleId) => sampleId !== d.id
                          )
                        );
                      }
                    }
                  }}
                />
              </div>
            );
          })
      ) : (
        <div>No samples found...</div>
      )}
    </div>
  );
};

export default SampleDropdown;
