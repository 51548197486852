import HelpContent from "../../HelpContent";

const ResearcherTutorialOverview = () => {
  return (
    <>
      <p>{HelpContent["Researcher Tutorial Overview"].intro}</p>
      <p>{HelpContent["Researcher Tutorial Overview"].profile}</p>
      <p>
        {HelpContent["Researcher Tutorial Overview"].sections}
        <h3>Tutorial Sections:</h3>
        <ul>
          <li>
            <a href="/help/the_sample_manager">The Sample Manager</a>
          </li>
          <li>
            <a href="/help/searching_for_data">Searching for Data</a>
          </li>
          <li>
            <a href="/help/uploading_data">Uploading Data</a>
          </li>
          <li>
            <a href="/help/downloading_data">Downloading Data</a>
          </li>
          <li>
            <a href="/help/sharing_data">Sharing Data</a>
          </li>
        </ul>
      </p>
    </>
  );
};

export default ResearcherTutorialOverview;
