import { User } from "@auth0/auth0-react";
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import FileBrowser from "../../FileBrowserComponent";
import styles from "../../../Styles/SharePage.module.css";
import PopUp from "../../PopUp";
import { FileError, saveFiles } from "../../../Utils/FileUtils";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ChooseLocation from "../../FileManager/ChooseLocation/ChooseLocation";
import { getFiles } from "../../../Utils/FileUtils";
import FileErrorModal from "../../FileManager/FileErrorModal/FileErrorModal";
import { Helmet } from "react-helmet";
import showdown from "showdown";
import Logo from "../../../Images/Logo.png";
import SignInInvitation from "./SignInInvitation";
import DownloadMeasurement from "../../FileManager/DownloadMeasurement/DownloadMeasurement";
import { AppContext } from "../../App";
import DashboardVibration from "../../AnalysisPage/DashboardVibration";
import DashboardTemperature from "../../AnalysisPage/DashboardTemperature";
import DashboardAudio from "../../AnalysisPage/DashboardAudio";

// To convert the markdown from the readme file to html
// NOT FOR DISPLAY (security risk)
// Only used to get the first 200 characters of the readme for the meta tags (social media sharing)
const converter = new showdown.Converter();

// Get the necessary info from the database to save a file to the user's own sample manager
// If the file in question is a folder, we need to retreive all of its sub-files
const getFileInfo = async (file_paths: string[]) => {
  const files = await fetch(
    `/files/?file_path=${encodeURIComponent(
      file_paths.reduce((a, b) =>
        a.split("/").length < b.split("/").length ? a : b
      )
    )}&user_type=researcher`
  ).then((res) => res.json());
  const parentFilePath = file_paths
    .reduce((a, b) => (a.split("/").length < b.split("/").length ? a : b))
    .split("/")
    .slice(0, -2)
    .join("/");

  const readmes = await fetch(
    `/readme/${encodeURIComponent(
      JSON.stringify([...files.folders, ...files.orgs])
    )}`
  )
    .then((res: any) => res.json())
    .then((data: any) =>
      Promise.all(
        data.map(async (d: any) => {
          return {
            name: d.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
            type: "readme",
            content: d.content,
          };
        })
      )
    );

  return [
    ...files.orgs.map((o: any) => {
      return {
        name: o.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
        type: "folder",
        upload: false,
      };
    }),
    ...files.folders.map((f: any) => {
      return {
        name: f.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
        type: "folder",
        upload: false,
      };
    }),
    ...files.samples.map((s: any) => {
      return {
        name: s.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
        id: s.id,
        type: "measurement",
        upload: false,
      };
    }),
    ...readmes,
  ];
};

/**
 * Page a user sees when clicking on a share link
 * Displays the file browser and allows the user to download/analyze/save files
 * Also displays a readme of the top level folder if one exists
 * The user will also be prompted to sign in if they are not already to analyze and save files
 * Props:
 * - user: the user object from Auth0
 * - sortDevicesTabLast: function to sort the devices tab last in the tab manager
 * - ifAuthorized: function to check if the user is authorized to view the page
 */
const SharePage = ({
  user,
  sortDevicesTabLast,
  ifAuthorized,
}: {
  user: User | undefined;
  sortDevicesTabLast: () => void;
  ifAuthorized: (uType: "industry" | "researcher" | null, elem: ReactNode) => ReactNode;
}) => {
  const { tabInstance } =  useContext(AppContext) ?? {};
  // Path of the folder shared
  const [filePath, setFilePath] = useState<string>("");

  // Reference to the file manager
  const managerRef = useRef<HTMLElement>(null);

  // Files shared
  const [files, setFiles] = useState<any>([]);

  // Current user files
  const [userFiles, setUserFiles] = useState<{
    orgs: any[];
    folders: any[];
    samples: any[];
  }>({
    orgs: [],
    folders: [],
    samples: [],
  });

  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [openedFilePaths, setOpenedFilePaths] = useState<string[]>([]); // Files opened in the download or save pop up
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);
  const [saveLocation, setSaveLocation] = useState<string>("");
  const [fileErrorModalOpen, setFileErrorModalOpen] =
    useState<FileError | null>(null);

  const [notLoggedInModalOpen, setNotLoggedInModalOpen] =
    useState<boolean>(false);

  // Current readme displayed
  const [readme, setReadme] = useState<string>("");

  // Open a new tab
  const openTab = useCallback(
    (
      id: string,
      name: string,
      type: string,
      icon: string,
      panelComponent: any
    ) => {
      const tab_id = `$${type}$${id}`;
      const page = {
        id: tab_id,
        direction: "ltr",
        iconClass: `fa fa-${icon}`,
        title: name,
        lazy: true,
        panelComponent: () => panelComponent,
      };

      // Save Opened Tabs in Local Storage
      if (localStorage.getItem("tabs")) {
        localStorage.setItem(
          "tabs",
          JSON.stringify([
            ...JSON.parse(localStorage.getItem("tabs") as string),
            page,
          ])
        );
      } else {
        localStorage.setItem("tabs", JSON.stringify([page]));
      }

      tabInstance.open(page).then(() => sortDevicesTabLast());
    },
    [tabInstance, sortDevicesTabLast]
  );

  // Retreive the shared file path from the database (the url is a uuid, not a path)
  useEffect(() => {
    fetch(`/share_file_path/${window.location.pathname.split("/").slice(2)[0]}`)
      .then((res) => res.json())
      .then((data) => {
        setFilePath(data);
      });
  }, []);

  // Retreive the files of the current signed in user
  useEffect(() => {
    const loadUserFiles = async () => {
      setUserFiles(await getFiles(user?.sub!, "researcher"));
    };
    if (user?.sub) loadUserFiles();
  }, [user?.sub]);

  // Load the files of the shared folder
  useEffect(() => {
    // Load the files of the shared folder
    const loadFiles = async (file_path: string) => {
      const data = await fetch(
        `/files?file_path=${encodeURIComponent(file_path)}&user_type=researcher`
      ).then((res) => res.json());

      const parentFilePath = file_path.split("/").slice(0, -2).join("/");

      const orgs = await Promise.all(
        data.orgs.map(async (o: any) => {
          return {
            dbID: o.id,
            key: o.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
            isOrg: true,
          };
        })
      );

      const folders = await Promise.all(
        data.folders.map(async (f: any) => {
          return {
            dbID: f.id,
            key: f.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
            analysisFunc:
              // If the folder contains samples, the user can analyze the folder
              data.samples.filter(
                (s: any) => !s.file_path.replace(f.file_path, "").includes("/")
              ).length > 0
                ? () => {
                    const key = f.file_path
                  
                    if (!user) {
                      setNotLoggedInModalOpen(true);
                      return;
                    }

                    const otherSamples = (data.samples as any[])
                      .filter(
                        (s: any) =>
                          s.file_path.startsWith(f.file_path) &&
                          s.file_path.split("/").length ===
                            f.file_path.split("/").length
                      )
                      .map((s: any) => {
                        return {
                          id: s.id,
                          sensorId: s.sensor_id,
                          Time: new Date(s.mtime),
                          type: s.measurement_type,
                          sampling_frequency: s.sampling_frequency,
                          axis: s.axis,
                        };
                      });
                    // Open the necessary tabs for the analysis
                    if (
                      otherSamples.filter(
                        (s) => s.type === "vibration" || s.type === "unknown"
                      ).length > 0
                    ) {
                      if (otherSamples.filter((s) => s.axis === "x").length > 0) {
                        openTab(
                          `${key}x`,
                          `Vib. X: ${key.split("/").slice(-2)[0]}`,
                          "vibration_analysis",
                          "chart-simple",
                          ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"x"} readonly />)
                        );
                      }
                      if (otherSamples.filter((s) => s.axis === "y").length > 0) {
                        openTab(
                          `${key}y`,
                          `Vib. Y: ${key.split("/").slice(-2)[0]}`,
                          "vibration_analysis",
                          "chart-simple",
                          ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"y"} readonly />)
                        );
                      }
                      if (otherSamples.filter((s) => s.axis === "z").length > 0) {
                        openTab(
                          `${key}z`,
                          `Vib. Z: ${key.split("/").slice(-2)[0]}`,
                          "vibration_analysis",
                          "chart-simple",
                          ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={"z"} readonly />)
                        );
                      }
                      if (otherSamples.filter((s) => !s.axis && (s.type === "vibration" || s.type === "unknown")).length > 0) {
                        openTab(
                          key,
                          `Vibration: ${key.split("/").slice(-2)[0]}`,
                          "vibration_analysis",
                          "chart-simple",
                          ifAuthorized("researcher", <DashboardVibration folderPath={key} axis={undefined} readonly />)
                        );
                      }
                    }

                    if (
                      otherSamples.filter((s: any) => s.type === "temperature")
                        .length > 0
                    )
                      openTab(
                        key,
                        `Temperature: ${key.split("/").slice(-2)[0]}`,
                        "temperature_analysis",
                        "chart-simple",
                        ifAuthorized(
                          "researcher",
                          <DashboardTemperature folderPath={key} />
                        )
                      );
                    if (
                      otherSamples.filter((s: any) => s.type === "audio")
                        .length > 0
                    )
                      openTab(
                        key,
                        `Audio: ${key.split("/").slice(-2)[0]}`,
                        "audio_analysis",
                        "chart-simple",
                        ifAuthorized(
                          "researcher",
                          <DashboardAudio folderPath={key} readonly />
                        )
                      );
                  }
                : undefined,
          };
        })
      );

      const samples = await Promise.all(
        data.samples.map(async (s: any) => {
          return {
            dbID: s.id,
            key: s.file_path.slice(parentFilePath.length).replace(/^\/+/, ""),
            sampleId: s.id,
            customType: s.measurement_type ? s.measurement_type : "Sample",
          };
        })
      );

      const readmes = await fetch(
        `/readme/${encodeURIComponent(
          JSON.stringify([...data.folders, ...data.orgs])
        )}`
      )
        .then((res: any) => res.json())
        .then((data: any) =>
          Promise.all(
            data.map(async (d: any) => {
              if (d.file_path.replace(file_path, "") === "README.md")
                setReadme(d.content);
              return {
                dbID: d.readme_id,
                key: d.file_path
                  .slice(parentFilePath.length)
                  .replace(/^\/+/, ""),
                customType: "readme",
                content: d.content,
                viewFunc: () => {
                  setReadme(d.content);
                },
              };
            })
          )
        );

      setFiles([...orgs, ...folders, ...samples, ...readmes]);
    };

    // If the link is valid
    if (filePath !== "") loadFiles(filePath);
  }, [user, filePath, openTab, tabInstance, ifAuthorized]);

  return (
    <>
      {/* Some metatags to make sharing on social media look better */}
      <Helmet>
        <meta property="og:title" content={filePath.split("/").slice(-2)[0]} />
        <meta property="og:image" content={Logo} />
        <meta
          property="og:description"
          content={
            readme
              ? converter
                  .makeHtml(readme)
                  .replace(/<[^>]*>/g, "")
                  .slice(0, 200)
              : `${filePath.split("/").slice(-2)[0]}: Data from openPHM`
          }
        />
        <meta property="og:site_name" content="openPHM" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      {!user && <SignInInvitation />}
      <PopUp
        isOpen={fileErrorModalOpen !== null}
        setIsOpen={() => setFileErrorModalOpen(null)}
        title={
          fileErrorModalOpen
            ? `${fileErrorModalOpen?.fileType} ${fileErrorModalOpen?.actionType} Failed`
            : ""
        }
      >
        <FileErrorModal fileError={fileErrorModalOpen} />
      </PopUp>
      <PopUp
        
        isOpen={downloadModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setDownloadModalOpen(false);
          }
        }}
        helpUrl={`/downloading_data`}
        title={
          <>
            Download Data
            <div className={styles.helpIcon} />
          </>
        }
      >
        <DownloadMeasurement downloadFilePaths={openedFilePaths} />
      </PopUp>
      <PopUp
        
        isOpen={notLoggedInModalOpen}
        setIsOpen={(b: boolean) => {
          if (!b) {
            setNotLoggedInModalOpen(false);
          }
        }}
        title={"Account Required"}
      >
        <div>
          You must be logged in to save or analyze data.
          <br />
          Please{" "}
          <a href="/" target="_blank" rel="noreferrer">
            log in or create an account
          </a>
          .
        </div>
      </PopUp>
      {user && (
        <PopUp
          
          isOpen={saveModalOpen}
          setIsOpen={(b: boolean) => {
            if (!b) {
              setSaveModalOpen(false);
            }
          }}
          helpUrl={`/the_sample_manager`}
          title={
            <>
              Save Data
              <div className={styles.helpIcon} />
            </>
          }
          confirmBtnTxt={"Save"}
          confirmBtnFunc={async () => {
            await saveFiles(
              saveLocation,
              userFiles.folders,
              [
                ...userFiles.orgs.map((o) => {
                  return { ...o, isOrg: true };
                }),
                ...userFiles.folders,
                ...userFiles.samples,
              ].map((f) => {
                return { ...f, key: f.file_path, dbID: f.id };
              }),
              user?.sub!,
              setFileErrorModalOpen,
              await getFileInfo(openedFilePaths)
            );
            setUserFiles(await getFiles(user?.sub!, "researcher"));
            tabInstance.refresh();
          }}
          closeFunction={() => {
            setOpenedFilePaths([]);
            setSaveLocation("");
          }}
        >
          <div className={styles.savePopUp}>
            <ChooseLocation
              location={saveLocation}
              setLocation={setSaveLocation}
              orgs={userFiles.orgs}
              folders={userFiles.folders}
              user_id={user?.sub!}
            />
          </div>
        </PopUp>
      )}
      <div className={`page ${styles.page}`}>
        <div className={`content ${styles.filebrowser}`}>
          <FileBrowser
            browserType="sample"
            innerRef={managerRef}
            files={files}
            icons={{
              // File Icons
              Org: <i className="orgFileIcon" aria-hidden="true" />,
              Vibration: <i className="vibrationFileIcon" aria-hidden="true" />,
              Temperature: (
                <i className="temperatureFileIcon" aria-hidden="true" />
              ),
              Audio: <i className="audioFileIcon" aria-hidden="true" />,
              Readme: <i className="readmeFileIcon" aria-hidden="true" />,
              Folder: <i className="folderFileIcon" aria-hidden="true" />,
              FolderOpen: (
                <i className="folderOpenFileIcon" aria-hidden="true" />
              ),
              Download: <i className="downloadIcon" aria-hidden="true" />,
              Save: <i className="saveIcon" aria-hidden="true" />,
            }}
            setSelectedFiles={(e: any) => {}}
            renderStyle={"table"}
            onDownloadFile={(filePaths: string[]) => {
              const parentFilePath = filePath.split("/").slice(0, -2).join("/");
              setDownloadModalOpen(true);
              setOpenedFilePaths(
                parentFilePath === ""
                  ? filePaths
                  : filePaths.map((f) => parentFilePath + "/" + f)
              );
            }}
            onDownloadFolder={(filePaths: string[]) => {
              const parentFilePath = filePath.split("/").slice(0, -2).join("/");
              setDownloadModalOpen(true);
              setOpenedFilePaths(
                parentFilePath === ""
                  ? filePaths
                  : filePaths.map((f) => parentFilePath + "/" + f)
              );
            }}
            onSaveFile={(filePaths: string[]) => {
              if (!user) {
                setNotLoggedInModalOpen(true);
                return;
              }
              const parentFilePath = filePath.split("/").slice(0, -2).join("/");
              setSaveModalOpen(true);
              setOpenedFilePaths(
                parentFilePath === ""
                  ? filePaths
                  : filePaths.map((f) => parentFilePath + "/" + f)
              );
            }}
            onSaveFolder={(filePaths: string[]) => {
              if (!user) {
                setNotLoggedInModalOpen(true);
                return;
              }
              const parentFilePath = filePath.split("/").slice(0, -2).join("/");
              setSaveModalOpen(true);
              setOpenedFilePaths(
                parentFilePath === ""
                  ? filePaths
                  : filePaths.map((f) => parentFilePath + "/" + f)
              );
            }}
          />
        </div>
        {readme && (
          <div className={`content ${styles.readme}`}>
            <ReactMarkdown className="markdown">{readme}</ReactMarkdown>
          </div>
        )}
      </div>
    </>
  );
};

export default SharePage;
