import { Dispatch, SetStateAction } from "react";

// The data rendered into the graph changes during a zoom
export const updateRenderData = (
  left: number | string,
  right: number | string,
  data: any[], // TODO fix this any when we know the data type
  setRenderData: any, // TODO fix this any when we know the data type
  maxPoints: number,
  xName: string
): void => {
  // Get rendered data left and right indices
  let L = 0;
  let R = 0;
  if (left === "dataMin") {
    L = 0;
  } else {
    L = data.findIndex((d: any) => d[xName] === left);
  }
  if (right === "dataMax") {
    R = data.length;
  } else {
    R = data.findIndex((d: any) => d[xName] === right);
  }
  const lTemp = L;
  const rTemp = R;
  L = Math.min(lTemp, rTemp);
  R = Math.max(lTemp + 1, rTemp + 1);

  // The max number of dots to render in graph
  const d = data.slice(L, R);
  const pointsInView = R - L;

  // Reduce number of data points being rendered for performance reasons
  // This is done by skipping between data points
  const skipNum = Math.max(Math.ceil(pointsInView / maxPoints), 1);
  const reducedData =
    pointsInView <= maxPoints
      ? d
      : d.filter((d: any, i: number) => i % skipNum === 0);
  setRenderData(reducedData);
};

// A zoom was completed (mouse up) on a graph
export const zoom = (
  refAreaLeft: number | string,
  refAreaRight: number | string,
  setRefAreaLeft: Dispatch<SetStateAction<string | number>>,
  setRefAreaRight: Dispatch<SetStateAction<string | number>>,
  setLeft: Dispatch<SetStateAction<string | number>>,
  setRight: Dispatch<SetStateAction<string | number>>,
  setTop: Dispatch<SetStateAction<string | number>>,
  setBottom: Dispatch<SetStateAction<string | number>>,
  paddingFactor: number,
  data: { x: number; y: number }[]
): void => {
  // Zoom incomplete
  if (refAreaLeft === refAreaRight || refAreaRight === "") {
    setRefAreaLeft("");
    setRefAreaRight("");
    return;
  }

  // Flip ref areas if it was drawn right-to-left
  let _refAreaLeft = refAreaLeft ?? Math.min(...data.map((p) => p.x));
  let _refAreaRight = refAreaRight ?? Math.max(...data.map((p) => p.x));
  if (refAreaLeft > refAreaRight) {
    _refAreaLeft = refAreaRight;
    _refAreaRight = refAreaLeft;
  }

  setRefAreaLeft("");
  setRefAreaRight("");
  setLeft(_refAreaLeft);
  setRight(_refAreaRight);

  const points = data.filter((p) => 
      p.x >= (_refAreaLeft as number) && p.x <= (_refAreaRight as number)
    )
    .map((p) => p.y);

  const span = Math.max(...points) - Math.min(...points);

  setTop(Math.max(...points) + span * paddingFactor);
  setBottom(Math.min(...points) - span * paddingFactor);
};

// Go back to max zoom out (the full data view)
export const zoomOut = (
  setRefAreaLeft: Dispatch<SetStateAction<string | number>>,
  setRefAreaRight: Dispatch<SetStateAction<string | number>>,
  setLeft: Dispatch<SetStateAction<string | number>>,
  setRight: Dispatch<SetStateAction<string | number>>,
  resetTopBottom: () => any,
): void => {
  setRefAreaLeft("");
  setRefAreaRight("");
  setLeft("dataMin");
  setRight("dataMax");
  resetTopBottom();
};
