import ForgotPassword from "../../../../Images/HelpArticles/ForgotGatewayPassword.gif";
import AddingGateway from "../../../../Images/HelpArticles/AddingGateway.gif";
import AddingOrg from "../../../../Images/HelpArticles/AddingOrg.gif";
import NewSensors from "../../../../Images/HelpArticles/NewSensors.png";
import SensorSettings from "../../../../Images/HelpArticles/SensorSettings.png";
import ReactJson from "react-json-view";
import CaptionedImage from "../../CaptionedImage";
import PageContents from "../../PageContents";
import Section from "../../Section";
import HelpContent from "../../HelpContent";

const IoTSystemSetup = () => {
  return (
    <>
      <Section
        title="Overview"
        children={
          <>
            <div>
              <p
                style={{ float: "left", width: "70%" }}
                dangerouslySetInnerHTML={{
                  __html: HelpContent["IoT System Setup"].intro,
                }}
              />
              <PageContents
                sections={[
                  "Adding an Organization",
                  "Adding a Gateway",
                  "Forgot Gateway Password",
                  "Adding Sensors & Sending Data",
                  "Editing Sensors",
                  "Next Steps",
                ]}
              />
            </div>
          </>
        }
      />

      <Section
        title="Adding an Organization"
        children={
          <>
            <div>
              <p style={{ width: "45%", float: "left" }}>
                {HelpContent["IoT System Setup"].addOrg}
              </p>
              <CaptionedImage
                img={AddingOrg}
                title="Adding an Organization"
                caption="Example of how to add an organization"
                width={50}
                position={"right"}
              />
            </div>
          </>
        }
      />

      <Section
        title="Adding a Gateway"
        children={
          <>
            <div>
              <p style={{ width: "45%", float: "left" }}>
                {HelpContent["IoT System Setup"].addGateway}
              </p>
              <CaptionedImage
                img={AddingGateway}
                title="Adding a Gateway"
                caption="Example of how to add a gateway"
                width={50}
                position={"right"}
              />
            </div>
          </>
        }
      />

      <Section
        title="Forgot Gateway Password"
        children={
          <>
            <div>
              <p style={{ width: "45%", float: "left" }}>
                {HelpContent["IoT System Setup"].forgotPass}
              </p>
              <CaptionedImage
                img={ForgotPassword}
                title="Forgot Gateway Password"
                caption="Example of how to get a reset password link"
                width={50}
                position={"right"}
              />
            </div>
          </>
        }
      />

      <Section
        title="Adding Sensors & Sending Data"
        children={
          <>
            <div>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: HelpContent["IoT System Setup"].addingSensors,
                  }}
                />
                <ReactJson
                  style={{
                    margin: "1.5em 0",
                  }}
                  name={false}
                  src={{
                    gateway_id: "SomeUniqueID",
                    gateway_password: "12345",
                    measurements: [
                      {
                        sensor_id: "VibrationSensor",
                        time: "2017-02-16T20:22:28+00:00",
                        sampling_frequency: 6756,
                        raw_data: [-1, 1.2, 0.1],
                        measurement_type: "vibration",
                        axis: "x",
                      },
                      {
                        sensor_id: "TempSensor",
                        time: "2017-02-16T20:25:31+00:00",
                        raw_data: [21.32],
                        measurement_type: "temperature",
                      },
                    ],
                  }}
                />
              </p>
              <p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: HelpContent["IoT System Setup"].jsonLearnMore,
                  }}
                />
              </p>
              <CaptionedImage
                img={NewSensors}
                title="New Sensors"
                caption="The new sensors appear as sub-files of the gateway"
                width={100}
                position={"center"}
              />
              <p>{HelpContent["IoT System Setup"].renameSensor}</p>
            </div>
          </>
        }
      />

      <Section
        title="Editing Sensors"
        children={
          <>
            <div>
              <div style={{ height: "550px" }}>
                <p style={{ width: "55%", float: "left" }}>
                  {HelpContent["IoT System Setup"].sensorSettingsIntro}
                </p>
                <CaptionedImage
                  img={SensorSettings}
                  title="Sensor Settings Modal"
                  caption="All settings available for a sensor"
                  width={40}
                  position={"right"}
                />
                <p style={{ width: "55%", float: "left" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: HelpContent["IoT System Setup"].sensorSettings,
                    }}
                  />
                </p>
              </div>
            </div>
          </>
        }
      />

      <Section
        title="Next Steps"
        children={
          <>
            <div>
              <p
                dangerouslySetInnerHTML={{
                  __html: HelpContent["IoT System Setup"].nextSteps,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default IoTSystemSetup;
