import { useCallback, useEffect, useState } from "react";
import styles from "../../../Styles/RelatedSamples.module.css";
import InfoTooltip from "../../InfoTooltip";
import SelectTable from "./SelectTable";

/**
 * Load related samples from the database
 */
const loadResults = async (
  timeIntervalUnit: "seconds" | "minutes" | "hours" | "days",
  sample_id: number,
  sameAxis: boolean,
  sameType: boolean,
  sameTime: boolean,
  timeInterval: string[],
  offset: number
) => {
  let factor = 1;
  switch (timeIntervalUnit) {
    case "seconds":
      factor = 1;
      break;
    case "minutes":
      factor = 60;
      break;
    case "hours":
      factor = 60 * 60;
      break;
    case "days":
      factor = 60 * 60 * 24;
      break;
  }
  return await fetch(
    `/related_samples/${encodeURIComponent(
      JSON.stringify({
        sample_id,
        same_axis: sameAxis,
        same_type: sameType,
        time_interval: sameTime
          ? ["0", "0"]
          : [
              timeInterval[0] !== ""
                ? parseFloat(timeInterval[0]) * factor
                : "",
              timeInterval[1] !== ""
                ? parseFloat(timeInterval[1]) * factor
                : "",
            ],
        offset: offset,
      })
    )}`
  ).then((data) => data.json());
};

/**
 * This components show the related samples of a sample (samples of the same sensor)
 * The user has the option to filter by: type, axis and time
 * The user can select samples on this component and then save or download them
 * Props:
 * - sample_id: the id of the sample to get the related samples
 * - isVibration: if the sample is a vibration sample
 * - samplesSelected: selected samples state of the parent component
 * - setSamplesSelected: the function to set samplesSelected
 */
const RelatedSamples = ({
  sample_id,
  isVibration,
  samplesSelected,
  setSamplesSelected,
}: {
  sample_id: number;
  isVibration: boolean;
  samplesSelected: any[];
  setSamplesSelected: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const [sameAxis, setSameAxis] = useState(false);
  const [sameType, setSameType] = useState(false);
  const [sameTime, setSameTime] = useState(false);
  const [timeInterval, setTimeInterval] = useState(["", ""]);
  const [timeIntervalUnit, setTimeIntervalUnit] = useState<
    "seconds" | "minutes" | "hours" | "days"
  >("seconds");

  const [relatedSamples, setRelatedSamples] = useState<any[]>([]);

  // Get related samples whenever a filter changes
  useEffect(() => {
    loadResults(
      timeIntervalUnit,
      sample_id,
      sameAxis,
      sameType,
      sameTime,
      timeInterval,
      0
    ).then((res) => {
      setRelatedSamples(res);
      setSamplesSelected([]);
    });
  }, [
    sameAxis,
    sameType,
    sameTime,
    timeInterval,
    sample_id,
    timeIntervalUnit,
    setSamplesSelected,
  ]);

  // Function to load more results when the user scrolls to the bottom
  const loadMoreResults = useCallback(async () => {
    const res = await loadResults(
      timeIntervalUnit,
      sample_id,
      sameAxis,
      sameType,
      sameTime,
      timeInterval,
      relatedSamples.length
    );
    setRelatedSamples((relatedSamples) => [...relatedSamples, ...res]);
  }, [
    sameAxis,
    sameType,
    sameTime,
    timeInterval,
    sample_id,
    timeIntervalUnit,
    relatedSamples,
  ]);

  return (
    <div className={styles.relatedSamples}>
      <div className={styles.title}>
        <h2>Related Samples</h2>
        <div className={styles.tooltip}>
          <InfoTooltip text="Samples of the same sensor" />
        </div>
      </div>

      <div className={styles.filters}>
        {isVibration && (
          <div className={styles.filter}>
            <input
              type="checkbox"
              checked={sameAxis}
              onChange={() => setSameAxis(!sameAxis)}
            />
            <span>Same Axis</span>
          </div>
        )}
        <div className={styles.filter}>
          <input
            type="checkbox"
            checked={sameType}
            onChange={() => setSameType(!sameType)}
          />
          <span>Same Type</span>
        </div>
        <div className={styles.filter}>
          <input
            type="checkbox"
            checked={sameTime}
            onChange={() => setSameTime(!sameTime)}
          />
          <span>Same Time</span>
        </div>
        {!sameTime && (
          <div className={styles.filter}>
            <span>Time Interval</span>
            <div className={styles.subFilter}>
              <span>Before: </span>
              <input
                type="text"
                value={timeInterval[0]}
                onChange={(e) =>
                  setTimeInterval([e.target.value, timeInterval[1]])
                }
                size={4}
                maxLength={8}
              />
            </div>
            <div className={`${styles.subFilter} ${styles.hasSelect}`}>
              <span>After: </span>
              <input
                type="text"
                value={timeInterval[1]}
                onChange={(e) =>
                  setTimeInterval([timeInterval[0], e.target.value])
                }
                size={4}
                maxLength={8}
              />
              <select
                onChange={(e) =>
                  setTimeIntervalUnit(
                    e.target.value as "seconds" | "minutes" | "hours" | "days"
                  )
                }
              >
                <option value="seconds">seconds</option>
                <option value="minutes">minutes</option>
                <option value="hours">hours</option>
                <option value="days">days</option>
              </select>
            </div>
          </div>
        )}
      </div>
      <SelectTable
        samples={relatedSamples}
        samplesSelected={samplesSelected}
        setSamplesSelected={setSamplesSelected}
        loadMoreResults={loadMoreResults}
      />
    </div>
  );
};

export default RelatedSamples;
