import styles from "../../../Styles/FFTAnalysis.module.css";
import {
  getEnvelope,
  LineColors,
} from "../../../Utils/GraphUtils";
import fftBtnImg from "../../../Images/FFTLayersBtn.png";
import layerHideImg from "../../../Images/HiddenEye.png";

import React, { useState, useEffect, Fragment, SetStateAction, Dispatch } from "react";

import { zoom, zoomOut } from "../../../Utils/GraphZoomUtils";

// Utils
import {

  // Bearing
  BPFI,
  BPFO,
  BSF,
  FTF,

  // Electric Motor
  PPF,
  SF,
  RBF,
  FBD,
  MotorFreq,

  // Gear
  GMF,
  HTF,
  GAPF,

  // Imbalance
  DIF,
  SIF,
  CIF,
  OMF,
} from "../../../Utils/GraphUtils";

import PopUp from "../../PopUp";

// Recharts (import directly to reduce size)
import { LineChart } from "recharts/es6/chart/LineChart";
import { Line } from "recharts/es6/cartesian/Line";
import { XAxis } from "recharts/es6/cartesian/XAxis";
import { YAxis } from "recharts/es6/cartesian/YAxis";
import { Tooltip } from "recharts/es6/component/Tooltip";
import { ResponsiveContainer } from "recharts/es6/component/ResponsiveContainer";
import { ReferenceArea } from "recharts/es6/cartesian/ReferenceArea";
import { ReferenceLine } from "recharts/es6/cartesian/ReferenceLine";
import { Label } from "recharts/es6/component/Label";
import { CartesianGrid } from "recharts/es6/cartesian/CartesianGrid";
import { FFTLayer, FFTThreshold, MachineSettings, Sample } from "../HTTPRequests/Types";
import GraphWrapper from "../GraphWrapper";
import CustomTooltip from "../CustomToolTip";
import { DropdownOption, LegendDropdown } from "../LegendDropdown";
import FFTThresholds from "./FFTThresholds";
import TypeDropdown from "../TypeDropdown";
import { getFFTThresholds } from "../HTTPRequests/Thresholds";

function resetMinMax(
  data: {
    [id: string]: number;
    frequency: number;
  }[],
  setTop: Dispatch<SetStateAction<string | number>>, 
  setBottom: Dispatch<SetStateAction<string | number>>, 
) {
  let max = -Infinity;
  let min = Infinity;
  for (const f of data) {
    for (const key in f) {
      if (key === "frequency") continue;
      if (f[key] > max) {
        max = f[key];
      }
      if (f[key] < min) {
        min = f[key];
      }
    }
  }
  const span = max - min;

  setTop(max + span * 0.1);
  setBottom(min - span * 0.1);
}

interface Props {
  mobile: boolean;
  openGraphManager: () => void;
  sensorId?: number; // for industry users
  folderPath?: string; // for research users
  layers: FFTLayer[];
  samples: Sample[];
  machineSettings: MachineSettings | undefined;
  measurementType: "vibration" | "audio";
  axis?: "x" | "y" | "z";
}

/*
 * This is the FFT graph where you can see the FFTs specified
 * Using the FFTLayers section
 */
const FFTAnalysis = ({
  mobile,
  openGraphManager,
  sensorId,
  folderPath,
  layers,
  samples,
  machineSettings,
  measurementType,
  axis,
}: Props) => {
  // Harmonics repeat
  const [harmonicsValue, setHarmonicsValue] = useState(3);

  const handleHarmonicsInputChange = (event: any) => {
    const inputValue = event.target.value;
    // Check if input is a number
    if (inputValue.match(/^[0-9]*$/)) {
      setHarmonicsValue(parseInt(inputValue));
    }
  };

  const handleIncrementHarmonics = () => {
    setHarmonicsValue((prevState) => prevState + 1);
  };

  const handleDecrementHarmonics = () => {
    if (harmonicsValue > 0) {
      setHarmonicsValue((prevState) => prevState - 1);
    }
  };

  const [peaks, setPeaks] = useState<number[]>([]);
  const [closestPeak, setClosestPeak] = useState<number>(0);
  const [snapping, setSnapping] = useState<boolean>(false);

  // The settings for FFT thresholds
  const [thresholdSettings, setThresholdSettings] = useState<FFTThreshold>();
  const [thresholdSettingsOpen, setThresholdSettingsOpen] = useState<boolean>(false);

  // Threshold Faults
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showDanger, setShowDanger] = useState<boolean>(false);

  // Bearing Faults
  const [showFTF, setShowFTF] = useState<boolean>(false);
  const [showInnerRace, setShowInnerRace] = useState<boolean>(false);
  const [showOuterRace, setShowOuterRace] = useState<boolean>(false);
  const [showBallBearing, setShowBallBearing] = useState<boolean>(false);

  const [bpfo, setBpfo] = useState<number>(0);
  const [bpfi, setBpfi] = useState<number>(0);
  const [bsf, setBsf] = useState<number>(0);
  const [ftf, setFtf] = useState<number>(0);

  // Electric Motor Faults
  const [showShaftFreq, setShowShaftFreq] = useState<boolean>(false);
  const [showPPF, setShowPPF] = useState<boolean>(false);
  const [showSF, setShowSF] = useState<boolean>(false);
  const [showRBF, setShowRBF] = useState<boolean>(false);
  const [showFBD, setShowFBD] = useState<boolean>(false);

  const [motorFreq, setMotorFreq] = useState<number>(0);
  const [ppf, setPpf] = useState<number>(0);
  const [sf, setSf] = useState<number>(0);
  const [rbf, setRbf] = useState<number>(0);
  const [fbd, setFbd] = useState<number>(0);

  // Gear Faults
  const [showGMF, setShowGMF] = useState<boolean>(false);
  const [showHTF, setShowHTF] = useState<boolean>(false);
  const [showGAPF, setShowGAPF] = useState<boolean>(false);

  const [gmf, setGmf] = useState<number>(0);
  const [htf, setHtf] = useState<number>(0);
  const [gapf, setGapf] = useState<number>(0);

  // Imbalance Faults
  const [showDIF, setShowDIF] = useState<boolean>(false);
  const [showSIF, setShowSIF] = useState<boolean>(false);
  const [showCIF, setShowCIF] = useState<boolean>(false);
  const [showOMF, setShowOMF] = useState<boolean>(false);

  const [dif, setDif] = useState<number>(0);
  const [sif, setSif] = useState<number>(0);
  const [cif, setCif] = useState<number>(0);
  const [omf, setOmf] = useState<number>(0);

  // Custom fault analysis
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const [customFreq, setCustomFreq] = useState<number>(100);
  const [customOffset, setCustomOffset] = useState<number>(0); 

  // Which fault frequency values are enabled
  const [bearingEnabled, setBearingEnabled] = useState<boolean>(false);
  const [electricMotorEnabled, setElectricMotorEnabled] = useState<boolean>(false);
  const [gearEnabled, setGearEnabled] = useState<boolean>(false);
  const [imbalanceEnabled, setImbalanceEnabled] = useState<boolean>(false);

  // Calculate fault frequency values based on settings
  useEffect(() => {
    if (machineSettings) {
      // Rolling Elements
      const angle = machineSettings.angle_of_contact; // angle of contact
      const Bd = machineSettings.rolling_elem_diameter; // ball diameter
      const Pd = machineSettings.pitch_diameter; // pitch diameter
      const Nb = machineSettings.num_of_rolling_elems; // num of balls

      // Motor
      const rpm = machineSettings.synch_speed; // motor speed (rpm)
      const Aa = machineSettings.motor_speed; // Actual motor speed (rpm)
      const As = machineSettings.synch_speed; // synchronous motor speed (rpm)
      const P = machineSettings.num_of_poles; // Number of poles
      const R = machineSettings.num_of_rotors; // Number of rotors
      const B = machineSettings.num_of_fan_blades; // Number of fan blades

      // Gear
      const T = machineSettings.num_of_teeth; // Number of teeth
      const Tin = machineSettings.num_of_teeth_in; // Number of teeth in
      const Tout = machineSettings.num_of_teeth_out; // Number of teeth out
      const Na = machineSettings.num_of_assembly_phases; // Number of assembly phases

      // Based on enabled settings, calculate fault frequencies to be enabled
      setBearingEnabled(machineSettings.rolling_elem_settings_active && machineSettings.motor_settings_active);
      setElectricMotorEnabled(machineSettings.motor_settings_active);
      setGearEnabled(machineSettings.gear_settings_active && machineSettings.motor_settings_active);
      setImbalanceEnabled(machineSettings.motor_settings_active);

      // Bearing
      setFtf(FTF(Bd, Pd, angle, rpm));
      setBpfo(BPFO(Bd, Pd, angle, rpm, Nb));
      setBpfi(BPFI(Bd, Pd, angle, rpm, Nb));
      setBsf(BSF(Bd, Pd, angle, rpm));

      // Motor
      setMotorFreq(MotorFreq(rpm));
      setPpf(PPF(Aa, As, P));
      setSf(SF(Aa, As));
      setRbf(RBF(Aa, As, R));
      setFbd(FBD(Aa, As, B));

      // Gear
      setGmf(GMF(rpm, T));
      setHtf(HTF(rpm, T, Tin, Tout, Na));
      setGapf(GAPF(rpm, T, Na));

      // Imbalance
      setDif(DIF(rpm));
      setSif(SIF(rpm));
      setCif(CIF(rpm));
      setOmf(OMF(rpm));
    }
  }, [machineSettings]);

  // Zoom state
  const [zoomSelected, setZoomSelected] = useState<boolean>(false);
  const [left, setLeft] = useState<number | string>("dataMin");
  const [right, setRight] = useState<number | string>("dataMax");
  const [bottom, setBottom] = useState<number | string>("dataMin - 0.5");
  const [top, setTop] = useState<number | string>("dataMax + 0.5");
  const [refAreaLeft, setRefAreaLeft] = useState<number | string>("");
  const [refAreaRight, setRefAreaRight] = useState<number | string>("");

  // Tips popup
  const [isTipsOpen, setIsTipsOpen] = useState<boolean>(false);

  const [dataType, setDataType] = useState<"acceleration" | "velocity" | "audio">(measurementType === "vibration" ? "acceleration" : "audio");

  const [data, setData] = useState<{ frequency: number, [id: string]: number }[]>([]);

  useEffect(() => {
    async function loadGraphData() {
      const yValues = await Promise.all(
        layers.map(async (l) => {
          if (l.visible) {
            const res: { 
              fft: { x: number, y: number }[], 
              envelope: { x: number, y: number }[],
            } = await getEnvelope(
              l.start_time,
              l.end_time,
              l.resolution,
              l.interpolation,
              l.measurement_ids,
              dataType,
              localStorage.getItem("token") ?? ""
            );
            if (l.layer_type === "envelope") {
              return { id: l.id, type: "envelope", values: res.envelope.map(e => e.y) };
            } else if (l.layer_type === "fft") {
              return { id: l.id, type: "fft", values: res.fft.map(e => e.y) };
            } else {
              return { id: l.id, type: "none", values: [] };
            }
          }
        })
      );

      let i = 0
      const fetchedData = [];
      while (true) {
        const point: { [id: string]: number; frequency: number; } = { frequency: i / 2 }
        let empty = true
        for (const y of yValues) {
          if (y) {
            if (y.type === "fft" && y.values.length > i / 2) {
              if (i % 2 === 0) {
                point[y.id] = y.values[i / 2];
              } else {
                point[y.id] = (y.values[Math.floor(i / 2)] + y.values[Math.ceil(i / 2)]) / 2;
              }
              empty = false;
            } else if (y.type === "envelope" && y.values.length > i) {
              point[y.id] = y.values[i];
              empty = false;
            }
          }
        }
        if (empty) break;
        fetchedData.push(point);
        i++;
      }

      resetMinMax(fetchedData, setTop, setBottom);

      setData(fetchedData);
    }

    loadGraphData();
  }, [layers, dataType]);

  // An algorithm  that detects the peaks of the FFT
  // for when "snapping" mode is activated (shift key)
  const detectPeaks = (data: number[], thresholdPercentage: number, window: number) => {
    const peaks: any = [];
    for (let i = 0; i < data.length; i++) {
      const slice = data.slice(i, Math.min(data.length, i + window));
      const peakVal = Math.max(...slice);
      const average = slice.reduce((a, b) => a + b) / slice.length;
      const averageDiff = slice.map((d) => Math.abs(d - average)).reduce((a, b) => a + b) / slice.length;
      if ((peakVal - average) >= averageDiff * (1 + thresholdPercentage / 100)) {
        const index = slice.indexOf(peakVal) + i;
        if (!peaks.includes(index/2)) {
          peaks.push(index/2);
        }
      }
    }
    return peaks;
  };

  useEffect(() => {
    const detectKeyUp = (e: any) => {
      if (e.key === "Shift") {
        setSnapping(false);
      }
    };
  
    const detectKeyDown = (e: any) => {
      setSnapping(e.key === "Shift");
    };

    document.addEventListener("keydown", detectKeyDown);
    document.addEventListener("keyup", detectKeyUp);

    return () => {
      document.removeEventListener("keydown", detectKeyDown);
      document.removeEventListener("keyup", detectKeyUp);
    };
  }, []);

  useEffect(() => {
    if (layers?.length) {
      let newPeaks = [];
      for (let i = 0; i < layers.length; i++) {
        if (layers[i].layer_type === "fft") {
          newPeaks.push(
            detectPeaks(
              data.map((d) => d[layers[i].id]),
              300,
              100
            )
          );
        }
      }
      newPeaks = newPeaks.flat();

      let r: number = right === "dataMax" ? data.length : right as number;
      for (let i = 1; i <= harmonicsValue; i++) {
        if (bearingEnabled) {
          if (showInnerRace && bpfi * i < r) newPeaks.push(bpfi * i);
          if (showOuterRace && bpfo * i < r) newPeaks.push(bpfo * i);
          if (showBallBearing && bsf * i < r) newPeaks.push(bsf * i);
          if (showFTF && ftf * i < r) newPeaks.push(ftf * i);
        }
        if (electricMotorEnabled) {
          if (showShaftFreq && motorFreq * i < r) newPeaks.push(motorFreq * i);
          if (showPPF && ppf * i < r) newPeaks.push(ppf * i);
          if (showSF && sf * i < r) newPeaks.push(sf * i);
          if (showRBF && rbf * i < r) newPeaks.push(rbf * i);
          if (showFBD && fbd * i < r) newPeaks.push(fbd * i);
        }
        if (gearEnabled) {
          if (showGMF && gmf * i < r) newPeaks.push(gmf * i);
          if (showHTF && htf * i < r) newPeaks.push(htf * i);
          if (showGAPF && gapf * i < r) newPeaks.push(gapf * i);
        }
        if (imbalanceEnabled) {
          if (showDIF && dif * i < r) newPeaks.push(dif * i);
          if (showSIF && sif * i < r) newPeaks.push(sif * i);
          if (showCIF && cif * i < r) newPeaks.push(cif * i);
          if (showOMF && omf * i < r) newPeaks.push(omf * i);
        }
        if (showCustom && customFreq * i + customOffset < r) {
          newPeaks.push(customFreq * i + customOffset);
        }
      }
      setPeaks(newPeaks);
    }
  }, [
    layers, data, bearingEnabled, electricMotorEnabled, gearEnabled, imbalanceEnabled,
    bpfi, bpfo, bsf, ftf, motorFreq, ppf, sf, rbf, fbd, gmf, htf, gapf, dif, sif, cif, omf,
    showCustom, customFreq, customOffset, harmonicsValue, right, showInnerRace, showOuterRace,
    showBallBearing, showFTF, showShaftFreq, showPPF, showSF, showRBF, showFBD, showGMF,
    showHTF, showGAPF, showDIF, showSIF, showCIF, showOMF,
  ]);

  useEffect(() => {
    if (sensorId) {
      getFFTThresholds(sensorId, dataType, axis).then((threshold) => {
        if (threshold.length > 0) {
          setThresholdSettings(threshold[0]);
        }
      }).catch(e => console.log(e));
    }
  }, [sensorId, dataType, axis]);

  const Graph = () => {
    return (
      <ResponsiveContainer width="100%" height={"100%"}>
        <LineChart
          data={data}
          onMouseDown={(e: any) => {
            if (e === null || !zoomSelected) return;
            setRefAreaLeft(e.activeLabel);
          }}
          onMouseMove={(e: any) => {
            if (e) {
              if (zoomSelected)
                refAreaLeft && setRefAreaRight(e.activeLabel);
              if (e.activePayload && peaks) {
                const currX = e.activePayload[0].payload.frequency;

                const closestPeak = peaks
                  .reduce((prev: any, curr: any) => {
                    return Math.abs(curr - currX) < Math.abs(prev - currX)
                      ? curr
                      : prev;
                  }, 0);
                setClosestPeak(closestPeak);
              }
            }
          }}
          onMouseUp={() => {
            if (zoomSelected)
              zoom(
                refAreaLeft,
                refAreaRight,
                setRefAreaLeft,
                setRefAreaRight,
                setLeft,
                setRight,
                setTop,
                setBottom,
                0.1,
                layers.map(l => data.map(d => ({x: d.frequency, y: d[l.id]}))).flat(),
              );
          }}
          margin={{
            bottom: mobile ? 20 : 28,
            right: 20,
            left: mobile ? -10 : 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            allowDataOverflow
            dataKey="frequency"
            tickFormatter={(value: any) => value.toFixed(0)}
            type="number"
            domain={[left, right]}
          >
            <Label
              fontSize={mobile ? 13 : 16}
              value="Frequency (Hz)"
              offset={0}
              position="bottom"
            />
          </XAxis>
          <YAxis
            tick={{ fontSize: mobile ? 13 : 16 }}
            type="number"
            domain={[bottom, top]}
            allowDataOverflow={true}
            allowDecimals={true}
            tickFormatter={(value: any) => value.toFixed(0)}
          />
          <Tooltip
            cursor={!snapping}
            content={<CustomTooltip snappedOn={snapping ? data.find(d => d.frequency === closestPeak) ?? {} : undefined} />}
            position={{ x: 60, y: 0 }}
          />

          {/* The rectangle shown on zoom */}
          {refAreaLeft && refAreaRight ? (
            <ReferenceArea
              x1={refAreaLeft}
              x2={refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}

          {/*---------------------- LINES -----------------------*/}

          {/* The FFT Graphs */}
          {layers
            .filter((l) => l.visible)
            .map((l: FFTLayer, i: number) => {
              return (
                <Line
                  key={i}
                  type="monotone"
                  dataKey={l.id}
                  stroke={l.color_hex}
                  strokeWidth={l.line_width}
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              );
            })}

            {/* Thresholds */}
            {sensorId && showWarning && thresholdSettings && (
              <>
                {thresholdSettings.warnings.map((d: any, i: number) => {
                  const lowFreq = thresholdSettings.frequencies[i];
                  const highFreq = thresholdSettings.frequencies[i + 1];
                  return (
                    <Fragment key={i}>
                      <ReferenceArea
                        y1={showDanger ? thresholdSettings.dangers[i] : 1000}
                        y2={d}
                        x1={lowFreq}
                        x2={highFreq}
                        ifOverflow="hidden"
                        fill={LineColors["Warning"]}
                        fillOpacity={0.2}
                      />
                      <ReferenceLine
                        stroke={LineColors["Warning"]}
                        strokeWidth={7}
                        opacity={0.75}
                        segment={[
                          { x: lowFreq, y: d },
                          { x: highFreq, y: d },
                        ]}
                      />
                    </Fragment>
                  );
                })}
              </>
            )}
            {sensorId && showDanger && thresholdSettings && (
              <>
                {thresholdSettings.dangers.map((d: any, i: number) => {
                  const lowFreq = thresholdSettings.frequencies[i];
                  const highFreq = thresholdSettings.frequencies[i + 1];
                  return (
                    <Fragment key={i}>
                      <ReferenceArea
                        y1={1000}
                        y2={d}
                        x1={lowFreq}
                        x2={highFreq}
                        ifOverflow="hidden"
                        fill={LineColors["Danger"]}
                        fillOpacity={0.2}
                      />
                      <ReferenceLine
                        stroke={LineColors["Danger"]}
                        strokeWidth={7}
                        opacity={0.75}
                        segment={[
                          { x: lowFreq, y: d },
                          { x: highFreq, y: d },
                        ]}
                      />
                    </Fragment>
                  );
                })}
              </>
            )}

            {/* Fault Analysis */}
            {Array.from({ length: harmonicsValue }, (_, i) => i + 1).map(
              (i, index) => {
                if (
                  machineSettings &&
                  !isNaN(machineSettings.angle_of_contact) &&
                  !isNaN(machineSettings.rolling_elem_diameter) &&
                  !isNaN(machineSettings.pitch_diameter) &&
                  !isNaN(machineSettings.num_of_rolling_elems) &&
                  !isNaN(machineSettings.synch_speed) &&
                  !isNaN(machineSettings.motor_speed) &&
                  layers.length
                ) {
                  let r: number = right === "dataMax" ? data.length : right as number;
                  return (
                    <Fragment key={index}>
                      {/* Bearing */}
                      {bearingEnabled && (
                        <>
                          {showInnerRace && bpfi * i < r && (
                            <ReferenceLine
                              x={bpfi * i}
                              stroke={LineColors["Ball Pass Frequency of Inner Race (BPFI)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showOuterRace && bpfo * i < r && (
                            <ReferenceLine
                              x={bpfo * i}
                              stroke={LineColors["Ball Pass Frequency of Outer Race (BPFO)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showBallBearing && bsf * i < r && (
                            <ReferenceLine
                              x={bsf * i}
                              stroke={LineColors["Ball Spin Frequency (BSF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showFTF && ftf * i < r && (
                            <ReferenceLine
                              x={ftf * i}
                              stroke={LineColors["Fundamental Train Frequency (FTF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                        </>
                      )}

                      {/* Electric Motor */}
                      {electricMotorEnabled && (
                        <>
                          {showShaftFreq && motorFreq * i < r && (
                            <ReferenceLine
                              x={motorFreq * i}
                              stroke={LineColors["Shaft/Motor Frequency"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showPPF && ppf * i < r && (
                            <ReferenceLine
                              x={ppf * i}
                              stroke={LineColors["Pole Pass Frequency (PPF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showSF && sf * i < r && (
                            <ReferenceLine
                              x={sf * i}
                              stroke={LineColors["Slip Frequency (SF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showRBF && rbf * i < r && (
                            <ReferenceLine
                              x={rbf * i}
                              stroke={LineColors["Rotor Bar Passing Frequency (RBF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showFBD && fbd * i < r && (
                            <ReferenceLine
                              x={fbd * i}
                              stroke={LineColors["Fan Blade Frequency (FBD)"]}
                              strokeWidth={1.75}
                            />
                          )}
                        </>
                      )}

                      {/* Gear */}
                      {gearEnabled && (
                        <>
                          {showGMF && gmf * i < r && (
                            <ReferenceLine
                              x={gmf * i}
                              stroke={LineColors["Gear Mesh Frequency (GMF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showHTF && htf * i < r && (
                            <ReferenceLine
                              x={htf * i}
                              stroke={LineColors["Hunting Tooth Frequency (HTF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showGAPF && gapf * i < r && (
                            <ReferenceLine
                              x={gapf * i}
                              stroke={LineColors["Gear Assembly Phase Frequency (GAPF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                        </>
                      )}

                      {/* Imbalance */}
                      {imbalanceEnabled && (
                        <>
                          {showDIF && dif * i < r && (
                            <ReferenceLine
                              x={dif * i}
                              stroke={LineColors["Dynamic Imbalance Frequency (DIF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showSIF && sif * i < r && (
                            <ReferenceLine
                              x={sif * i}
                              stroke={LineColors["Static Imbalance Frequency (SIF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showCIF && cif * i < r && (
                            <ReferenceLine
                              x={cif * i}
                              stroke={LineColors["Couple Imbalance Frequency (CIF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                          {showOMF && omf * i < r && (
                            <ReferenceLine
                              x={omf * i}
                              stroke={LineColors["Overhung Machine Frequency (OMF)"]}
                              strokeWidth={1.75}
                            />
                          )}
                        </>
                      )}

                      {/* Custom */}
                      {showCustom && (
                        <>
                          {customFreq * i + customOffset < r && (
                            <ReferenceLine
                              x={customFreq * i + customOffset}
                              stroke={LineColors["Custom"]}
                              strokeWidth={1.75}
                            />
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                } else {
                  return null;
                }
              }
            )}

            {/* Peak snapping lines */}
            {snapping && (
              <>
                <ReferenceLine
                  x={closestPeak}
                  stroke={"rgb(80,80,80)"}
                  strokeWidth={1.75}
                />
              </>
            )}
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      <PopUp title="Tips" isOpen={isTipsOpen} setIsOpen={setIsTipsOpen}>
        Holding shift will allow you to snap on peaks (only on raw FFT graphs)
      </PopUp>

      {sensorId && thresholdSettings && <FFTThresholds
        axis={axis}
        samples={samples}
        setThreshold={setThresholdSettings}
        thresholdSettingsOpen={thresholdSettingsOpen}
        setThresholdSettingsOpen={setThresholdSettingsOpen}
        sensorId={sensorId}
        threshold={thresholdSettings}
        dataType={dataType}
      />}

      <GraphWrapper
        title="FFT Analysis"
        mobile={mobile}
        data={data}
        helpPage="fft_analysis"
        legendItems={[
          /* Bearing */
          bearingEnabled && showFTF ? "Fundamental Train Frequency (FTF)" : "",
          bearingEnabled && showInnerRace ? "Ball Pass Frequency of Inner Race (BPFI)" : "",
          bearingEnabled && showOuterRace ? "Ball Pass Frequency of Outer Race (BPFO)" : "",
          bearingEnabled && showBallBearing ? "Ball Spin Frequency (BSF)" : "",

          /* Electric Motor */
          electricMotorEnabled && showShaftFreq ? "Shaft/Motor Frequency" : "",
          electricMotorEnabled && showPPF ? "Pole Pass Frequency (PPF)" : "",
          electricMotorEnabled && showSF ? "Slip Frequency (SF)" : "",
          electricMotorEnabled && showRBF ? "Rotor Bar Passing Frequency (RBF)" : "",
          electricMotorEnabled && showFBD ? "Fan Blade Frequency (FBD)" : "",

          /* Gear */
          gearEnabled && showGMF ? "Gear Mesh Frequency (GMF)" : "",
          gearEnabled && showHTF ? "Hunting Tooth Frequency (HTF)" : "",
          gearEnabled && showGAPF ? "Gear Assembly Phase Frequency (GAPF)" : "",

          /* Imbalance */
          imbalanceEnabled && showDIF ? "Dynamic Imbalance Frequency (DIF)" : "",
          imbalanceEnabled && showSIF ? "Static Imbalance Frequency (SIF)" : "",
          imbalanceEnabled && showCIF ? "Couple Imbalance Frequency (CIF)" : "",
          imbalanceEnabled && showOMF ? "Overhung Machine Frequency (OMF)" : "",

          /* Custom */
          showCustom ? {
            name: `Custom Frequency (${customFreq}Hz)`, 
            color: LineColors["Custom"]
          } : "",

          /* Thresholds */
          sensorId && showDanger ? "Danger" : "",
          sensorId && showWarning ? "Warning" : "",
        ]}
        titleChildren={
          <div
            className={`${styles.headerButton} ${styles.tips}`}
            onClick={() => setIsTipsOpen(true)}
          />
        }
        mobileTitleChildren={
          <>
            <div
              className={styles.mobileTitleButton}
              onClick={() => setIsTipsOpen(true)}
            >
              <div className={styles.tips} />
              Tips
            </div>

            {measurementType === "vibration" && <TypeDropdown type={dataType} setType={setDataType} mobile={true} />}
          </>
        }
        headerChildren={
          measurementType === "vibration" && <TypeDropdown type={dataType} setType={setDataType} />
        }
        options={(layers.length && layers.filter((l) => l.visible).length > 0) ? (
          <>
            <LegendDropdown name="Fault Analysis" customDropdownStyle={styles.faultsAnalysisDropdown}>
              <LegendDropdown name="Bearing" disabled={!bearingEnabled}>
                <DropdownOption title={mobile ? "FTF" : "Fundamental Train Frequency (FTF)"} state={showFTF} setState={setShowFTF} />
                <DropdownOption title={mobile ? "BSF" : "Ball Spin Frequency (BSF)"} state={showBallBearing} setState={setShowBallBearing} />
                <DropdownOption title={mobile ? "BPFI" : "Ball Pass Frequency of Inner Race (BPFI)"} state={showInnerRace} setState={setShowInnerRace} />
                <DropdownOption title={mobile ? "BPFO" : "Ball Pass Frequency of Outer Race (BPFO)"} state={showOuterRace} setState={setShowOuterRace} />
              </LegendDropdown>

              <LegendDropdown name="Electric Motor" disabled={!electricMotorEnabled}>
                <DropdownOption title="Shaft/Motor Frequency" state={showShaftFreq} setState={setShowShaftFreq} />
                <DropdownOption title={mobile ? "PPF" : "Pole Pass Frequency (PPF)"} state={showPPF} setState={setShowPPF} />
                <DropdownOption title={mobile ? "SF" : "Slip Frequency (SF)"} state={showSF} setState={setShowSF} />
                <DropdownOption title={mobile ? "RBF" : "Rotor Bar Passing Frequency (RBF)"} state={showRBF} setState={setShowRBF} />
                <DropdownOption title={mobile ? "FBD" : "Fan Blade Frequency (FBD)"} state={showFBD} setState={setShowFBD} />
              </LegendDropdown>

              <LegendDropdown name="Gear" disabled={!gearEnabled}>
                <DropdownOption title={mobile ? "GMF" : "Gear Mesh Frequency (GMF)"} state={showGMF} setState={setShowGMF} />
                <DropdownOption title={mobile ? "HTF" : "Hunting Tooth Frequency (HTF)"} state={showHTF} setState={setShowHTF} />
                <DropdownOption title={mobile ? "GAPF" : "Gear Assembly Phase Frequency (GAPF)"} state={showGAPF} setState={setShowGAPF} />
              </LegendDropdown>

              <LegendDropdown name="Imbalance" disabled={!imbalanceEnabled}>
                <DropdownOption title={mobile ? "DIF" : "Dynamic Imbalance Frequency (DIF)"} state={showDIF} setState={setShowDIF} />
                <DropdownOption title={mobile ? "SIF" : "Static Imbalance Frequency (SIF)"} state={showSIF} setState={setShowSIF} />
                <DropdownOption title={mobile ? "CIF" : "Couple Imbalance Frequency (CIF)"} state={showCIF} setState={setShowCIF} />
                <DropdownOption title={mobile ? "OMF" : "Overhung Machine Frequency (OMF)"} state={showOMF} setState={setShowOMF} />
              </LegendDropdown>

              <LegendDropdown name="Custom" disabled={false} customDropdownStyle={styles.customFaultDropdown}>
                <input type="checkbox" checked={showCustom} onChange={e => setShowCustom(e.target.checked)} />
                <div className={styles.fields}>
                  <label>Frequency Interval:</label>
                  <input type="number" value={customFreq} onChange={e => {
                    try {
                      setCustomFreq(parseFloat(e.target.value));
                    } catch (_) {}
                  }} />Hz
                  <label>Offset From 0:</label>
                  <input type="number" value={customOffset} onChange={e => {
                    try {
                      setCustomOffset(parseFloat(e.target.value));
                    } catch (_) {}
                  }} />Hz
                </div>
              </LegendDropdown>

              <div className={styles.harmonicsSelector}>
                Harmonics:
                <button onClick={handleDecrementHarmonics}>-</button>
                <input
                  type="text"
                  value={harmonicsValue}
                  onChange={handleHarmonicsInputChange}
                />
                <button onClick={handleIncrementHarmonics}>+</button>
              </div>
            </LegendDropdown>
            {sensorId && <LegendDropdown name="Thresholds">
              <>
                <div
                  onClick={() => setThresholdSettingsOpen(!thresholdSettingsOpen)}
                  className={styles.thresholdSettingsBtn}
                >
                  <div />
                  Settings
                </div>

                <DropdownOption title="Warning" state={showWarning} setState={setShowWarning} />
                <DropdownOption title="Danger" state={showDanger} setState={setShowDanger} />
              </>
            </LegendDropdown>}
          </>
        ): <></>}
        graph={layers.length ? (
          layers.filter((l) => l.visible).length > 0 ? (
            Graph()
          ) : (
            <div className={styles.noData}>
              <b
                style={{
                  fontWeight: 500,
                  display: "block",
                }}
              >
                No visible FFT analysis layers.
              </b>
              Make a layer visible by clicking on the{" "}
              <img
                style={{
                  opacity: "0.8",
                  padding: "0.2em",
                  boxSizing: "border-box",
                }}
                alt={"Hide Icon"}
                height={"30px"}
                src={layerHideImg}
              />{" "}
              button
            </div>
          )
        ) : (
          <div className={styles.noData}>
            <b
              style={{
                fontWeight: 500,
                display: "block",
              }}
            >
              No FFT analysis layers.
            </b>
            To add an analysis layer click the button below or use the{" "}
            <img alt={"Layer Icon"} height={"30px"} src={fftBtnImg} /> button
            in the Trends menu at any time.
            <button
              onClick={() => {
                openGraphManager();
              }}
            >
              Open Layer Manager
            </button>
          </div>
        )}
        zoomSelected={zoomSelected}
        setZoomSelected={setZoomSelected}
        zoomOut={() => zoomOut(
          setRefAreaLeft,
          setRefAreaRight,
          setLeft,
          setRight,
          () => resetMinMax(data ?? [], setTop, setBottom)
        )}
      />
    </>
  );
};

export default FFTAnalysis;
