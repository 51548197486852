import { useState, useEffect, ReactNode } from "react";
import styles from "../../Styles/Help.module.css";
import { useNavigate, useParams } from "react-router-dom";
import HelpContent from "./HelpContent";
import { findPathByKeyword, getValueByPath } from "./HelpUtils";

/*
 * This is the container for the help pages
 * It includes a sidebar with links to each page and a search bar
 * as well as the main content pane
 */
const Help = ({
  pages,
}: {
  pages: { name: string; id: string; body: ReactNode | null }[];
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const params = useParams();
  const title = params.id;
  const currentPage:
    | { name: string; id: string; body: ReactNode | null }
    | undefined = pages.find(
    (page: { name: string; id: string; body: ReactNode | null }) =>
      page.id === title
  );

  const navigate = useNavigate();

  const [filter, setFilter] = useState<string>("");
  const [searchResults, setSearchResults] = useState<string[]>([]);

  const link = (page: any, key: number) => {
    return (
      <div
        style={{
          backgroundColor: `${
            title === page.name ? "rgb(200, 229, 255)" : "transparent"
          }`,
          paddingLeft: `${title === page.name ? "0.7em" : "0"}`,
          borderRadius: `${title === page.name ? "1em" : "0"}`,
        }}
        onClick={() => {
          const c = document.getElementById("helpContent");
          if (c) c.scrollTop = 0;
          navigate(`/help/${page.id}`);
          setFilter("");
          setSearchResults([]);
        }}
        className={styles.pageLink}
        key={key}
      >
        {page.name}
      </div>
    );
  };

  return (
    <div className={`${styles.container} help`}>
      <div className={styles.headerBar}>
        <i
          style={{
            transform: sideBarOpen ? "rotate(90deg)" : "rotate(-90deg)",
          }}
          onClick={() => setSideBarOpen(!sideBarOpen)}
        />
        <span
          onClick={() => {
            navigate("/help/documentation_overview");
          }}
        >
          Help & Documentation
        </span>
      </div>
      <div className={styles.mainArea}>
        {(sideBarOpen || screenWidth > 750) && (
          <div className={styles.menu}>
            <div className={styles.pageSearch}>
              <div>
                <i className="fa-solid fa-magnifying-glass" />
                <input
                  type="text"
                  value={filter}
                  onChange={(e: any) => {
                    const val = e.target.value;
                    setFilter(val);
                    if (val) {
                      const results = findPathByKeyword(val);
                      setSearchResults(results);
                    } else {
                      setSearchResults([]);
                    }
                  }}
                  placeholder="Search Help"
                />
              </div>
            </div>
            <div className={styles.menuSection}>
              {pages.map((page: any, key: any) => {
                if (page.body !== null) {
                  return link(page, key);
                } else {
                  return (
                    <div key={key} className={styles.chapterHeader}>
                      {page.name}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        <div id="helpContent" className={`${styles.content}`}>
          {searchResults.length > 0 && (
            <>
              <span className={styles.searchResultTitle}>
                <h1>Search Results</h1>
              </span>
              {searchResults.map((path: string, key: number) => {
                return (
                  <div className={styles.searchResult} key={key}>
                    <a
                      href={path
                        .split(".")[0]
                        .replaceAll(" ", "_")
                        .toLowerCase()}
                    >
                      {path.split(".")[0]}
                    </a>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: getValueByPath(path, HelpContent),
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
          {filter && searchResults.length === 0 && (
            <>
              <span className={styles.searchResultTitle}>
                <h1>Search Results</h1>
              </span>
              <div className={styles.noSearchResults}>
                <span>No results.</span>
              </div>
            </>
          )}
          {!filter && searchResults.length === 0 && (
            <>
              {currentPage ? (
                <div>
                  <h1>{currentPage.name}</h1>
                  {currentPage.body}
                </div>
              ) : (
                <div>
                  <h1>404 - Docs Not Found</h1>
                  <p>
                    Sorry, this article (<pre>{title}</pre>) does not exist.
                    Please use the sidebar to access the available
                    documentation.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Help;
